import { updateNodeAttributes } from '@blissbook/lib/document/commands'
import {
  expressionNodeTypes,
  getExpressionNodes,
} from '@blissbook/lib/document/types'
import {
  type AudienceRootExpression,
  mapLogicalExpressionStringToAudienceExpression,
  stringifyAudienceExpression,
} from '@blissbook/lib/expression'
import { Extension, type NodeWithPos } from '@tiptap/core'
import type { Node as ProseMirrorNode } from 'prosemirror-model'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    expression: {
      setAudienceExpression: (expression?: AudienceRootExpression) => ReturnType
      setAudienceExpressions: (
        nodes: NodeWithPos[],
        expression?: AudienceRootExpression,
      ) => ReturnType
    }
  }
}

export const ExpressionExtension = Extension.create({
  name: 'expression',

  addGlobalAttributes() {
    return [
      {
        types: expressionNodeTypes,
        attributes: {
          audienceExpression: {
            default: undefined,
            parseHTML: (element) => {
              // Try JSON value first
              const strAudienceExpression = element.getAttribute(
                'data-audience-expression',
              )
              if (strAudienceExpression) {
                return JSON.parse(strAudienceExpression)
              }

              // Try deprecated string value
              const strExpression = element.getAttribute('data-expression')
              if (strExpression) {
                return mapLogicalExpressionStringToAudienceExpression(
                  strExpression,
                )
              }
            },
            renderHTML: ({ audienceExpression }) => {
              if (!audienceExpression) return {}
              const strAudienceExpression =
                stringifyAudienceExpression(audienceExpression)
              return { 'data-audience-expression': strAudienceExpression }
            },
          },
          // DEPRECATED string value. This needs to be here so ProseMirror doesn't rip the attribute out
          expression: {
            default: undefined,
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setAudienceExpression:
        (audienceExpression) =>
        ({ dispatch, state, tr }) => {
          // Determine the nodes we can set
          const { blockNodes } = getExpressionNodes(state.doc, tr.selection)
          if (!blockNodes.length) return false

          // Must all be at the same depth
          const parents = new Set<ProseMirrorNode>()
          for (const blockNode of blockNodes) {
            parents.add(blockNode.parent)
          }
          if (parents.size > 1) return false

          if (dispatch) {
            for (const node of blockNodes) {
              updateNodeAttributes(tr, node.pos, {
                audienceExpression,
                expression: undefined,
              })
            }
          }

          return true
        },

      setAudienceExpressions:
        (nodes, audienceExpression) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            for (const node of nodes) {
              updateNodeAttributes(tr, node.pos, {
                audienceExpression,
                expression: undefined,
              })
            }
          }

          return true
        },
    }
  },
})
