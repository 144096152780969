import {
  type BlissbookHandbookContent,
  handbookContentKeys,
} from '@blissbook/lib/blissbook'
import type { PersonalizationInput } from './Personalization'
import { personalizeContent } from './content'

export function personalizeHandbookContent(
  content: BlissbookHandbookContent | undefined,
  personalization: PersonalizationInput,
) {
  if (!content) return
  for (const key of handbookContentKeys) {
    if (content[key]) personalizeContent(content[key], personalization)
  }
}
