import axios from 'axios'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'recaptcha', function () {
  const tokens: Record<string, boolean> = {}

  const testFn = async (response: string) => {
    if (!response) return false
    if (response in tokens) return tokens[response]

    const res = await axios.post<any>('/recaptcha', { response })
    tokens[response] = res.data.success
    return tokens[response]
  }

  return this.test('recaptcha', '${path} is invalid', testFn)
})
