import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { type ReactNode, forwardRef } from 'react'

export type BadgeProps = {
  children?: ReactNode
  className?: string
  color?: string
  icon?: IconProp
  onRemove?: () => void
  style?: React.CSSProperties
  title?: string
  variant?: string
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, className, color, icon, onRemove, variant, ...props }, ref) => (
    <div
      {...props}
      className={classnames(
        'badge',
        color && `badge-${color}`,
        onRemove && 'badge-removable',
        variant && `badge-${variant}`,
        className,
      )}
      onClick={() => {
        if (onRemove) onRemove()
      }}
      ref={ref}
    >
      <If condition={!!icon}>
        <FontAwesomeIcon className='badge-icon' icon={icon} />
      </If>

      <span className='tw-flex-1 tw-min-0 ellipsis'>{children}</span>

      <If condition={!!onRemove}>
        <FontAwesomeIcon className='badge-remove-icon' icon='times-circle' />
      </If>
    </div>
  ),
)
