import {
  type ColorPalette,
  resolveColorPalette,
} from '@blissbook/lib/blissbook'
import { ColorInputMenu, Popper, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Selection } from 'prosemirror-state'
import React from 'react'
import { useState } from 'react'
import type { Editor } from '../editor'
import { getSelectedColor } from '../selection'
import { hasExtension } from '../util'
import { canSelectionMarkText } from './text'
import type { ToolComponent, ToolComponentProps } from './types'

type ColorToolState = {
  colorPalette: ColorPalette
  editor: Editor
  selection: Selection
  value: string
}

export const ColorTool: ToolComponent = ({ className, editor, ...props }) => {
  const [state, setState] = useState<ColorToolState>()
  const active = editor?.isActive('color') || !!state
  const selection = state ? state.selection : editor?.state.selection
  const value = state ? state.value : getSelectedColor(editor)
  const canMark =
    hasExtension(editor, 'color') && canSelectionMarkText(selection)
  const isOpen = !!state

  return (
    <Popper.Provider
      isOpen={isOpen}
      setOpen={(isOpen: boolean) => {
        if (isOpen) {
          const colorPalette = resolveColorPalette(editor.colorPalette)
          setState({ colorPalette, editor, selection, value })
        } else {
          setState(undefined)
        }
      }}
    >
      <Tooltip content='Color' disabled={!canMark}>
        <Popper.ToggleButton
          {...props}
          className={cx(
            'btn-tool tw-flex tw-flex-col tw-justify-end',
            { active },
            className,
          )}
          disabled={!canMark}
        >
          <FontAwesomeIcon icon='font' />

          <div
            className={cx(
              'tw-my-0.5 tw-rounded tw-w-full',
              !canMark && 'tw-opacity-40',
            )}
            css={{ height: 3 }}
            style={{
              backgroundColor: value,
            }}
          />
        </Popper.ToggleButton>
      </Tooltip>

      {isOpen && (
        <ColorInputMenu
          onChange={(value) => {
            const { editor } = state
            editor.commands.setColor(value)
            setState({ ...state, value })
          }}
          onRemove={() => {
            const { editor } = state
            editor.chain().focus().unsetMark('color').run()
            setState(undefined)
          }}
          palette={state?.colorPalette}
          value={value}
        />
      )}
    </Popper.Provider>
  )
}

export type BorderColorToolProps = ToolComponentProps & {
  defaultValue?: string
  onChanging?: (value: string) => void
  onChange: (value?: string) => void
  value?: string
}

export const BorderColorTool: React.FC<BorderColorToolProps> = ({
  className,
  defaultValue,
  editor,
  onChange,
  onChanging,
  value,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)
  const active = value !== undefined
  return (
    <Popper.Provider isOpen={isOpen} setOpen={setOpen}>
      <Tooltip content='Set Border Color'>
        <Popper.ToggleButton
          {...props}
          className={cx(
            'btn-tool tw-flex tw-flex-col tw-justify-end',
            { active },
            className,
          )}
        >
          <FontAwesomeIcon icon={['fas', 'pen']} />

          <div
            className='tw-my-0.5 tw-rounded tw-w-full'
            css={{ height: 3 }}
            style={{
              backgroundColor: value || defaultValue,
            }}
          />
        </Popper.ToggleButton>
      </Tooltip>

      {isOpen && (
        <ColorInputMenu
          onChange={onChange}
          onChanging={onChanging}
          onRemove={() => {
            onChange(undefined)
            setOpen(false)
          }}
          palette={editor.colorPalette}
          showAlpha
          value={value || defaultValue}
        />
      )}
    </Popper.Provider>
  )
}
