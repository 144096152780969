import { normalizeStyles } from '../lib'
import { normalizeLists } from './lists'

export function isMatch(html: string) {
  return html.includes('docs-internal-guid')
}

export function normalizeHtml(html: string) {
  const document = new DOMParser().parseFromString(html, 'text/html')

  // Remove unused tags
  const deleteNodes = document.querySelectorAll<HTMLElement>(
    'br.Apple-interchange-newline',
  )
  for (const node of deleteNodes) {
    node.remove()
  }

  // Replace lonely <br>s with <p>
  const blockTagNames = ['p']
  const breakNodes = document.querySelectorAll<HTMLElement>('br')
  for (const breakNode of breakNodes) {
    const { parentElement } = breakNode
    if (!parentElement) continue

    if (!blockTagNames.includes(parentElement.tagName)) {
      const emptyParagraph = document.createElement('p')
      breakNode.replaceWith(emptyParagraph)
    }
  }

  normalizeLists(document)
  normalizeStyles(document)

  return document.body.innerHTML
}
