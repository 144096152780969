export function normalizeLists(document: Document) {
  const rootListEls = document.querySelectorAll<Element>('ol, ul')
  for (const rootListEl of rootListEls) {
    const listItemEls = rootListEl.querySelectorAll('li')

    // Remove any sub-lists
    const subListEls = rootListEl.querySelectorAll<Element>('ol, ul')
    for (const subListEl of subListEls) {
      subListEl.remove()
    }

    // Remove children
    while (rootListEl.firstChild) {
      rootListEl.removeChild(rootListEl.lastChild)
    }

    // Add the list items
    for (const listItemEl of listItemEls) {
      const level = listItemEl.getAttribute('aria-level')
      if (level) {
        const indent = Number.parseInt(level, 10) - 1
        listItemEl.classList.add(`bb-indent-${indent}`)
      }
      rootListEl.appendChild(listItemEl)
    }
  }
}
