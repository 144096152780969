import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { idNanoid } from '../../id'

// Ensure all id'd nodes have a unique id

const name = 'id'
const key = new PluginKey(name)

const plugin = new Plugin({
  key,
  appendTransaction: (transactions, _prevState, nextState) => {
    const { tr } = nextState
    if (transactions.some((transaction) => transaction.docChanged)) {
      const ids = new Set<string>()
      nextState.doc.descendants((node, pos) => {
        const attrsSpec = node.type.spec.attrs || {}
        if ('id' in attrsSpec) {
          const { attrs } = node
          if (!attrs.id || ids.has(attrs.id)) {
            const id = idNanoid()
            tr.setNodeMarkup(pos, undefined, { ...attrs, id })
            ids.add(id)
          } else {
            ids.add(attrs.id)
          }
        }
      })
    }

    return tr.steps.length ? tr : null
  },
})

export const IdExtension = Extension.create({
  name,

  addProseMirrorPlugins() {
    return [plugin]
  },
})
