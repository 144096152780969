import { css } from '@emotion/react'
import React from 'react'

export interface BackgroundImageProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  height?: string | number
  size?: string
  src: string
  style?: React.CSSProperties
  width?: string | number
}

export const BackgroundImage = React.forwardRef<
  HTMLDivElement,
  BackgroundImageProps
>(({ height, size = 'cover', src, style, width = '100%', ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    css={css`
      background-size: ${size};
      background-repeat: no-repeat;
      background-position: 50% 50%;
    `}
    style={{
      backgroundImage: `url(${src})`,
      height,
      width,
      ...style,
    }}
  />
))
