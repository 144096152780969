import { useEffect, useRef, useState } from 'react'

function getScrollBarWidth(node: HTMLElement) {
  return node ? node.offsetWidth - node.clientWidth : 0
}

export function useScrollBarWidth(
  node: HTMLElement,
  onChange?: (width: number) => void,
) {
  const [, setTimestamp] = useState<Date>()
  const width = getScrollBarWidth(node)
  const lastWidthRef = useRef(width)

  if (width !== lastWidthRef.current) {
    if (onChange) setTimeout(() => onChange(width))
    lastWidthRef.current = width
  }

  // Helper to detect scrollbar width changes
  useEffect(() => {
    const timerId = setInterval(() => {
      const width = getScrollBarWidth(node)
      if (width !== lastWidthRef.current) {
        setTimestamp(new Date())
      }
    }, 200)
    return () => clearInterval(timerId)
  }, [node])

  return width
}
