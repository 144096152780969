import { Node } from '@tiptap/core'
import {
  type ListDefinition,
  getActiveHeading,
  getActiveList,
} from '../../types'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    list: {
      setList: (list: ListDefinition) => ReturnType
    }

    paragraph: {
      setParagraph: () => ReturnType
    }
  }
}

export const ParagraphNode = Node.create({
  name: 'paragraph',
  priority: 1000,
  group: 'block',
  content: 'inline*',

  parseHTML() {
    return [{ tag: 'p' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setList:
        (list) =>
        ({ chain, editor }) => {
          const commands = chain()

          const activeHeading = getActiveHeading(editor)
          const activeList = getActiveList(editor)

          if (list !== activeList) {
            if (activeHeading) {
              commands.unsetHeading()
            }
            commands.toggleList(list.name, 'listItem')
          }

          return commands.run()
        },

      setParagraph:
        () =>
        ({ commands, editor }) => {
          const activeHeading = getActiveHeading(editor)
          const activeList = getActiveList(editor)

          if (activeHeading) {
            commands.unsetHeading()
          } else if (activeList) {
            commands.liftListItem('listItem')
          }

          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setParagraph(),
    }
  },
})
