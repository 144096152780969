import type { Editor } from '@blissbook/ui/editor'
import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { ExpressionsState } from './ExpressionsState'

const name = 'expressions'
const key = new PluginKey<ExpressionsState>(name)

function createPlugin(editor: Editor) {
  return new Plugin({
    key,

    state: {
      init() {
        return new ExpressionsState(editor)
      },

      apply(transaction, pluginState, _oldState, newState) {
        return pluginState.apply(transaction, newState)
      },
    },

    props: {
      decorations(state) {
        const pluginState = key.getState(state)
        return pluginState.decorations
      },
    },

    view(view) {
      const pluginState = key.getState(view.state)
      pluginState.bindView(view)

      return {
        update() {
          pluginState.updatePositions()
        },

        destroy() {
          pluginState.unbindView(view)
        },
      }
    },
  })
}

export const ExpressionsExtension = Extension.create({
  name,

  addProseMirrorPlugins() {
    const editor = this.editor as Editor
    const plugin = createPlugin(editor)
    return [plugin]
  },
})
