import type { AudienceRootExpression } from '@blissbook/lib/expression'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { NodeWithPos } from '@tiptap/core'
import React from 'react'
import { getSelectedExpression } from '../selection'
import { ToolbarButton } from '../toolbar'
import { hasExtension } from '../util'
import type { ToolComponent, ToolComponentProps } from './types'

export const ExpressionTool: ToolComponent = ({ editor, ...props }) => {
  const selectedExpression = getSelectedExpression(editor)
  return (
    <ToolbarButton
      {...props}
      active={!!selectedExpression}
      disabled={
        !hasExtension(editor, 'expression') ||
        !editor.can().setAudienceExpression()
      }
      label={selectedExpression ? 'Remove Access Limitation' : 'Limit Access'}
      onClick={async () => {
        if (selectedExpression) {
          editor.chain().focus().setAudienceExpression(undefined).run()
        } else {
          const audienceExpression =
            await editor.pickAudienceExpression(selectedExpression)
          editor.chain().focus().setAudienceExpression(audienceExpression).run()
        }
      }}
    >
      <FontAwesomeIcon icon={['fas', 'lock']} />
    </ToolbarButton>
  )
}

export type NodeExpressionToolProps = ToolComponentProps & {
  node: NodeWithPos
}

export const NodeExpressionTool = ({
  editor,
  node,
  ...props
}: NodeExpressionToolProps) => {
  const nodeExpression = node.node.attrs
    .audienceExpression as AudienceRootExpression
  return (
    <ToolbarButton
      {...props}
      active={!!nodeExpression}
      label={nodeExpression ? 'Remove Access Limitation' : 'Limit Access'}
      onClick={async () => {
        if (nodeExpression) {
          editor.chain().focus().setAudienceExpressions([node], undefined).run()
        } else {
          const audienceExpression =
            await editor.pickAudienceExpression(nodeExpression)
          editor
            .chain()
            .focus()
            .setAudienceExpressions([node], audienceExpression)
            .run()
        }
      }}
    >
      <FontAwesomeIcon icon={['fas', 'lock']} />
    </ToolbarButton>
  )
}
