import {
  type HorizontalRuleAttributes,
  defaultHorizontalNodeAttributes,
} from '@blissbook/lib/document'
import {
  NodeSelectionToolbar,
  type NodeSelectionToolbarProps,
} from '@blissbook/ui/editor/selection/node'
import { BorderColorTool } from '@blissbook/ui/editor/tools'
import React from 'react'
import { EmbedNodeSelectionRemoveTool } from '../embed'

export const HorizontalRuleNodeSelectionToolbar: React.FC<
  NodeSelectionToolbarProps
> = (props) => {
  const { editor, removeNode, selection, updateNodeAttributes } = props
  const { node } = selection
  const { borderColor } = node.attrs as HorizontalRuleAttributes
  const hrEl = editor.view.nodeDOM(selection.from) as HTMLElement
  return (
    <NodeSelectionToolbar {...props}>
      <BorderColorTool
        defaultValue={defaultHorizontalNodeAttributes.borderColor}
        editor={editor}
        onChange={(borderColor) => {
          hrEl.style.backgroundColor = undefined
          updateNodeAttributes({ borderColor })
        }}
        onChanging={(color) => {
          hrEl.style.backgroundColor = color
        }}
        value={borderColor}
      />

      <EmbedNodeSelectionRemoveTool label='Divider' onRemove={removeNode} />
    </NodeSelectionToolbar>
  )
}
