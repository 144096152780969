import reduce from 'lodash/reduce'
import moment from 'moment'

// biome-ignore lint/complexity/noStaticOnlyClass: requires some rework, for models it has to be classes
export class fromJSON {
  static date(value: Date | string): Date {
    return moment.parseZone(value).toDate()
  }
}

type ConvertFunction = (value: any, json: any) => any

export const mapJSONValues = (
  json: any,
  converts: Record<string, ConvertFunction>,
) => {
  return reduce(
    converts,
    (json, convert, key) => {
      const value = json[key]
      if (value !== undefined && value !== null)
        json[key] = convert(value, json)
      return json
    },
    { ...json },
  )
}
