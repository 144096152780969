import {
  type AudienceRootExpression,
  type Expression,
  ExpressionConjunction,
  type OperandValue,
  type SelectOperandValue,
} from '@blissbook/lib/expression'
import type { PersonalizationInput } from './Personalization'

/** Return true if the values match the operands and/or/not settings  */
function evaluateOperandValues<T>(
  operand: { isEvery: boolean; isNot?: boolean },
  values: T[],
  callback: (value: T) => boolean,
) {
  const { isEvery, isNot } = operand
  const result = isEvery ? values.every(callback) : values.some(callback)
  return isNot ? !result : result
}

function evaluateConjunction<T>(
  conjunction: ExpressionConjunction,
  values: T[],
  callback: (value: T) => boolean,
) {
  const isEvery = conjunction === ExpressionConjunction.And
  return evaluateOperandValues({ isEvery }, values, callback)
}

function evaluateSelectOperand(
  operand: SelectOperandValue,
  personalization: PersonalizationInput,
) {
  const { field, path, values } = operand
  if (field === 'metadata') {
    const { metadata } = personalization
    if (!metadata) return false

    const value = metadata[path]
    if (!value) return false

    return evaluateOperandValues(
      operand,
      values,
      // MySQL does a case sensitive compare, so do the same
      (_value) => _value === value,
    )
  }

  return false
}

function evaluateOperand(
  operand: OperandValue,
  personalization: PersonalizationInput,
) {
  switch (operand.type) {
    case 'groups': {
      const { groupIds } = personalization
      if (!groupIds) return false
      return evaluateOperandValues(operand, operand.ids, (id) =>
        groupIds.includes(id),
      )
    }
    case 'people': {
      const { personId } = personalization
      if (!personId) return false
      return evaluateOperandValues(
        operand,
        operand.ids,
        (id) => id === personId,
      )
    }
    case 'savedSegments': {
      const { savedSegmentIds } = personalization
      if (!savedSegmentIds) return false
      return evaluateOperandValues(operand, operand.ids, (id) =>
        savedSegmentIds.includes(id),
      )
    }
    case 'select': {
      return evaluateSelectOperand(operand, personalization)
    }
    default:
      return false
  }
}

function evaluateExpression(
  expression: Expression,
  personalization: PersonalizationInput | undefined,
) {
  const { conjunction, operands } = expression
  return evaluateConjunction(conjunction, operands, (operand) =>
    evaluateOperand(operand, personalization),
  )
}

/** Determine if the person's data matches the audience expression */
export function evaluateAudienceExpression(
  audienceExpression: AudienceRootExpression | null,
  personalization: PersonalizationInput | undefined,
): boolean {
  // No expression = allow
  if (!audienceExpression) return true
  // No personalization = deny
  if (!personalization) return false

  const { conjunction, expressions } = audienceExpression
  return evaluateConjunction(conjunction, expressions, (expression) =>
    evaluateExpression(expression, personalization),
  )
}
