import config from '@blissbook/ui-config'
import React from 'react'
import { Helmet } from 'react-helmet'

export const Head: React.FC<{
  description?: string
  favicon?: string
  metaProps?: {
    property: string
    content: string
  }[]
  title?: string
}> = ({
  description,
  favicon = '/favicon.png',
  metaProps,
  title = 'Blissbook',
}) => (
  <Helmet>
    <meta charSet='utf-8' />
    <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
    <If condition={!!description}>
      <meta name='description' content={description} />
    </If>
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <meta
      name='last-modified'
      httpEquiv='last-modified'
      content='LAST_MODIFIED_AT'
    />
    <If condition={!config.env.production}>
      <meta name='ROBOTS' content='NOINDEX, NOFOLLOW, NOARCHIVE' />
    </If>
    <If condition={!!metaProps}>
      {metaProps.map((props, i) => (
        <meta key={i} {...props} />
      ))}
    </If>
    <base href='/' />
    <link rel='shortcut icon' href={favicon} />
    <title>{title}</title>
  </Helmet>
)
