import type { Identifier } from 'dnd-core'
import React from 'react'
import { type CSSProperties, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

interface DragObject {
  index: number
  id: number
}

interface CollectedProps {
  handlerId: Identifier
}

export const SortableItem: React.FC<{
  className?: string
  id: number
  index: number
  onDragEnd: () => void
  onDrop: (dragIndex: number, hoverIndex: number) => void
  onDropHover: (dragIndex: number, hoverIndex: number) => void
  style: CSSProperties
}> = ({ id, index, onDragEnd, onDrop, onDropHover, style, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragObject, unknown, CollectedProps>({
    accept: 'sortable',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item) {
      onDrop(item.index, index)
    },
    hover(item) {
      onDropHover(item.index, index)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'sortable',
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      onDragEnd()
    },
  })

  const opacity = isDragging ? 0.2 : 1
  drag(drop(ref))
  return (
    <div
      {...props}
      data-handler-id={handlerId}
      ref={ref}
      style={{ ...style, opacity }}
    />
  )
}
