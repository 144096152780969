import { fullScreenAspectRatio } from '@blissbook/lib/aspectRatio'
import { Node } from '@tiptap/core'
import type { VideoNodeAttributes } from '../../types'
import { embedAttributes } from './embed'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      insertVideo: (attrs: VideoNodeAttributes) => ReturnType
    }
  }
}

export const VideoNode = Node.create({
  name: 'video',
  draggable: true,
  group: 'block',

  addAttributes() {
    return embedAttributes
  },

  parseHTML() {
    return [{ tag: 'rw-video[src]' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['rw-video', HTMLAttributes]
  },

  addCommands() {
    return {
      insertVideo:
        ({ width = 640, ...attrs }) =>
        ({ commands }) => {
          return commands.insertContent(
            {
              type: this.name,
              attrs: {
                align: 'center',
                height: fullScreenAspectRatio.getHeightFromWidth(width),
                width,
                ...attrs,
              },
            },
            {
              updateSelection: true,
            },
          )
        },
    }
  },
})
