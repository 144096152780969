import { renderKeyboardShortcut } from '@blissbook/ui/lib/keyboard'

export const renderToolbarTooltip = (
  label: string,
  keyboardShortcut?: string,
) => {
  if (!keyboardShortcut) return label
  const shortcut = renderKeyboardShortcut(keyboardShortcut)
  return `${label} (${shortcut})`
}
