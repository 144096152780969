import React from 'react'
import { Dropdown } from '../popper'
import {
  //@ts-ignore WIP import
  ScrollContainer,
} from '../scroll'
import type { DropdownInputProps } from './dateInput'

export const MonthDropdownInput: React.FC<DropdownInputProps<string>> = ({
  onChange,
  value,
  ...props
}) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        className={`btn btn-input tw-mx-2 ${props.className}`}
      >
        {value !== null ? value : 'Month'}
      </Dropdown.ToggleButton>
      <Dropdown.Menu placement='bottom-end'>
        <ScrollContainer maxHeight='196px'>
          {months.map((month, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                onChange(month)
              }}
            >
              {month}
            </Dropdown.Item>
          ))}
        </ScrollContainer>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
