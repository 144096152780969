import type { HorizontalRuleAttributes } from '@blissbook/lib/document'
import { HorizontalRuleNode } from '@blissbook/lib/document/schema/nodes'
import { wrapSelectableNodeView } from '../selection'

export const HorizontalRuleEditorNode = HorizontalRuleNode.extend({
  addNodeView() {
    return (props) => {
      const { node } = props
      const attrs = node.attrs as HorizontalRuleAttributes
      const { borderColor } = attrs

      const dom = document.createElement('hr')
      if (borderColor) dom.style.backgroundColor = borderColor

      return wrapSelectableNodeView(dom, props)
    }
  },
})
