import { isMac } from './platform'

const shortcutsByKey: Record<string, string> = {
  alt: '⌥',
  mod: isMac ? '⌘' : 'Ctrl',
  shift: '⇧',
}

export function renderKeyboardShortcut(input: string | string[]) {
  const inputs = Array.isArray(input) ? input : input.split('-')
  const keys = inputs.map(
    (key) => shortcutsByKey[key.toLowerCase()] || key.toUpperCase(),
  )

  const joinChar = keys.some((key) => key.length > 1) ? '+' : ''
  return keys.join(joinChar)
}
