import { useForceUpdate } from '@blissbook/ui/util/hooks'
import { useEffect } from 'react'
import type { Editor } from './editor'

export function useEditorTransaction(editor?: Editor) {
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (!editor) return

    function onUpdate() {
      forceUpdate()
    }

    editor.on('transaction', onUpdate)
    return () => {
      editor.off('transaction', onUpdate)
    }
  }, [editor])
}
