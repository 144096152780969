import { type Range, posToDOMRect } from '@tiptap/core'
import type { Selection } from 'prosemirror-state'
import { useMemo } from 'react'
import type { Editor } from '../editor'

function reduceDOMRects(rects: DOMRect[]) {
  return rects.reduce((result, rect) => {
    const left = Math.min(result.left, rect.left)
    const right = Math.max(result.right, rect.right)
    const width = right - left

    const top = Math.min(result.top, rect.top)
    const bottom = Math.max(result.bottom, rect.bottom)
    const height = bottom - top

    return new DOMRect(left, top, width, height)
  })
}

export const createVirtualRangeElement = (editor: Editor, range: Range) => ({
  getBoundingClientRect() {
    const { from, to } = range
    return posToDOMRect(editor.view, from, to)
  },
})

export const useVirtualRangeElement = (editor: Editor, range: Range) =>
  useMemo(() => createVirtualRangeElement(editor, range), [editor.view, range])

export const createVirtualSelectionElement = (
  editor: Editor,
  selection: Selection,
) => ({
  getBoundingClientRect() {
    const rects = selection.ranges.map((range) => {
      const from = range.$from.pos - 1
      const to = range.$to.pos - 1
      return posToDOMRect(editor.view, from, to)
    })

    return reduceDOMRects(rects)
  },
})

export const useVirtualSelectionElement = (
  editor: Editor,
  selection: Selection,
) =>
  useMemo(
    () => createVirtualSelectionElement(editor, selection),
    [editor.view, selection],
  )
