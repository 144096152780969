import type { VariableAttributes } from '@blissbook/lib/document'
import { VariableNode } from '@blissbook/lib/document/schema/nodes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactDOM from 'react-dom'
import type { Editor } from '../editor'
import { wrapSelectableNodeView } from './selection'

export const VariableEditorNode = VariableNode.extend({
  addNodeView() {
    return (props) => {
      const { editor, node } = props
      const { variables = [] } = editor as Editor
      const { format, name } = node.attrs as VariableAttributes
      const variable = variables.find(
        (v) => v.name === name && v.format === format,
      )
      const label = variable ? variable.label : `id:${name}`

      const dom = document.createElement('span')
      dom.classList.add('rw-variable')
      dom.dataset.variableName = name
      if (format) dom.dataset.format = format
      ReactDOM.render(
        <>
          {variable && (
            <FontAwesomeIcon
              className='tw-mr-1.5 tw-text-gray-700'
              css={{ fontSize: '.75em' }}
              icon={variable.category.variableIcon}
            />
          )}
          {label}
        </>,
        dom,
      )

      return wrapSelectableNodeView(dom, props)
    }
  },
})
