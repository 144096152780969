import * as googleDocNormalizer from './googleDoc'
import * as msWordNormalizer from './msWord'
import * as unknownNormalizer from './unknown'

type Normalizer = {
  isMatch: (html: string) => boolean
  normalizeHtml: (html: string) => string
}

const normalizers: Normalizer[] = [msWordNormalizer, googleDocNormalizer]

function getNormalizer(html: string) {
  return normalizers.find((n) => n.isMatch(html)) || unknownNormalizer
}

export function normalizeHtml(html: string) {
  // console.log('normalizeHtml', html)

  // Leave content pasted from prosemirror along
  const isProseMirror = html.includes('data-pm-slice')
  if (isProseMirror) return html

  // Otherwise, try to normalize the data
  const normalizer = getNormalizer(html)
  return normalizer.normalizeHtml(html)
}
