import './submit.scss'
import classnames from 'classnames'
import React from 'react'
import { forwardRef } from 'react'
import { Button } from '../buttons'

const marginLeft = '1rem'

export type FormSubmitProps = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  helpUrl?: string
  isSubmitting?: boolean
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>
  rightButton?: any
  size?: string
  submitStyle?: React.CSSProperties
}

export const FormSubmit = forwardRef<HTMLButtonElement, FormSubmitProps>(
  (
    {
      children,
      className,
      disabled,
      helpUrl, // Help URL
      isSubmitting, // Whether or not the form is submitting
      onCancel, // Custom cancel handler
      onSubmit, // Custom submit handler
      rightButton,
      size,
      submitStyle,
      ...props
    },
    ref,
  ) => (
    <div {...props} className={classnames('form-submit', className)}>
      <Button
        children={children}
        className='btn btn-primary'
        disabled={disabled || isSubmitting}
        onClick={onSubmit}
        ref={ref}
        size={size}
        style={submitStyle}
        type={onSubmit ? 'button' : 'submit'}
      />

      <If condition={onCancel && !isSubmitting}>
        <Button className='btn-cancel' css={{ marginLeft }} onClick={onCancel}>
          Cancel
        </Button>
      </If>

      <Choose>
        <When condition={rightButton && !isSubmitting}>
          <div css={{ flex: 1 }} />
          <Button {...rightButton} css={{ marginLeft }} />
        </When>
        <When condition={!!helpUrl}>
          <div css={{ flex: 1 }} />
          <a
            href={helpUrl}
            rel='noopener noreferrer'
            target='_blank'
            css={{ fontSize: 12, marginLeft }}
          >
            Need help?
          </a>
        </When>
      </Choose>
    </div>
  ),
)
