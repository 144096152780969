export const label = 'Vimeo'
export const exampleUrl = 'https://vimeo.com/28164488'

export const playerProps = {
  controls: true,
}

const urlRegEx = /^https?:\/\/vimeo\.com\/([^?\s]+)$/i

export function fromUrl(url: string) {
  const match = url.match(urlRegEx)
  if (match)
    return {
      url: `https://vimeo.com/${match[1]}`,
    }
}
