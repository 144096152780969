import {
  type TableCellNodeAttributes,
  type TableNodeAttributes,
  defaultTableNodeAttributes,
  getContainerNodeFromPos,
} from '@blissbook/lib/document'
import {
  ProseMirrorBubbleToolbar,
  ToolbarDivider,
} from '@blissbook/ui/editor/toolbar'
import { BorderColorTool } from '@blissbook/ui/editor/tools'
import { TableMap } from 'prosemirror-tables'
import React from 'react'
import { ExpressionTool } from '../../tools'
import { useVirtualSelectionElement } from '../util'
import {
  MergeTableCellsTool,
  RemoveTableColumnTool,
  RemoveTableRowTool,
  RemoveTableTool,
  SplitTableCellTool,
  TableAlignTool,
  TableCellBackgroundColorTool,
  TableCellVerticalAlignTool,
  TableTidyUpTool,
} from './tools'
import type { CellSelectionViewProps } from './view'

function setBorderColor(tableEl: HTMLTableElement, color: string | undefined) {
  const cellEls = tableEl.querySelectorAll('td')
  for (const cellEl of cellEls) {
    cellEl.style.borderColor = color
  }
}

export const CellSelectionToolbar = ({
  selection,
  ...props
}: CellSelectionViewProps) => {
  const { editor } = props
  const virtualEl = useVirtualSelectionElement(editor, selection)

  const table = getContainerNodeFromPos(selection.$from, 'table')
  const { borderColor, expression } = table.node.attrs as TableNodeAttributes
  const isColSelection = selection.isColSelection()
  const isRowSelection = selection.isRowSelection()
  const isTableSelection = isColSelection && isRowSelection

  const tableEl = editor.view.nodeDOM(table.pos) as HTMLTableElement
  const tableMap = TableMap.get(table.node)
  const isTopRow = selection.ranges.some((range) => {
    const cell = tableMap.findCell(range.$from.pos - table.pos - 2)
    return cell.top === 0
  })

  const canMerge = selection.ranges.length > 1
  const canSplit =
    selection.ranges.length === 1 &&
    selection.ranges.every((range) => {
      const node = range.$from.node()
      const { colspan, rowspan } = node.attrs as TableCellNodeAttributes
      return colspan > 1 || rowspan > 1
    })

  const selectedCols = new Set<number>()
  const selectedRows = new Set<number>()
  for (const range of selection.ranges) {
    const cell = tableMap.findCell(range.$from.pos - table.pos - 2)
    selectedCols.add(cell.left)
    selectedRows.add(cell.top)
  }

  return (
    <ProseMirrorBubbleToolbar
      {...props}
      refEl={virtualEl}
      offset={isTopRow ? 18 : 2}
    >
      <If condition={isTableSelection}>
        <TableAlignTool editor={editor} table={table} />

        <ToolbarDivider />
      </If>

      <TableCellVerticalAlignTool editor={editor} selection={selection} />

      <ToolbarDivider />

      <If condition={canMerge}>
        <MergeTableCellsTool editor={editor} />
      </If>

      <If condition={canSplit}>
        <SplitTableCellTool editor={editor} />
      </If>

      <If condition={isTableSelection}>
        <BorderColorTool
          defaultValue={defaultTableNodeAttributes.borderColor}
          editor={editor}
          onChange={(borderColor) => {
            setBorderColor(tableEl, undefined)
            editor
              .chain()
              .focus()
              .updateNodeAttributes(table.pos, { borderColor })
              .run()
          }}
          onChanging={(color) => {
            setBorderColor(tableEl, color)
          }}
          value={borderColor}
        />
      </If>

      <TableCellBackgroundColorTool editor={editor} selection={selection} />

      <If condition={isTableSelection}>
        <TableTidyUpTool editor={editor} table={table} />
      </If>

      <If condition={!expression && isTableSelection}>
        <ToolbarDivider />
        <ExpressionTool editor={editor} />
      </If>

      <If condition={isRowSelection || isColSelection}>
        <ToolbarDivider />

        <If condition={isRowSelection && !isColSelection}>
          <RemoveTableRowTool editor={editor} rowCount={selectedRows.size} />
        </If>

        <If condition={isColSelection && !isRowSelection}>
          <RemoveTableColumnTool colCount={selectedCols.size} editor={editor} />
        </If>

        <If condition={isTableSelection}>
          <RemoveTableTool editor={editor} />
        </If>
      </If>
    </ProseMirrorBubbleToolbar>
  )
}
