import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export type InfiniteLoadingProps = {
  children: React.ReactNode
  className?: string
  isLoading: boolean
}

export function InfiniteLoading({
  children,
  className,
  isLoading,
}: InfiniteLoadingProps) {
  if (!isLoading) return null
  return (
    <div
      className={cx(
        'tw-flex tw-items-center tw-justify-center tw-text-gray-500',
        className,
      )}
    >
      {children}
      <FontAwesomeIcon className='tw-ml-2' icon='spinner' spin />
    </div>
  )
}
