import type { Editor } from '@blissbook/ui/editor'
import type { EditorState, Selection, Transaction } from 'prosemirror-state'
import { DecorationSet, type EditorView } from 'prosemirror-view'
import { ExpressionView, getExpressions } from './ExpressionView'

export class ExpressionsState {
  decorations: DecorationSet
  editor: Editor
  groups: ExpressionView[] = []

  constructor(editor: Editor) {
    this.editor = editor
    this.updateGroups(editor.state)
  }

  bindView(view: EditorView) {
    view.dom.addEventListener('mouseleave', this.onMouseLeave)
    view.dom.addEventListener('mouseover', this.onMouseOver)
  }

  unbindView(view: EditorView) {
    view.dom.removeEventListener('mouseleave', this.onMouseLeave)
    view.dom.removeEventListener('mouseover', this.onMouseOver)
  }

  private onMouseLeave = () => {
    for (const view of this.groups) {
      view.widgetEl.classList.remove('hover')
    }
  }

  private onMouseOver = (event: MouseEvent) => {
    let groupId: string
    const { target } = event
    if (target instanceof HTMLElement) {
      groupId = target.dataset.expressionId
    }

    for (const view of this.groups) {
      const isHover = view.group.id === groupId
      view.widgetEl.classList.toggle('hover', isHover)
    }
  }

  apply(tr: Transaction, newState: EditorState) {
    if (!tr.docChanged) {
      this.onChangeSelection(newState.selection)
    } else {
      this.updateGroups(newState)
    }

    return this
  }

  private updateGroups(state: EditorState) {
    const { editor } = this
    const groups = getExpressions(state.doc)
    this.groups = groups.map((group) => new ExpressionView(editor, group))
    this.onChangeSelection(state.selection)

    const decorations = this.groups.flatMap((g) => g.decorations)
    this.decorations = DecorationSet.create(state.doc, decorations)
  }

  private onChangeSelection(selection: Selection) {
    // Determine which group should have focus (if any)
    let focusGroup: ExpressionView = undefined
    for (const group of this.groups) {
      const { from, to } = group.group
      if (selection.from > from && selection.to < to) {
        focusGroup = group
      }
    }

    for (const group of this.groups) {
      const { widgetEl } = group
      const hasFocus = group === focusGroup
      widgetEl.classList.toggle('focus', hasFocus)
    }
  }

  updatePositions() {
    for (const group of this.groups) {
      group.updatePosition(this.editor.view)
    }
  }
}
