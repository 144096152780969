import axios from 'axios'
import debounce from 'lodash/debounce'
import * as Yup from 'yup'

const addMethod = (name: string, type: string) => {
  const validate = async (value: string): Promise<boolean> => {
    try {
      const res = await axios.post<any>(`/marketing/validate/${type}`, {
        value,
      })
      return res.data.valid
    } catch (_error) {
      return false
    }
  }

  const pendingValidations: {
    value: string
    resolve: (result: boolean) => void
  }[] = []

  const debounceValidate = debounce(async (value) => {
    // Run the validation
    const valid = await validate(value)

    // Run all the pending validations
    for (const validation of pendingValidations) {
      const result = value === validation.value ? valid : undefined
      validation.resolve(result)
    }

    // Clear the pending validations
    pendingValidations.splice(0, pendingValidations.length)
  }, 250)

  Yup.addMethod(Yup.string, name, function () {
    const testFn = async (value: string) => {
      if (!value) return true
      return new Promise<boolean>((resolve) => {
        pendingValidations.push({ value, resolve })
        return debounceValidate(value)
      })
    }

    return this.test(name, 'This ${path} is not available', testFn)
  })
}

addMethod('validEmail', 'email')
addMethod('validSubdomain', 'subdomain')
