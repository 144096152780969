import {
  type NodeViewRendererProps,
  type NodeWithPos,
  isTextSelection,
} from '@tiptap/core'
import type { Selection } from 'prosemirror-state'
import { getNodeViewPos } from './util'

export function isNodeWithinTextSelection(
  node: NodeWithPos,
  selection: Selection,
) {
  if (!isTextSelection(selection)) return false

  const from = node.pos
  const to = node.pos + node.node.nodeSize
  return from >= selection.from && to <= selection.to
}

export function updateSelectableNodeView(
  dom: HTMLElement,
  props: NodeViewRendererProps,
) {
  const { editor, getPos, node } = props
  const pos = getNodeViewPos(getPos)
  const { selection } = editor.state
  const isTextSelected = isNodeWithinTextSelection({ node, pos }, selection)
  dom.classList.toggle('text-selected', isTextSelected)
}

export function wrapSelectableNodeView(
  dom: HTMLElement,
  props: NodeViewRendererProps,
) {
  const { editor } = props
  updateSelectableNodeView(dom, props)

  function onSelectionChange() {
    updateSelectableNodeView(dom, props)
  }

  function selectNode() {
    dom.classList.add('selected')
  }

  function deselectNode() {
    dom.classList.remove('selected')
  }

  editor.on('selectionUpdate', onSelectionChange)

  return {
    dom,
    selectNode,
    deselectNode,
    destroy: () => {
      editor.off('selectionUpdate', onSelectionChange)
    },
  }
}
