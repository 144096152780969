import type { ReadMoreNodeAttributes } from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  NodeSelectionToolbar,
  type NodeSelectionToolbarProps,
} from '../../selection/node'
import { ToolbarButton } from '../../toolbar'
import { NodeExpressionTool } from '../../tools'

export const ReadMoreNodeSelectionToolbar: React.FC<
  NodeSelectionToolbarProps
> = (props) => {
  const { editor, selection } = props
  const { node } = selection
  const pos = selection.from
  const { expression } = node.attrs as ReadMoreNodeAttributes
  return (
    <NodeSelectionToolbar {...props}>
      {!expression && (
        <NodeExpressionTool editor={editor} node={{ node, pos }} />
      )}

      <ToolbarButton
        active
        label='Unwrap Read More'
        onClick={() => {
          editor.chain().focus().unwrapContainer(pos).run()
        }}
      >
        <FontAwesomeIcon icon={['fas', 'expand-wide']} />
      </ToolbarButton>
    </NodeSelectionToolbar>
  )
}
