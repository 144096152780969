import type { AudienceRootExpression } from '@blissbook/lib/expression'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export function getDefaultTableCellWidth(cols: number) {
  const maxTableWidth = 770
  const minCellWidth = 40
  const maxCellWidth = Math.floor(maxTableWidth / cols)
  return Math.max(minCellWidth, Math.min(100, maxCellWidth))
}

// TableAlign -----------------------------------------------------------------

export type TableAlign = 'left' | 'center' | 'right'

export type TableAlignDefinition = {
  className: string
  icon: IconProp
  label: string
  value: TableAlign
}

export const tableAligns: TableAlignDefinition[] = [
  {
    className: 'table-left',
    icon: ['fas', 'objects-align-left'],
    label: 'Align Table Left',
    value: 'left',
  },
  {
    className: 'table-center',
    icon: ['fas', 'objects-align-center-horizontal'],
    label: 'Align Table Center',
    value: 'center',
  },
  {
    className: 'table-right',
    icon: ['fas', 'objects-align-right'],
    label: 'Align Table Right',
    value: 'right',
  },
]

// TableNodeAttributes --------------------------------------------------------

export type TableNodeAttributes = {
  align?: TableAlign
  audienceExpression?: AudienceRootExpression
  borderColor?: string
  expression?: string
}

export const defaultTableNodeAttributes: TableNodeAttributes = {
  align: 'center',
  borderColor: 'rgba(0, 0, 0, 0.8)',
}

export const getTableNodeAttributes = (attrs?: TableNodeAttributes) => ({
  ...defaultTableNodeAttributes,
  ...attrs,
})

export function getTableCss({ borderColor }: TableNodeAttributes = {}) {
  return {
    td: {
      borderColor,
    },
  }
}

export function getTableClassName(attrs: TableNodeAttributes) {
  const align = tableAligns.find((a) => a.value === attrs.align)
  return align?.className
}

// TableCellVerticalAlign --------------------------------------------------------------

export type TableCellVerticalAlign = 'top' | 'middle' | 'bottom'

type TableCellVerticalAlignDefinition = {
  icon: IconProp
  label: string
  value: TableCellVerticalAlign
}

export const tableCellVerticalAligns: TableCellVerticalAlignDefinition[] = [
  {
    icon: ['fas', 'objects-align-top'],
    label: 'Align Content Top',
    value: 'top',
  },
  {
    icon: ['fas', 'objects-align-center-vertical'],
    label: 'Align Content Middle',
    value: 'middle',
  },
  {
    icon: ['fas', 'objects-align-bottom'],
    label: 'Align Content Bottom',
    value: 'bottom',
  },
]

export function getTableCellVerticalAlign(value: TableCellVerticalAlign) {
  return (
    tableCellVerticalAligns.find((va) => va.value === value) ||
    tableCellVerticalAligns[1]
  )
}

// TableCellNodeAttributes ----------------------------------------------------

export type TableCellNodeAttributes = {
  backgroundColor?: string
  colspan?: number
  height?: number
  rowspan?: number
  width?: number
  valign?: TableCellVerticalAlign
}
