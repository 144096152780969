import { colors } from '@blissbook/ui/branding'
import React, { forwardRef } from 'react'
import Switch from 'react-switch'

type ToggleButtonProps = {
  checked: boolean
  height?: number
  onChange: (checked: boolean) => void
  width?: number
}

// See: https://www.npmjs.com/package/react-switch
export const ToggleButton = forwardRef<HTMLDivElement, ToggleButtonProps>(
  ({ height = 22, width = 40, ...props }, ref) => (
    <div className='tw-flex tw-items-center tw-justify-center' ref={ref}>
      <Switch
        activeBoxShadow={null}
        checkedIcon={false}
        handleDiameter={height - 8}
        height={height}
        offColor={colors['gray-200']}
        offHandleColor={colors['gray-500']}
        onColor={colors['blurple-200']}
        onHandleColor={colors['blurple-500']}
        width={width}
        uncheckedIcon={false}
        {...props}
      />
    </div>
  ),
)
