import { useQueryParam } from '@blissbook/ui/hooks/useQueryParam'
import { useCallback, useMemo, useState } from 'react'
import { type SortOrder, parseSortOrder, stringifySortOrder } from './SortOrder'

/** Hook to maintain sort order within memory */
export function useSortOrderState(defaultValue?: SortOrder | string) {
  return useState(() => parseSortOrder(defaultValue))
}

/** Hook to maintain sort order in the URL via */
export function useSortOrderLocationState(defaultValue?: SortOrder | string) {
  const [value, setValue] = useQueryParam('order')

  const order = useMemo(
    () => parseSortOrder(value || defaultValue),
    [value, defaultValue],
  )

  const setOrder = useCallback(
    (newOrder: SortOrder) => {
      const newValue = newOrder ? stringifySortOrder(newOrder) : undefined
      setValue(newValue)
    },
    [setValue],
  )

  return [order, setOrder] as const
}
