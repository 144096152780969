export const label = 'YouTube'
export const exampleUrl = 'https://www.youtube.com/watch?v=1duv0yXErPc'

const urlRegExs = [
  /^https?:\/\/www\.youtube\.com\/watch\?v=([^&\s]+)$/i,
  /^www\.youtube\.com\/watch\?v=([^&\s]+)$/i,
  /^youtube\.com\/watch\?v=([^&\s]+)$/i,
  /^https?:\/\/youtu\.be\/([^?\s]+)$/i,
  /^youtu\.be\/([^?\s]+)$/i,
]

export function fromUrl(url: string) {
  for (const urlRegEx of urlRegExs) {
    const match = url.match(urlRegEx)
    if (!match) continue

    const id = match[1]
    return {
      url: `https://www.youtube.com/watch?v=${id}`,
      coverUrl: `https://img.youtube.com/vi/${id}/hqdefault.jpg`,
    }
  }
}
