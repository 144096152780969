const inlineTagNames = [
  'A',
  'B',
  'BR',
  'DEL',
  'EM',
  'I',
  'INS',
  'S',
  'SPAN',
  'STRONG',
  'U',
]

function createParagraph(nodes: Node[]) {
  const paragraphEl = document.createElement('p')
  for (const node of nodes) {
    paragraphEl.appendChild(node)
  }
  return paragraphEl
}

function wrapBody(document: Document) {
  const { body } = document

  const bodyNodes: Node[] = []
  let inlineNodes: Node[] = []

  // Ensure everything hanging off the body is wrapped
  for (const node of document.body.childNodes) {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node as Text
      if (text.textContent.trim().length) {
        inlineNodes.push(node)
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const el = node as Element
      if (inlineTagNames.includes(el.tagName)) {
        inlineNodes.push(node)
        continue
      }

      if (inlineNodes.length) {
        const paragraphEl = createParagraph(inlineNodes)
        bodyNodes.push(paragraphEl)
        inlineNodes = []
      }

      bodyNodes.push(node)
    }
  }

  if (inlineNodes.length) {
    const paragraphEl = createParagraph(inlineNodes)
    bodyNodes.push(paragraphEl)
  }

  body.replaceChildren(...bodyNodes)
}

function removeBodyDivs(document: Document) {
  const { body } = document
  let childEl = body.firstElementChild
  while (childEl) {
    if (childEl.tagName === 'DIV') {
      childEl.replaceWith(...childEl.childNodes)
      childEl = body.firstElementChild
    } else {
      childEl = childEl.nextElementSibling
    }
  }
}

export function normalizeBody(document: Document) {
  removeBodyDivs(document)
  wrapBody(document)
}
