import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { Dropdown } from '../popper'
import {
  //@ts-ignore WIP import
  ScrollContainer,
} from '../scroll'
import type { DropdownInputProps } from './dateInput'

export const DayDropdownInput: React.FC<DropdownInputProps<number>> = ({
  onChange,
  value,
  ...props
}) => {
  const days = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString().padStart(2, '0'),
  )

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        className={`btn btn-input tw-mx-2 ${props.className}`}
      >
        {value !== null ? value.toString().padStart(2, '0') : 'Day'}
      </Dropdown.ToggleButton>
      <Dropdown.Menu placement='bottom-end'>
        <ScrollContainer maxHeight='196px'>
          {days.map((day, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                onChange(Number.parseInt(day, 10))
              }}
            >
              {day}
            </Dropdown.Item>
          ))}
        </ScrollContainer>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
