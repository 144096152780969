import { browserTracingIntegration, replayIntegration } from '@sentry/browser'

import config from '@blissbook/ui-config'
import * as Sentry from '@sentry/react'
import type { ErrorEvent } from '@sentry/types'
import { ignoreErrors } from './sentryIgnoreErrors'

const env: string = String(config.env)

export const initializeSentry = () => {
  if (!config.sentry.enabled) return

  try {
    Sentry.init({
      dsn: config.sentry.dsn,
      integrations: [browserTracingIntegration(), replayIntegration()],
      tracesSampleRate: 0.1,
      sampleRate: 0.5,
      attachStacktrace: true,
      environment: env,
      release: config.sentry.release,
      ignoreErrors,
      beforeSend(event: ErrorEvent): ErrorEvent {
        if (['info', 'debug', 'warning'].includes(event.level)) {
          return null
        }

        if (!['production', 'staging'].includes(env)) {
          return null
        }

        return event
      },
    })
  } catch (error) {
    console.error('Error initializing Sentry:', error)
  }
}

export function logUIError(error: any, extra?: Record<string, any>) {
  if (!config.sentry.enabled) {
    console.error(error)
    return
  }

  const message =
    error?.stack && error.message ? error.message : 'Unknown Error'

  Sentry.withScope((scope) => {
    scope.setExtras({
      ...extra,
      url: window.location.href,
    })
    scope.addBreadcrumb({
      type: 'error',
      category: 'error',
      message,
    })
    scope.setTag('scope', 'client')

    return Sentry.captureException(error)
  })
}
