import {
  type Expression,
  type RootExpression,
  buildRootExpressionFromOperand,
} from './Expression'
import type {
  GroupsOperandValue,
  PeopleOperandValue,
  SavedSegmentsOperandValue,
  SelectOperandValue,
} from './Operand'
import { LogicalExpression, type Variant, type VariantType } from './deprecated'
import {
  AudienceSelectOperandField,
  ExpressionConjunction,
  OperandType,
} from './types'

export type AudienceOperandValue =
  | AudienceIdsOperandValue
  | AudienceSelectOperandValue

export type AudienceExpression = Expression<AudienceOperandValue>

export type AudienceRootExpression = RootExpression<AudienceOperandValue>

// AudienceIdsOperandValue ----------------------------------------------------

export type AudienceIdsOperandValue =
  | GroupsOperandValue
  | PeopleOperandValue
  | SavedSegmentsOperandValue

export type AudienceIdsOperandType = AudienceIdsOperandValue['type']

export type AudienceIdsOperandValueInput = Pick<
  AudienceIdsOperandValue,
  'type' | 'ids'
> &
  Partial<Omit<AudienceIdsOperandValue, 'type' | 'ids'>>

export function buildAudienceOperandFromIds(
  input: AudienceIdsOperandValueInput,
): AudienceIdsOperandValue {
  return {
    isEvery: false,
    isNot: false,
    ...input,
  }
}

export function buildAudienceRootExpressionFromIds(
  input: AudienceIdsOperandValueInput,
): AudienceRootExpression {
  const operand = buildAudienceOperandFromIds(input)
  return buildRootExpressionFromOperand(operand)
}

// AudienceSelectOperandValue -------------------------------------------------

export type AudienceSelectOperandValue = Omit<SelectOperandValue, 'field'> & {
  field: AudienceSelectOperandField
}

export type AudienceMetadataOperandValueInput = Pick<
  AudienceSelectOperandValue,
  'path' | 'values'
>

export function buildMetadataOperand(
  input: AudienceMetadataOperandValueInput,
): AudienceSelectOperandValue {
  return {
    ...input,
    type: OperandType.Select,
    field: AudienceSelectOperandField.Metadata,
    isEvery: false,
    isNot: false,
  }
}

export function buildAudienceExpressionFromMetadata(
  input: AudienceMetadataOperandValueInput,
): AudienceRootExpression {
  const operand = buildMetadataOperand(input)
  return buildRootExpressionFromOperand(operand)
}

// mapLogicalExpressionStringToAudienceExpression -----------------------------

const operandTypeByVariantType = new Map<VariantType, AudienceIdsOperandType>([
  ['group', OperandType.Groups],
  ['person', OperandType.People],
  ['savedSegment', OperandType.SavedSegments],
])

function mapVariantsToOperands(
  variants: Variant[],
): AudienceIdsOperandValueInput[] {
  const operandByType = new Map<
    AudienceIdsOperandType,
    AudienceIdsOperandValueInput
  >()

  for (const variant of variants) {
    const operandType = operandTypeByVariantType.get(variant.type)
    if (!operandType) throw new Error(`Unknown variant type: ${variant.type}`)

    const operand = operandByType.get(operandType)
    if (operand) {
      operand.ids.push(variant.id)
    } else {
      operandByType.set(operandType, {
        type: operandType,
        ids: [variant.id],
      })
    }
  }

  return [...operandByType.values()]
}

export function mapLogicalExpressionStringToAudienceExpression(
  value: string | null,
): AudienceRootExpression | null {
  // Everyone
  if (value === null) return null

  const audienceExpression: AudienceRootExpression = {
    conjunction: ExpressionConjunction.Or,
    expressions: [],
  }

  const logicalExpression = LogicalExpression.fromString(value)
  for (const rule of logicalExpression.rules) {
    const expression: AudienceExpression = {
      conjunction: ExpressionConjunction.And,
      operands: [],
    }

    if (rule.includes) {
      const operands = mapVariantsToOperands(rule.includes)
      for (const operand of operands) {
        const isEvery = operand.ids.length > 1
        expression.operands.push({
          ...operand,
          isEvery,
          isNot: false,
        })
      }
    }

    if (rule.excludes) {
      const operands = mapVariantsToOperands(rule.excludes)
      for (const operand of operands) {
        expression.operands.push({
          ...operand,
          isEvery: false,
          isNot: true,
        })
      }
    }

    audienceExpression.expressions.push(expression)
  }

  return audienceExpression
}

export function stringifyAudienceExpression(
  audienceExpression: AudienceRootExpression,
) {
  return JSON.stringify(audienceExpression)
}
