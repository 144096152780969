import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import type { Editor } from '@tiptap/core'
import keyBy from 'lodash/keyBy'
import { keyboardShortcuts } from './keyboard'

export type ListVariant = 'bulleted' | 'numbered'

export type ListDefinition = {
  keyboardShortcut: string
  icon: IconProp
  label: string
  name: string
  nodeName: string
  tagName: string
  variant: ListVariant
}

export const bulletList: ListDefinition = {
  keyboardShortcut: keyboardShortcuts.bulletList,
  icon: ['fas', 'list-ul'],
  label: 'Bulleted List',
  name: 'bulletList',
  nodeName: 'UL',
  tagName: 'ul',
  variant: 'bulleted',
}

export const orderedList: ListDefinition = {
  keyboardShortcut: keyboardShortcuts.orderedList,
  icon: ['fas', 'list-ol'],
  label: 'Numbered List',
  name: 'orderedList',
  nodeName: 'OL',
  tagName: 'ol',
  variant: 'numbered',
}

export const lists: ListDefinition[] = [bulletList, orderedList]

export const listNodeTypes = ['bulletList', 'orderedList']

export const listsByVariant = keyBy(lists, 'variant')

export const getActiveList = (editor: Editor) =>
  lists.find((list) => {
    return editor.isActive(list.name)
  })
