import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ToolbarButton } from '../toolbar'
import { hasExtension } from '../util'
import type { ToolComponentProps } from './types'

export const WrapInReadMoreTool: React.FC<ToolComponentProps> = ({
  editor,
  ...props
}) => {
  const disabled =
    !hasExtension(editor, 'readMore') || !editor.can().wrapInReadMore()
  return (
    <ToolbarButton
      {...props}
      active={editor ? editor.isActive('readMore') : false}
      disabled={disabled}
      label='Wrap In Read More'
      onClick={() => {
        editor.chain().focus().wrapInReadMore().run()
      }}
    >
      <FontAwesomeIcon icon={['fas', 'expand-wide']} />
    </ToolbarButton>
  )
}
