import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { type CSSProperties, type ReactNode } from 'react'
import { Tooltip, type TooltipProps } from './Tooltip'

export type IconTooltipProps = Omit<TooltipProps, 'content'> & {
  children: ReactNode
  icon: IconProp
  style?: CSSProperties
}

export const IconTooltip = ({
  className,
  children,
  icon,
  placement = 'right',
  style,
  ...props
}: IconTooltipProps) => (
  <Tooltip {...props} content={children} placement={placement}>
    <span
      className={className}
      css={{
        cursor: 'pointer',
        display: 'inline-block',
      }}
      style={style}
    >
      <FontAwesomeIcon icon={icon} />
    </span>
  </Tooltip>
)
