import type { VariableCategory, VariableOption } from '@blissbook/lib/document'

const organizationCategory: VariableCategory = {
  dropdownIcon: ['far', 'building'],
  label: 'Organization',
  variableIcon: 'building',
}

export const organizationNameVariable: VariableOption = {
  category: organizationCategory,
  name: 'organization.name',
  label: 'Organization Name',
}

const handbookCategory: VariableCategory = {
  dropdownIcon: ['far', 'file-invoice'],
  label: 'Document',
  variableIcon: 'file-invoice',
}

export const handbookNameVariable: VariableOption = {
  category: handbookCategory,
  name: 'handbook.name',
  label: 'Document Name',
}

export const handbookVariables: VariableOption[] = [
  handbookNameVariable,
  {
    category: handbookCategory,
    name: 'handbook.viewingLink',
    label: 'Document View Link',
  },
]

const personCategory: VariableCategory = {
  dropdownIcon: ['far', 'user'],
  label: 'People',
  variableIcon: 'user',
}

export const fromVariables: VariableOption[] = [
  {
    category: personCategory,
    name: 'from.firstName',
    label: 'From: First Name',
  },
  {
    category: personCategory,
    name: 'from.fullName',
    label: 'From: Full Name',
  },
]

export const toVariables: VariableOption[] = [
  {
    category: personCategory,
    name: 'to.firstName',
    label: 'To: First Name',
  },
  {
    category: personCategory,
    name: 'to.fullName',
    label: 'To: Full Name',
  },
]

const invitateDateCategory: VariableCategory = {
  dropdownIcon: ['far', 'calendar-plus'],
  label: 'Invitation Date',
  variableIcon: 'calendar-plus',
}

export const invitationDateVariables: VariableOption[] = [
  {
    category: invitateDateCategory,
    dropdownItem: '"Month D, YYYY"',
    label: 'Invitation Date: "Month D, YYYY"',
    name: 'signatureRequestedAt',
    format: 'MMMM D, YYYY',
  },
  {
    category: invitateDateCategory,
    dropdownItem: '"YYYY-M-D"',
    label: 'Invitation Date: "YYYY-M-D"',
    name: 'signatureRequestedAt',
    format: 'YYYY-M-D',
  },
]

const signatureDueDateCategory: VariableCategory = {
  dropdownIcon: ['far', 'file-signature'],
  label: 'Signature Due Date',
  variableIcon: 'file-signature',
}

export const signatureDueDateVariables: VariableOption[] = [
  {
    category: signatureDueDateCategory,
    dropdownItem: '"Month D, YYYY"',
    label: 'Signature Due Date: "Month D, YYYY"',
    name: 'signatureDueDate',
    format: 'MMMM D, YYYY',
  },
  {
    category: signatureDueDateCategory,
    dropdownItem: '"YYYY-M-D"',
    label: 'Signature Due Date: "YYYY-M-D"',
    name: 'signatureDueDate',
    format: 'YYYY-M-D',
  },
]

export const emailVariables = [
  organizationNameVariable,
  ...handbookVariables,
  ...fromVariables,
  ...toVariables,
  ...invitationDateVariables,
  ...signatureDueDateVariables,
]
