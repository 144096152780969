import React, { useContext } from 'react'

export type InfiniteState<RowType = any> = {
  isLoaded: boolean
  isLoading: boolean
  rows: RowType[]
}

export type LoadMoreFunction = (lastRow: any) => Promise<any[]>

export type InfiniteProps<RowType = any> = InfiniteState<RowType> & {
  onLoadMore: () => void
  removeRow?: (row: RowType) => void
  updateRow?: (row: RowType, changes: Partial<RowType>) => void
}

export const InfiniteContext = React.createContext<InfiniteProps>(undefined)

/** Hooks to expose the current infinite scroll context */
export function useInfiniteContext() {
  return useContext(InfiniteContext)
}

export type InfiniteComponentProps<ComponentProps> = Omit<
  ComponentProps,
  'rows'
> & {
  infinite: InfiniteProps
}

export function wrapInfinite<ComponentProps>(
  Component: React.FC<ComponentProps>,
) {
  return ({ infinite, ...props }: InfiniteComponentProps<ComponentProps>) => (
    <InfiniteContext.Provider value={infinite}>
      <Component {...(props as ComponentProps)} rows={infinite.rows} />
    </InfiniteContext.Provider>
  )
}
