import tinycolor from 'tinycolor2'

const allowTextBlockStyles = ['text-align']

const allowedStylesByTagName: Record<string, string[]> = {
  H1: allowTextBlockStyles,
  H2: allowTextBlockStyles,
  H3: allowTextBlockStyles,
  H4: allowTextBlockStyles,
  H5: allowTextBlockStyles,
  H6: allowTextBlockStyles,
  P: allowTextBlockStyles,
  LI: [],
  SPAN: ['color'],
  TD: ['background-color', 'width'],
}

export function normalizeStyles(document: Document) {
  const styledNodes = document.querySelectorAll<HTMLElement>('[style]')
  for (const node of styledNodes) {
    // Remove disallowed styles
    const allowedStyles = allowedStylesByTagName[node.tagName]
    if (allowedStyles) {
      for (let i = node.style.length; i--; ) {
        const name = node.style[i]
        if (!allowedStyles.includes(name)) {
          node.style.removeProperty(name)
        }
      }
    }

    // Remove color, if the color is black
    const { color } = node.style
    if (color && tinycolor.equals(color, 'black')) {
      node.style.removeProperty('color')
    }

    // If no styles left, drop the styles completely
    if (!node.style.length) {
      node.removeAttribute('style')
    }
  }
}
