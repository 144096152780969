import type { BlissbookHandbookBrandingColorKey } from '@blissbook/lib/blissbook'
import type { HandbookDataModel } from '@blissbook/lib/models'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import tinycolor from 'tinycolor2'
import { sectionAttrColorKeys } from '../types'
import { handbookDescendants } from '../util'

export type PaletteColor = {
  id: string
  label: string
  key: BlissbookHandbookBrandingColorKey
  defaultValue: string
}

export const paletteColors: PaletteColor[] = [
  {
    id: 'primary',
    label: 'Primary',
    key: 'primaryColor',
    defaultValue: '#3B80E5',
  },
  {
    id: 'black',
    label: 'Black',
    key: 'blackColor',
    defaultValue: '#333333',
  },
  {
    id: 'white',
    label: 'White',
    key: 'whiteColor',
    defaultValue: '#ffffff',
  },
]

export const paletteColorIds = paletteColors.map((c) => c.id)
export const paletteColorKeys = paletteColors.map((c) => c.key)

export function getHandbookColorCounts(
  handbook: HandbookDataModel,
  colorCounts = new Map<string, number>(),
) {
  const addColor = (color: string) => {
    const key = tinycolor(color).toHexString()
    const prevCount = colorCounts.has(key) ? colorCounts.get(key) : 0
    colorCounts.set(key, prevCount + 1)
  }

  // Section Attributes
  for (const section of handbook.sectionsById.values()) {
    const { attrs } = section
    if (attrs) {
      for (const key of sectionAttrColorKeys) {
        const value = attrs[key]
        if (value) addColor(value)
      }
    }
  }

  // Content
  handbookDescendants(handbook, (node) => {
    if (node.type === 'table') {
      const { borderColor } = node.attrs || {}
      if (borderColor) addColor(borderColor)
    } else if (node.type === 'tableCell') {
      const { backgroundColor } = node.attrs || {}
      if (backgroundColor) addColor(backgroundColor)
    } else if (node.type === 'text') {
      const { marks = [] } = node
      for (const mark of marks) {
        if (mark.type === 'color') {
          addColor(mark.attrs.color)
        }
      }
    }
  })

  return colorCounts
}

export function getColorPalette(
  handbook: HandbookDataModel | HandbookDataModel[],
) {
  const colorPalette = new Set<string>()
  const handbooks = isArray(handbook) ? handbook : [handbook]

  // Go through the branding colors first
  for (const key of paletteColorKeys) {
    for (const handbook of handbooks) {
      const value = handbook.branding[key]
      colorPalette.add(value)
    }
  }

  // Go through color counts
  const colorCounts = new Map<string, number>()
  for (const handbook of handbooks) {
    getHandbookColorCounts(handbook, colorCounts)
  }

  // Add to set
  const sortedColorCounts = new Map(
    [...colorCounts.entries()].sort((a, b) => b[1] - a[1]),
  )
  for (const color of sortedColorCounts.keys()) {
    colorPalette.add(color)
  }

  return [...colorPalette]
}

export type ColorPalette = string[]
export type ColorPaletteFunction = () => ColorPalette
export type ColorPaletteInput = ColorPalette | ColorPaletteFunction

export const resolveColorPalette = (palette?: ColorPaletteInput) => {
  if (!palette) return
  return isFunction(palette) ? palette() : palette
}
