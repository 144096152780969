import React, { useContext, useEffect, useState } from 'react'
import type { Editor } from './editor'

export type EditorCallbackFunction = (editor: Editor) => void

// ActiveEditorManager -------------------------------------------------------

export class ActiveEditorManager {
  active?: Editor
  activeToolbarEl?: HTMLDivElement
  bubbleToolbarEl?: HTMLDivElement
  listeners = new Set<EditorCallbackFunction>()

  bind() {
    document.addEventListener('mousedown', this.handleMouseDown, false)
  }

  unbind() {
    document.removeEventListener('mousedown', this.handleMouseDown, false)
  }

  addListener(listener: EditorCallbackFunction) {
    this.listeners.add(listener)
  }

  removeListener(listener: EditorCallbackFunction) {
    this.listeners.delete(listener)
  }

  handleBlur(editor: Editor) {
    if (editor === this.active) {
      this.setActive(undefined)
    }
  }

  handleFocus(editor: Editor) {
    this.setActive(editor)
  }

  private handleMouseDown = (event: MouseEvent) => {
    const { active, activeToolbarEl, bubbleToolbarEl } = this
    const targetEl = event.target as HTMLElement

    // If not active or within active editor, do nothing
    if (!active || active.viewEl.contains(targetEl)) return

    // If going to a toolbar, retain the current active
    const toolbarEls = [activeToolbarEl, bubbleToolbarEl]
    const isToolbar = toolbarEls.some((toolbarEl) =>
      toolbarEl?.contains(targetEl),
    )
    if (isToolbar) return

    // If the active editor lost focus, then that editor is no longer active
    setTimeout(() => {
      const isActive = active.viewEl.contains(document.activeElement)
      if (!isActive) active.handleBlur()
    })
  }

  private setActive(editor?: Editor) {
    this.active = editor

    for (const listener of this.listeners) {
      listener(editor)
    }
  }
}

// ActiveEditorContext -------------------------------------------------------

const ActiveEditorContext = React.createContext<ActiveEditorManager>(undefined)

export const ActiveEditorProvider: React.FC = ({ children }) => {
  const [manager] = useState(() => new ActiveEditorManager())
  useEffect(() => {
    manager.bind()
    return () => {
      manager.unbind()
    }
  }, [manager])

  return (
    <ActiveEditorContext.Provider value={manager}>
      {children}
    </ActiveEditorContext.Provider>
  )
}

export const useActiveEditorManager = () => useContext(ActiveEditorContext)

export function useActiveEditor(manager: ActiveEditorManager) {
  const [editor, setEditor] = useState<Editor>(manager.active)
  useEffect(() => {
    manager.addListener(setEditor)
    return () => {
      manager.removeListener(setEditor)
    }
  }, [manager])

  return editor
}
