import { Node } from '@tiptap/core'

export const HardBreakNode = Node.create({
  name: 'hardBreak',

  inline: true,

  group: 'inline',

  selectable: false,

  parseHTML() {
    return [{ tag: 'br' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['br', HTMLAttributes]
  },

  renderText() {
    return '\n'
  },
})
