import React from 'react'

const mapValueByType = {
  number: (value) => Number.parseFloat(value),
}

const mapValue = (value, type) => {
  const mapValue = mapValueByType[type]
  return mapValue ? mapValue(value) : value
}

export const TextInput = React.forwardRef(
  (
    {
      emptyValue = '', // Value for onChangeValue when the input is empty
      type = 'text',
      onChange,
      onChangeValue,
      value = '',
      ...props
    },
    ref,
  ) => (
    <input
      {...props}
      onChange={(event) => {
        const { target } = event
        const value = target.value.length
          ? mapValue(target.value, type)
          : emptyValue
        if (onChange) onChange(event, value)
        if (onChangeValue) onChangeValue(value)
      }}
      ref={ref}
      type={type}
      value={value === emptyValue ? '' : value}
    />
  ),
)
