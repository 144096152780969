import type { ParagraphNodeAttributes } from '@blissbook/lib/document'
import { ParagraphNode } from '@blissbook/lib/document/schema/nodes'

export const ParagraphEditorNode = ParagraphNode.extend({
  addNodeView() {
    return ({ HTMLAttributes, node }) => {
      // attrs
      const attrs = node.attrs as ParagraphNodeAttributes
      const { expression, id, indent } = attrs
      const { style } = HTMLAttributes

      const containerNode = document.createElement('div')
      containerNode.className = 'text-container'
      if (expression) containerNode.dataset.expression = expression
      if (indent) containerNode.classList.add(`bb-indent-${indent}`)

      const paragraphNode = document.createElement('p')
      paragraphNode.id = id
      paragraphNode.setAttribute('style', style)
      containerNode.appendChild(paragraphNode)

      return {
        dom: containerNode,
        contentDOM: paragraphNode,
      }
    }
  },
})
