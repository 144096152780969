import { Mark } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    color: {
      setColor: (color: string) => ReturnType
    }
  }
}

export const ColorMark = Mark.create({
  name: 'color',

  addAttributes() {
    return {
      color: {
        default: undefined,
        renderHTML: ({ color }) => {
          if (!color) return {}
          return { style: `color: ${color}` }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        style: 'color',
        getAttrs: (color) => {
          if (!color) return false
          return { color }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setColor:
        (color) =>
        ({ commands }) => {
          return commands.setMark('color', { color })
        },
    }
  },
})
