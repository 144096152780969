import { type TeamRoleId, getMaxTeamRoleIndex, getTeamRoleIndex } from '../team'
import type { Role } from '../types'
import { mapById } from '../util'

// Handbook Collaborator

export type HandbookCollaboratorRoleId = 'editor' | 'publisher'

export type HandbookCollaboratorRole = Role & {
  id: HandbookCollaboratorRoleId | null
}

export const handbookEditorRole: HandbookCollaboratorRole = {
  id: 'editor',
  label: 'Editor',
  description: 'Can view & edit this document.',
  color: 'purple',
  permissionIds: [
    'handbook.convert',
    'handbook.query',
    'handbook.draft.brand',
    'handbook.draft.edit',
    'handbook.draft.preview',
    'handbook.draft.view',
    'handbook.history.view',
    'handbook.sharing.view',
  ],
}

export const handbookPublisherRole: HandbookCollaboratorRole = {
  id: 'publisher',
  label: 'Publisher',
  description: 'Can view, edit & publish this document.',
  color: 'purple',
  permissionIds: [
    ...handbookEditorRole.permissionIds,
    'handbook.publish',
    'handbook.notifyPeople',
  ],
}

export const handbookOwnerRole: Role = {
  id: 'owner',
  label: 'Owner',
  description:
    'Can create, view, edit & publish the document. Can also manage document settings & collaborator permissions.',
  color: 'red',
  permissionIds: [
    ...handbookPublisherRole.permissionIds,
    'handbook.audience.edit',
    'handbook.collaborators.edit',
    'handbook.delete',
    'handbook.draft.brand',
    'handbook.recordSignature',
    'handbook.performTech',
    'handbook.signatureRounds.edit',
  ],
}

export const handbookCollaboratorRoles = [
  handbookEditorRole,
  handbookPublisherRole,
]

export const handbookCollaboratorRolesReverse = [
  ...handbookCollaboratorRoles,
].reverse()

export const handbookCollaboratorRolesById = mapById(handbookCollaboratorRoles)

export function getHandbookCollaboratorRole(
  roleId: HandbookCollaboratorRoleId | null,
) {
  return handbookCollaboratorRolesById.get(roleId)
}
