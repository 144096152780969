import type { Attrs } from 'prosemirror-model'
import type { Transaction } from 'prosemirror-state'
import { UpdateNodeAttributesStep } from '../steps'

export const updateNodeAttributes = (
  tr: Transaction,
  pos: number,
  attrs: Attrs,
) => {
  tr.step(new UpdateNodeAttributesStep(pos, attrs))
}
