import type { AudienceRootExpression } from '@blissbook/lib/expression'

export type HorizontalRuleAttributes = {
  audienceExpression?: AudienceRootExpression
  borderColor?: string
  expression?: string
}

export const defaultHorizontalNodeAttributes = {
  borderColor: '#bbbbbb',
}

export const getHorizontalNodeAttributes = (
  attrs?: HorizontalRuleAttributes,
) => ({
  ...defaultHorizontalNodeAttributes,
  ...attrs,
})
