import last from 'lodash/last'
import * as Yup from 'yup'

const invalidDomains = ['gmail.com', 'yahoo.com', 'hotmail.com']

Yup.addMethod(Yup.string, 'companyEmail', function () {
  return this.test(
    'companyEmail',
    'Please enter your work Email Address',
    (email) => {
      if (!email) return false
      const domain = last(email.split('@'))
      return !invalidDomains.includes(domain)
    },
  )
})
