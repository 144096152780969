import { cx } from '@emotion/css'
import drop from 'lodash/drop'
import take from 'lodash/take'
import React from 'react'
import { Tooltip } from '../tooltip'
import { Badge } from './Badge'

type NameBadgesProps = {
  className?: string
  Icon?: (props: { className?: string }) => JSX.Element
  maxCount?: number
  values: string[]
}

export const NameBadges: React.FC<NameBadgesProps> = ({
  Icon,
  maxCount = 2,
  values,
  ...props
}) => {
  const count = values.length <= maxCount ? values.length : maxCount - 1
  const takeValues = take(values, count)
  const otherValues = drop(values, count)

  return (
    <div
      {...props}
      className={cx('tw-flex tw-items-center tw-gap-1', props.className)}
    >
      <Choose>
        <When condition={!values.length}>
          <span className='tw-text-gray-500'>None</span>
        </When>
        <Otherwise>
          {takeValues.map((value, index) => (
            <Badge
              className='tw-min-w-0 tw-uppercase'
              key={index}
              title={value}
            >
              {Icon && <Icon className='tw-mr-1' />}
              {value}
            </Badge>
          ))}
          <If condition={otherValues.length > 0}>
            <Tooltip
              content={otherValues.map((value, index) => (
                <div key={index}>{value}</div>
              ))}
            >
              <Badge className='tw-ml-1 tw-uppercase'>
                {otherValues.length} others
              </Badge>
            </Tooltip>
          </If>
        </Otherwise>
      </Choose>
    </div>
  )
}
