import { useMemo } from 'react'
import type { TableColumn } from './types'

export const filterTableColumns = (columns: TableColumn[], props: any) =>
  columns.filter((column) => !column.show || column.show(props))

export const useTableColumns = (columns: TableColumn[], props: any) =>
  useMemo(
    () => filterTableColumns(columns, props),
    [columns, ...Object.values(props)],
  )
