import React from 'react'
import { Badge } from './Badge'

type HandbookStatusBadgeProps = {
  isPublished: boolean
}

export const HandbookStatusBadge: React.FC<HandbookStatusBadgeProps> = ({
  isPublished,
}) => {
  return (
    <Badge
      className='tw-capitalize'
      icon={['fas', 'circle']}
      variant={isPublished ? 'primary' : 'secondary'}
    >
      {isPublished ? 'Published' : 'Draft'}
    </Badge>
  )
}
