import { ReadMoreNode } from '@blissbook/lib/document/schema/nodes'
import { wrapSelectableNodeView } from '../selection'
import { getNodeViewPos } from '../util'

export const ReadMoreEditorNode = ReadMoreNode.extend({
  addNodeView() {
    return (props) => {
      const { editor, getPos } = props
      const dom = document.createElement('div')
      dom.className = 'read-more tw-mt-1.5'

      dom.addEventListener('dragstart', (event) => {
        event.preventDefault()
        event.stopPropagation()
        return false
      })

      function onSelect() {
        const pos = getNodeViewPos(getPos)
        editor.commands.setNodeSelection(pos)
      }

      function bindSelectElement(el: HTMLElement) {
        el.addEventListener('mousedown', (event) => {
          event.preventDefault()
          editor.view.dom.focus({ preventScroll: true })
          onSelect()
        })
      }

      const editorEl = document.createElement('div')
      editorEl.className = 'read-more-editor'
      editorEl.contentEditable = 'false'
      dom.append(editorEl)

      const buttonEl = document.createElement('button')
      buttonEl.className =
        'tw-absolute tw-left-1/2 -tw-ml-11 -tw-mt-1.5 tw-px-11 tw-py-1.5 tw-rounded brand-primary-background'
      editorEl.append(buttonEl)
      bindSelectElement(buttonEl)

      function addTargetElement(className: string) {
        const targetEl = document.createElement('div')
        targetEl.className = `tw-absolute tw-cursor-pointer ${className}`
        editorEl.append(targetEl)
        bindSelectElement(targetEl)
      }

      addTargetElement('tw-bottom-0 tw-top-0 tw-left-0 tw-w-4')
      addTargetElement('tw-bottom-0 tw-top-0 tw-right-0 tw-w-4')
      addTargetElement('tw-left-0 tw-right-0 tw-top-0 tw-h-4')
      addTargetElement('tw-left-0 tw-right-0 tw-bottom-0 tw-h-4')

      const contentNode = document.createElement('div')
      contentNode.className =
        'tw-bg-gray-100 tw-py-6 tw-px-6 tw-relative tw-z-0'
      dom.append(contentNode)

      return {
        ...wrapSelectableNodeView(dom, props),
        contentDOM: contentNode,

        ignoreMutation(mutation) {
          return mutation.type === 'attributes'
        },
      }
    }
  },
})
