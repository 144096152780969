import { isValidHttpUrl } from '@blissbook/lib/sanitize'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import $ from 'jquery'
import isFunction from 'lodash/isFunction'
import reduce from 'lodash/reduce'
import throttle from 'lodash/throttle'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'

// See https://github.com/reduxjs/react-redux/blob/master/src/utils/useIsomorphicLayoutEffect.js
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect

export const useForceUpdate = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  return forceUpdate
}

export const useInterval = (
  callback: () => void,
  milliseconds: number,
  deps: any[],
) =>
  useEffect(() => {
    const timerId = setInterval(callback, milliseconds)
    return () => {
      clearInterval(timerId)
    }
  }, deps)

type UsePromiseState<T> = [T, Error, boolean]
const defaultPromiseState: UsePromiseState<any> = [undefined, undefined, true]

// Returns [result, error, loading]
export const usePromise = <T>(callback: () => Promise<T>, deps: any[]) => {
  const [state, setState] = useState<UsePromiseState<T>>(defaultPromiseState)

  useEffect(() => {
    let isValid = true

    // Reset the state (if different)
    if (state !== defaultPromiseState) setState(defaultPromiseState)

    // Run the promise
    const promise = callback()
    promise
      .then((result) => {
        if (isValid) setState([result, undefined, false])
      })
      .catch((error) => {
        if (isValid) setState([undefined, error, false])
      })

    return () => {
      isValid = false
    }
  }, deps)

  return state
}

export const useThrottle = (
  callback: (...args: any[]) => any,
  milliseconds: number,
  deps: any[] = [],
) => useCallback(throttle(callback, milliseconds), [milliseconds, ...deps])

// It's like useState, except it passed a ref instead of the state, so you can update the state on-the-fly if you choose to
export const useStateRef = <T>(
  initialValue: T,
): [React.MutableRefObject<T>, React.Dispatch<T>] => {
  const forceUpdate = useForceUpdate()
  const ref = useRef<T>(initialValue)
  const setState = useCallback((value) => {
    ref.current = value
    forceUpdate()
  }, [])
  return [ref, setState]
}

// Retain a state value until the transiton ends (so we can kill it)
export function useTransitionState<T>(value: T, isOpen: boolean) {
  // Keep track of current value
  const [currValue, setCurrValue] = useState<T>(value)
  useIsomorphicLayoutEffect(() => {
    if (isOpen) setCurrValue(value)
  }, [value])

  // Reset value on a a CSS transition event for the current target
  const onTransitionEnd = useCallback(
    (event) => {
      if (event.target === event.currentTarget) setCurrValue(value)
    },
    [value],
  )

  return [currValue, onTransitionEnd] as const
}

// Get the current window size
const getWindowSize = () => ({
  height: $(window).height(),
  width: $(window).width(),
})

// Hook for returning the dimensions of the window
export const useWindowSize = () => {
  const [size, setSize] = useState(() => getWindowSize())

  useEffect(() => {
    // Handle resize
    const handleResize = () => {
      const size = getWindowSize()
      setSize(size)
    }

    // Update on bind
    handleResize()

    // Add / Remove handlers
    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [])

  return size
}

// Hook for getting the window width
export const useWindowWidth = () => {
  const { width } = useWindowSize()
  return useMemo(() => width, [width])
}

const areRectsEqualKeys: (keyof DOMRect)[] = ['x', 'y', 'width', 'height']

// Determine if these rects are equal
const areRectsEqual = (lhs: DOMRect, rhs: DOMRect) => {
  if (lhs === rhs) return true
  if (!lhs || !rhs) return false
  return areRectsEqualKeys.every((side) => lhs[side] === rhs[side])
}

// Hook for the bounding client rect for this node
export const useBoundingClientRect = (node: HTMLElement) => {
  const ref = useRef<DOMRect>()
  const [, setUpdatedAt] = useState<Date>()

  const setRect = (rect: DOMRect) => {
    if (!areRectsEqual(rect, ref.current)) {
      ref.current = rect
      setUpdatedAt(new Date())
    }
  }

  useIsomorphicLayoutEffect(() => {
    // If no node, reset and don't listen
    if (!node) {
      setRect(undefined)
      return
    }

    // Handle updates
    const onUpdate = () => {
      const rect = node.getBoundingClientRect()
      setRect(rect)
    }

    // Update on bind
    onUpdate()

    // Add / Remove handlers
    window.addEventListener('resize', onUpdate, false)
    window.addEventListener('scroll', onUpdate, false)
    return () => {
      window.removeEventListener('resize', onUpdate, false)
      window.removeEventListener('scroll', onUpdate, false)
    }
  }, [node])

  return ref.current
}

// Hook got handling resizes
export const useResize = (
  onResize: (this: Window, ev: UIEvent) => any,
  deps: any[],
) =>
  useEffect(() => {
    window.addEventListener('resize', onResize, false)
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, deps)

type Dimensions = {
  height: number
  width: number
}

// Hook for returning the dimensions of this node
export const useDimensions = (
  node: HTMLElement,
  deps: any[] = [],
): Dimensions => {
  const [state, setState] = useState<Dimensions>()

  useEffect(() => {
    // If no node, reset and don't listen
    if (!node) {
      setState(undefined)
      return
    }

    // Handle changes
    const onChange = () => {
      const height = $(node).outerHeight()
      const width = $(node).outerWidth()
      setState({ height, width })
    }

    // Update on bind
    onChange()

    // Add / Remove handlers
    window.addEventListener('resize', onChange, false)
    return () => {
      window.removeEventListener('resize', onChange, false)
    }
  }, [node, ...deps])

  return state
}

// Determine an image's dimensions
export const getImageDimensions = (url: string) =>
  new Promise<Dimensions>((resolve) => {
    // Must be a valid url
    if (!isValidHttpUrl(url)) return

    const node = document.createElement('img')
    node.src = url
    node.onload = () => {
      const { height, width } = node
      resolve({ height, width })
    }
  })

// Hook to determine an image's dimensions
export const useImageDimensions = (url: string) => {
  const [state] = usePromise(() => getImageDimensions(url), [url])
  return state
}

type MouseEventFunction = (ev: MouseEvent) => void

// Hook to handle click
export const useClick = (fn: MouseEventFunction) => {
  const fnRef = useRef(fn)
  fnRef.current = fn
  useEffect(() => {
    function onClick(event: MouseEvent) {
      fnRef.current(event)
    }

    document.addEventListener('click', onClick, false)
    return () => {
      document.removeEventListener('click', onClick, false)
    }
  }, [])
}

// Hook to handle mouseup
export const useMouseUp = (_fn: MouseEventFunction) => {
  const fnRef = useRef(_fn)
  fnRef.current = _fn

  useEffect(() => {
    function onMouseUp(event: MouseEvent) {
      fnRef.current(event)
    }

    document.addEventListener('mouseup', onMouseUp, false)
    return () => {
      document.removeEventListener('mouseup', onMouseUp, false)
    }
  }, [])
}

// Hook for returning the scrollTop for the browser
export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    // Handle changes
    const onChange = () => {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop
      setScrollTop(scrollTop)
    }
    onChange()

    // Add / Remove handlers
    window.addEventListener('scroll', onChange, false)
    return () => {
      window.removeEventListener('scroll', onChange, false)
    }
  }, [])

  return scrollTop
}

// Get the current anchor
const getCurrentAnchor = (): HTMLAnchorElement => {
  // Find the current anchor
  const nodeList = document.querySelectorAll<HTMLAnchorElement>('a[name]')
  return reduce(
    nodeList,
    (bestNode, node) => {
      // Must be < 0 to be better
      const { top } = node.getBoundingClientRect()
      if (top > 0) return bestNode

      // If no existing node, it's the best
      if (!bestNode) return node

      // Pick the better choice
      const bestTop = bestNode.getBoundingClientRect().top
      return top > bestTop ? node : bestNode
    },
    undefined,
  )
}

// Hook to get the current anchor
export const useCurrentAnchor = () => {
  const [currentAnchor, setCurrentAnchor] = useState<HTMLAnchorElement>()

  useEffect(() => {
    // Handle changes
    const onChange = () => {
      const anchor = getCurrentAnchor()
      setCurrentAnchor(anchor)
    }
    onChange()

    // Add / Remove handlers
    window.addEventListener('resize', onChange, false)
    window.addEventListener('scroll', onChange, false)
    return () => {
      window.removeEventListener('resize', onChange, false)
      window.removeEventListener('scroll', onChange, false)
    }
  }, [])

  return currentAnchor
}

// Hook to get the current anchor's name
export const useCurrentAnchorName = () => {
  const anchor = useCurrentAnchor()
  return anchor?.getAttribute('name')
}

const getFileText = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (event) => {
      const data = event.target.result
      resolve(data as string)
    }
    reader.onerror = reject
    reader.readAsText(file)
  })

export const useFileText = (file: File) => {
  const [fileText, setFileText] = useState<string>()

  useEffect(() => {
    setFileText(undefined)
    if (!file) return

    let canceled = false
    getFileText(file)
      .then((text) => {
        if (!canceled) setFileText(text)
      })
      .catch(logUIError)

    return () => {
      canceled = true
    }
  }, [file])

  return fileText
}
