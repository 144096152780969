import type { Editor } from '@blissbook/ui/editor'
import React from 'react'
import { useVirtualRangeElement } from '../../selection/util'
import { ProseMirrorBubbleToolbar } from '../../toolbar'
import { LinkToolbarForm } from './LinkToolbarForm'
import type { LinkEditorState } from './state'

export type LinkToolbarProps = {
  editor: Editor
  state: LinkEditorState
}

export const LinkToolbar: React.FC<LinkToolbarProps> = ({ editor, state }) => {
  const { range } = state
  const virtualEl = useVirtualRangeElement(editor, range)

  function handleCancel() {
    editor.chain().focus().hideLinkEditor().run()
  }

  function handleSubmit(value: string) {
    editor
      .chain()
      .focus()
      .setTextSelection(range)
      .setLink(value)
      .hideLinkEditor()
      .run()
  }

  function handleRemove() {
    editor.chain().focus().unsetLink().hideLinkEditor().run()
  }

  return (
    <ProseMirrorBubbleToolbar editor={editor} refEl={virtualEl} offset={4}>
      <LinkToolbarForm
        bookmarks={editor.bookmarks}
        bookmarkLabel='section title'
        initialValue={state.href}
        onCancel={handleCancel}
        onRemove={handleRemove}
        onSubmit={handleSubmit}
      />
    </ProseMirrorBubbleToolbar>
  )
}
