import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { PickFunction } from '../../../editor'
import { ToolbarButton } from '../../../toolbar'

export const EmbedReplaceIcon = () => (
  <FontAwesomeIcon icon={['fas', 'pen-to-square']} />
)

export type EmbedNodeSelectionReplaceToolProps = {
  label: string
  pickSrc: PickFunction<string>
  onChange: (value: string) => void
  value: string
}

export const EmbedNodeSelectionReplaceTool = ({
  label,
  onChange,
  pickSrc,
  value,
}: EmbedNodeSelectionReplaceToolProps) => (
  <ToolbarButton
    label={`Replace ${label}`}
    onClick={async () => {
      const newValue = await pickSrc(value)
      if (newValue) onChange(newValue)
    }}
  >
    <EmbedReplaceIcon />
  </ToolbarButton>
)
