import { Variant } from './Variant'

export class BasicExpression {
  // Go from expression to object
  static parse(expression: string) {
    if (!expression) return new BasicExpression()

    // Validate the format
    const parts = expression.split('&&')
    if (parts.length > 2) throw new Error('Invalid Expression')

    // Determine includes and excludes
    const includes: Variant[] = []
    const excludes: Variant[] = []
    parts.forEach((exp) => {
      // Determine if its an exclude
      const isExclude = exp[0] === '!'
      if (isExclude) exp = exp.substr(1)

      // Remove parantheses
      if (exp[0] === '(' && exp[exp.length - 1] === ')')
        exp = exp.substr(1, exp.length - 2)

      // Pull variables (not can be empty)
      const variables = exp.split('||')
      const variants = variables.map(Variant.fromString).filter(Boolean)
      if (variants.length !== variables.length)
        throw new Error('Invalid Expression')

      // Put into result (cannot have both excludes or both)
      const result = isExclude ? excludes : includes
      if (result.length) throw new Error('Invalid Expression')
      result.push(...variants)
    })

    return new BasicExpression(includes, excludes)
  }

  // Convert to string
  static toString(includes: Variant[], excludes: Variant[]) {
    // Includes only
    if (!excludes.length) return includes.join('||')

    // Has excludes (may have includes)
    const includeExp = toExpression(includes)
    let excludeExp = toExpression(excludes)
    if (excludeExp) excludeExp = '!' + excludeExp
    return [includeExp, excludeExp].filter(Boolean).join('&&')
  }

  constructor(includes: Variant[] = [], excludes: Variant[] = []) {
    this.includes = includes
    this.excludes = excludes
  }

  // Convert from object to expression
  toString() {
    return BasicExpression.toString(this.includes, this.excludes)
  }

  includes: Variant[]
  excludes: Variant[]
}

// Convert these variants to an expression
const toExpression = (variants: Variant[]) => {
  if (!variants.length) return variants.join('||')
  let expression = variants.join('||')
  if (variants.length > 1) expression = `(${expression})`
  return expression
}
