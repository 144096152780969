export const label = 'Wistia'
export const exampleUrl = 'https://home.wistia.com/medias/29b0fbf547'

const urlRegEx = /^https?:\/\/([^.]+)\.wistia\.com\/medias\/([^&\s]+)$/i

export function fromUrl(url: string) {
  const match = url.match(urlRegEx)
  if (match)
    return {
      url: `https://${match[1]}.wistia.com/medias/${match[2]}`,
    }
}
