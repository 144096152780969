import { Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { DocumentStatus } from './DocumentStatus'

export type DocumentStatusViewProps = {
  className?: string
  value: DocumentStatus
}

export function DocumentStatusView({
  className,
  value,
}: DocumentStatusViewProps) {
  return (
    <Tooltip content={value.label} placement='right'>
      <FontAwesomeIcon
        className={cx(value.className, className)}
        fixedWidth
        icon={value.icon}
      />
    </Tooltip>
  )
}
