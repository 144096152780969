import type { NodeWithPos } from '@tiptap/core'
import type { Node as ProseMirrorNode } from 'prosemirror-model'

export const containerNodeTypes = ['banner', 'readMore']

export function getContainerNodes(doc: ProseMirrorNode) {
  const nodes: NodeWithPos[] = []

  doc.descendants((node, pos) => {
    if (containerNodeTypes.includes(node.type.name)) {
      nodes.push({ node, pos })
    }
  })

  return nodes
}
