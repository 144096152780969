import { useMouseUp } from '@blissbook/ui/util/hooks'
import classnames from 'classnames'
import React from 'react'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from './Button'

type HoldButtonState = {
  heldSeconds: number
  isHolding: boolean
  startTimer: any
  stopTimer: any
  totalSeconds: number
}

export const useHoldState = (
  totalSeconds: number,
  onComplete: () => any,
): HoldButtonState => {
  const [heldSeconds, setHeldSeconds] = useState(0)
  const [isHolding, setHolding] = useState(false)

  // Current timer
  const timerIdRef = useRef<NodeJS.Timeout>()

  // Callback to stop the timer
  const stopTimer = useCallback(() => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current)
      timerIdRef.current = undefined
      setHolding(false)
      setHeldSeconds(0)
    }
  }, [])

  // Callback to start the timer
  const startTimer = useCallback(() => {
    // Initialize seconds held
    let heldSeconds = 0
    setHolding(true)
    setHeldSeconds(heldSeconds)

    timerIdRef.current = setInterval(() => {
      // Increase seconds held
      heldSeconds += 1
      setHeldSeconds(heldSeconds)

      // Done?
      if (heldSeconds >= totalSeconds) {
        clearInterval(timerIdRef.current)
        timerIdRef.current = undefined
        setHolding(false)
        onComplete()
      }
    }, 1000)
  }, [])

  return {
    heldSeconds,
    isHolding,
    startTimer,
    stopTimer,
    totalSeconds,
  }
}

type HoldButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  holdState?: HoldButtonState
}

export const HoldButton = forwardRef<HTMLButtonElement, HoldButtonProps>(
  (
    {
      children,
      className,
      holdState,
      holdState: {
        heldSeconds,
        isHolding,
        startTimer,
        stopTimer,
        totalSeconds,
      },
      ...props
    },
    ref,
  ) => {
    // Clear the timer on mouseup
    useMouseUp(stopTimer)

    // Last State
    const [lastHolding, setLastHolding] = useState(isHolding)
    useEffect(() => {
      setLastHolding(isHolding)
    }, [isHolding])

    // Current State
    const holdCount = heldSeconds + (isHolding ? 1 : 0)
    const holdPercentage = Math.min(holdCount / totalSeconds, 1) * 100
    return (
      <Button
        {...props}
        className={classnames(className, 'btn-hold')}
        onMouseDown={() => startTimer()}
        ref={ref}
      >
        <span
          className='btn-hold-progress'
          css={{
            transition: 'width linear 1s',
          }}
          key={isHolding.toString()}
          style={{
            width: isHolding && !lastHolding ? 0 : holdPercentage + '%',
          }}
        />
        {children}
      </Button>
    )
  },
)
