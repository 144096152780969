import type { Node } from '../types'
import { renderText } from './render'
import { type RenderVariableInput, parseVariable } from './variable'

export function parseText(text: string) {
  const content: Node[] = []

  const regEx = /{{([^}]+)}}/g
  let match = regEx.exec(text)
  let lastIndex = 0
  while (match !== null) {
    const _text = text.substring(lastIndex, match.index)
    if (_text.length > 0) {
      content.push({
        type: 'text',
        text: _text,
      })
    }

    const _variable = parseVariable(match[1])
    content.push({
      type: 'variable',
      attrs: _variable,
    })

    lastIndex = match.index + match[0].length
    match = regEx.exec(text)
  }

  const _text = text.slice(lastIndex)
  if (_text.length > 0) {
    content.push({
      type: 'text',
      text: _text,
    })
  }

  return content
}

export function replaceText(
  text: string,
  variables: RenderVariableInput<Date | string>,
) {
  const content = parseText(text)
  return renderText(content, variables)
}
