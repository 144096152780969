import type { BlissbookSection } from '@blissbook/lib/blissbook'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import clone from 'lodash/clone'
import times from 'lodash/times'
import { nanoid } from 'nanoid'
import { idNanoid } from '../id'
import type { BlockEmbedNodeAttributes, EmbedNodeAttributes } from './embed'
import type { HorizontalRuleAttributes } from './horizontalRule'
import type { Mark } from './mark'
import type { TableCellNodeAttributes, TableNodeAttributes } from './table'
import type { TextAlign } from './textAlign'

// Specific types

export type BannerBackground = {
  color: string
  imageUrl?: string
  opacity: number
}

export type BannerAnimationType = 'fixed' | 'scroll'
export type BannerAnimation = {
  type: BannerAnimationType | undefined
  label: string
}

export const bannerAnimations: BannerAnimation[] = [
  {
    type: undefined,
    label: 'None',
  },
  {
    type: 'fixed',
    label: 'Fixed',
  },
  {
    type: 'scroll',
    label: 'Slow Scroll',
  },
]

export type BannerAttributes = {
  id?: string
  animation?: BannerAnimationType
  backgrounds: BannerBackground[]
  margin: number
  padding: number
}

export const defaultBannerAttributes: Omit<BannerAttributes, 'id'> = {
  animation: undefined,
  backgrounds: [
    {
      color: 'primary',
      opacity: 100,
    },
  ],
  margin: 0,
  padding: 8,
}

export type BannerNode = {
  type: 'banner'
  attrs: BannerAttributes
  content: Node[]
}

export const createBannerNode = (text: string): BannerNode => ({
  type: 'banner',
  attrs: {
    ...defaultBannerAttributes,
    id: idNanoid(),
  },
  content: [
    {
      type: 'heading',
      attrs: {
        level: 1,
        id: idNanoid(),
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text,
        },
      ],
    },
  ],
})

export type BulletListNode = {
  type: 'bulletList'
  content: ListItemNode[]
}

export type DocNode = {
  type: 'doc'
  content: Node[]
}

export type DocumentVersion = {
  annotationIds: string[]
  audienceExpression?: AudienceRootExpression
  documentId: number
  html: string | null
  languageCode?: string
  text: string
  title: string
  versionNumber: number
}

export type DocumentRefNodeAttributes = {
  documentId: number
  hideToc?: boolean // Hide this document from the table of contents
  id: string // Unique ID for this reference
  tocTitle?: string // Custom title for the table of contents
}

export type DocumentRefNode = {
  type: 'documentRef'
  attrs: DocumentRefNodeAttributes
  document?: DocumentVersion
}

export function createDocumentRefNode(documentId: number): DocumentRefNode {
  return {
    type: 'documentRef',
    attrs: {
      documentId,
      id: nanoid(),
    },
  }
}

export type HandbookSectionNodeAttributes = {
  sectionId: number
  sectionVersion: number
}

export type HandbookSectionNode = {
  type: 'handbookSection'
  attrs: HandbookSectionNodeAttributes
}

export function createHandbookSectionNode(
  section: BlissbookSection,
): HandbookSectionNode {
  return {
    type: 'handbookSection',
    attrs: {
      sectionId: section.id,
      sectionVersion: section.version,
    },
  }
}

export type HardBreakNode = {
  type: 'hardBreak'
}

export type HeadingNodeAttributes = {
  audienceExpression?: AudienceRootExpression
  expression?: string
  id?: string
  indent?: number
  level: number
  textAlign?: TextAlign
}

export type HeadingNode = {
  type: 'heading'
  attrs: HeadingNodeAttributes
  content: Node[]
}

export type HorizontalRuleNode = {
  type: 'horizontalRule'
  attrs?: HorizontalRuleAttributes
}

export type ImageNodeAttributes = EmbedNodeAttributes

export type ImageNode = {
  type: 'image'
  attrs: ImageNodeAttributes
  marks?: Mark[]
}

export type ListItemAttributes = {
  audienceExpression?: AudienceRootExpression
  expression?: string
  id?: string
  indent?: number
}

export type ListItemNode = {
  type: 'listItem'
  attrs?: ListItemAttributes
  content: Node[]
}

export type OrderedListNode = {
  type: 'orderedList'
  content: ListItemNode[]
}

export type ParagraphNodeAttributes = {
  audienceExpression?: AudienceRootExpression
  expression?: string
  id?: string
  indent?: number
  textAlign?: TextAlign
}

export type ParagraphNode = {
  type: 'paragraph'
  attrs?: ParagraphNodeAttributes
  content: Node[]
}

export const createParagraphNode = (text?: string) => {
  const node: ParagraphNode = {
    type: 'paragraph',
    content: [],
  }

  if (text)
    node.content.push({
      type: 'text',
      text,
    })

  return node
}

export const createEmptyParagraphNodes = (count: number) =>
  times(count, () => createParagraphNode())

export type PdfNodeAttributes = BlockEmbedNodeAttributes & {
  showThumbnail?: boolean
  thumbnailUrl?: string
}

export type PdfNode = {
  type: 'pdf'
  attrs: PdfNodeAttributes
}

export type ReadMoreNodeAttributes = {
  audienceExpression?: AudienceRootExpression
  id?: string
  expression?: string
  templateId?: string
}

export type ReadMoreNode = {
  type: 'readMore'
  attrs?: ReadMoreNodeAttributes
  content: Node[]
}

export type TableNode = {
  type: 'table'
  attrs?: TableNodeAttributes
  content: TableRowNode[]
}

export type TableCellNode = {
  type: 'tableCell'
  attrs?: TableCellNodeAttributes
  content: Node[]
}

export type TableRowNode = {
  type: 'tableRow'
  content: TableCellNode[]
}

export type TextNode = {
  type: 'text'
  text: string
  marks?: Mark[]
}

export type VariableAttributes = {
  name: string
  format?: string
}

export type VariableNode = {
  type: 'variable'
  attrs: VariableAttributes
  marks?: Mark[]
}

export type VideoNodeAttributes = BlockEmbedNodeAttributes

export type VideoNode = {
  type: 'video'
  attrs: VideoNodeAttributes
}

export type ListNode = BulletListNode | OrderedListNode

export type InlineNode = TextNode

export type TextblockNode = HeadingNode | ParagraphNode

export type Node =
  | BannerNode
  | BulletListNode
  | DocNode
  | DocumentRefNode
  | HandbookSectionNode
  | HardBreakNode
  | HorizontalRuleNode
  | ImageNode
  | InlineNode
  | ListItemNode
  | OrderedListNode
  | PdfNode
  | ReadMoreNode
  | TableNode
  | TableCellNode
  | TableRowNode
  | TextblockNode
  | VariableNode
  | VideoNode

export function isNodeId(node: Node, id: string) {
  if ('attrs' in node && 'id' in node.attrs) {
    return node.attrs.id === id
  }
  return false
}

export function reduceAttributes<T extends object>(attrs: T) {
  let reduced: T | undefined

  for (const [key, value] of Object.entries(attrs)) {
    if (value !== undefined) {
      reduced = reduced || ({} as T)
      reduced[key as keyof T] = value
    }
  }

  return reduced
}

export function reduceNode(node: Node) {
  const reduced = clone(node)

  if ('attrs' in reduced) {
    reduced.attrs = reduceAttributes(reduced.attrs)
    // biome-ignore lint/performance/noDelete: app relies on this behavior instead of being undefined
    if (!reduced.attrs) delete reduced.attrs
  }

  if ('marks' in reduced && reduced.marks) {
    for (const mark of reduced.marks) {
      if ('attrs' in mark && mark.attrs) {
        // @ts-ignore: TODO: Fix
        mark.attrs = reduceAttributes(mark.attrs)
        // biome-ignore lint/performance/noDelete: app relies on this behavior instead of being undefined
        if (!mark.attrs) delete mark.attrs
      }
    }
  }

  if ('content' in reduced) {
    // @ts-ignore: TODO: Fix
    reduced.content = reduced.content.map(reduceNode)
  }

  return reduced
}

export function contentDescendants(
  content: Node[],
  // biome-ignore lint/suspicious/noConfusingVoidType: requires some work
  fn: (node: Node) => boolean | void,
) {
  for (const node of content) {
    nodeDescendants(node, fn)
  }
}

// See https://prosemirror.net/docs/ref/#model.Node.descendants
export function nodeDescendants(
  node: Node,
  // biome-ignore lint/suspicious/noConfusingVoidType: requires some work
  fn: (node: Node) => boolean | void,
) {
  const result = fn(node)
  if (result === false) return

  if ('content' in node) {
    for (const childNode of node.content) {
      nodeDescendants(childNode, fn)
    }
  }
}

/** Get the documentIds from documentRefs nodes in this content */
export function getDocumentIdsFromContent(
  content: Node[],
  documentIds = new Set<number>(),
): Set<number> {
  for (const node of content) {
    if (node.type === 'documentRef') {
      documentIds.add(node.attrs.documentId)
    }
  }

  return documentIds
}
