import type { Editor } from '@blissbook/ui/editor'
import type { CellSelection } from 'prosemirror-tables'
import React from 'react'
import { CellSelectionToolbar } from './toolbar'

export type CellSelectionViewProps = {
  editor: Editor
  selection: CellSelection
}

export const CellSelectionView = (props: CellSelectionViewProps) => (
  <CellSelectionToolbar {...props} />
)
