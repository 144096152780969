import { bulletList } from '@blissbook/lib/document'
import { Node, wrappingInputRule } from '@tiptap/core'

export const inputRegex = /^\s*([-+*])\s$/

export const BulletListNode = Node.create({
  name: 'bulletList',

  group: 'block list',

  content: 'listItem+',

  parseHTML() {
    return [
      {
        tag: 'ul',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['ul', HTMLAttributes, 0]
  },

  addKeyboardShortcuts() {
    return {
      [bulletList.keyboardShortcut]: () =>
        this.editor.commands.toggleList(bulletList.name, 'listItem'),
    }
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ]
  },
})
