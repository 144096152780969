import { isTextSelection, keyboardShortcuts } from '@blissbook/lib/document'
import { LinkMark } from '@blissbook/lib/document/schema/marks'
import { createPlugin, pluginKey } from './plugin'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    linkEditor: {
      hideLinkEditor: () => ReturnType
      showLinkEditor: () => ReturnType
    }
  }
}

export const LinkEditorMark = LinkMark.extend({
  addProseMirrorPlugins() {
    const plugin = createPlugin()
    return [plugin]
  },

  addCommands() {
    return {
      ...this.parent(),

      hideLinkEditor:
        () =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setMeta(pluginKey, { show: false })
          }

          return true
        },

      showLinkEditor:
        () =>
        ({ dispatch, tr }) => {
          const { selection } = tr
          if (!isTextSelection(selection) || selection.empty) {
            return false
          }

          if (dispatch) {
            tr.setMeta(pluginKey, { show: true })
          }

          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      [keyboardShortcuts.link]: () => {
        return this.editor.commands.showLinkEditor()
      },
    }
  },
})
