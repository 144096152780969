import React, { forwardRef, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

export const MOUNT_TRANSITION_CLASSNAMES = {
  appear: 'enter',
  appearActive: 'enter-active',
  enter: 'enter',
  enterActive: 'enter-active',
  exit: 'exit',
  exitActive: 'exit-active',
}

export const MountCSSTransition = forwardRef(
  ({ children, timeout = 250, ...props }, ref) => {
    // Accept any truthy/falsey values
    const isIn = !!props.in

    // Hold onto children until we're closed
    const [lastChildren, setLastChildren] = useState(children)
    useEffect(() => {
      if (isIn) setLastChildren(children)
    }, [children])

    return (
      <CSSTransition
        {...props}
        children={isIn ? children : lastChildren}
        classNames={MOUNT_TRANSITION_CLASSNAMES}
        in={isIn}
        ref={ref}
        timeout={timeout}
        unmountOnExit
      />
    )
  },
)

export const FadeOutInTransition = ({
  children,
  fadeInDelay = 0,
  fadeInDuration = 500,
  fadeOutDelay = 0,
  fadeOutDuration = 500,
  in: isIn,
  timingFunction = 'linear',
}) => {
  const delay = isIn ? fadeInDelay : fadeOutDelay
  const duration = isIn ? fadeInDuration : fadeOutDuration
  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      style: {
        ...child.style,
        opacity: isIn ? 1 : 0,
        pointerEvents: isIn ? undefined : 'none',
        transition: `opacity ${duration}ms ${timingFunction} ${delay}ms`,
      },
    }),
  )
}
