import { listNodeTypes } from '@blissbook/lib/document/types'
import { Node } from '@tiptap/core'

export const ListItemNode = Node.create({
  name: 'listItem',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  content: 'paragraph+',

  defining: true,

  parseHTML() {
    return [
      {
        tag: 'li',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['li', HTMLAttributes, 0]
  },

  addKeyboardShortcuts() {
    const handleBackspace = () => {
      return this.editor.commands.command(({ chain, commands, tr }) => {
        const { empty, $from } = tr.selection
        const { parent, pos } = $from
        const beforePos = pos - 2
        if (beforePos < 0) return false

        const $before = tr.doc.resolve(beforePos)
        const before = $before.node()
        const parentPos = $from.start()
        const grandParent = $from.node(-1)
        const grandParentPos = $from.start(-1)

        // If li > p:not(:first-child)
        if (
          empty &&
          parent.type.isTextblock &&
          grandParent.type === this.type &&
          pos === parentPos &&
          pos > grandParentPos + 1
        ) {
          return commands.lift(this.type)
        }
        // If text block following a list
        if (
          empty &&
          parent.type.isTextblock &&
          grandParent.type !== this.type &&
          pos === parentPos &&
          before &&
          listNodeTypes.includes(before.type.name)
        ) {
          return chain().joinBackward().joinBackward().joinBackward().run()
        }

        return false
      })
    }

    const handleEnter = () => {
      return this.editor.commands.command(({ commands, tr }) => {
        const { empty, $anchor, $from } = tr.selection
        const { parent, pos } = $anchor
        const parentPos = $from.start()
        const grandParent = $from.node(-1)
        const grandParentPos = $from.start(-1)

        // If li > p:not(:first-child)
        if (
          empty &&
          parent.type.isTextblock &&
          grandParent.type === this.type &&
          pos === parentPos &&
          pos > grandParentPos + 1
        ) {
          return commands.splitBlock()
        }

        return commands.splitListItem(this.name)
      })
    }

    return {
      Backspace: handleBackspace,
      'Mod-Backspace': handleBackspace,
      'Shift-Backspace': handleBackspace,

      Enter: handleEnter,
      'Shift-Enter': () => this.editor.commands.splitBlock(),
    }
  },
})
