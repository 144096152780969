export function removeComments(document: Document) {
  // Remove MSO comment anchors
  const msoCommentAnchors =
    document.querySelectorAll<HTMLElement>('a.msocomanchor')
  for (const node of msoCommentAnchors) {
    // Try to find the next text node
    let textNode = node.nextSibling
    while (textNode && textNode.nodeType !== Node.TEXT_NODE) {
      textNode = textNode.nextSibling
    }

    // If found, trim the space character
    if (textNode instanceof Text) {
      textNode.data = textNode.data.trimStart()
    }

    // Remove the anchor
    node.remove()
  }

  // Remove MSO comments
  const msoComments = document.querySelectorAll<HTMLElement>(
    'div.msocomtxt, hr.msocomoff',
  )
  for (const node of msoComments) {
    const { parentNode } = node
    if (parentNode instanceof HTMLElement) parentNode.remove()
  }
}
