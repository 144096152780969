import React from 'react'

export default ({ color, size, ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox='0 0 70 92'
    role='img'
    aria-label='balloon'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-705.000000, -1896.000000)'>
        <g transform='translate(0.000000, 1386.000000)'>
          <g transform='translate(156.000000, 509.000000)'>
            <g transform='translate(549.000000, 0.000000)'>
              <g transform='translate(27.000000, 76.386200)'>
                <path
                  d='M4.4673,4.3982 L4.4673,10.4062 C4.4673,11.3032 5.1963,12.0322 6.0933,12.0322 L10.4753,12.0322 C11.3723,12.0322 12.1023,11.3032 12.1023,10.4062 L12.1023,4.3982 L4.4673,4.3982 Z M10.4753,16.0002 L6.0933,16.0002 C3.0093,16.0002 0.5003,13.4912 0.5003,10.4062 L0.5003,0.4302 L16.0693,0.4302 L16.0693,10.4062 C16.0693,13.4912 13.5603,16.0002 10.4753,16.0002 L10.4753,16.0002 Z'
                  fill={color}
                />
              </g>
              <g transform='translate(57.000000, 11.386200)'>
                <path
                  d='M3.0371,48.0134 L0.2171,45.1764 C5.8811,39.5454 9.0001,32.0584 9.0001,24.0954 C9.0001,16.1324 5.8811,8.6444 0.2171,3.0134 L3.0371,0.1764 C9.4621,6.5644 13.0001,15.0594 13.0001,24.0954 C13.0001,33.1314 9.4621,41.6254 3.0371,48.0134'
                  fill={color}
                />
              </g>
              <path
                d='M9.9624,59.3991 C3.5384,53.0111 0.0004,44.5171 0.0004,35.4811 C0.0004,26.4451 3.5384,17.9511 9.9624,11.5631 L12.7824,14.3991 C7.1194,20.0311 4.0004,27.5181 4.0004,35.4811 C4.0004,43.4441 7.1194,50.9311 12.7824,56.5631 L9.9624,59.3991 Z'
                fill={color}
              />
              <g id='Group-11' transform='translate(9.000000, 0.386200)'>
                <path
                  d='M5.834,12.0715 L26,33.6645 L46.166,12.0715 C34.671,2.1175 17.328,2.1175 5.834,12.0715 L5.834,12.0715 Z M26,39.5255 L0.209,11.9085 L1.597,10.5415 C15.052,-2.6955 36.946,-2.6955 50.402,10.5415 L51.792,11.9085 L26,39.5255 Z'
                  id='Fill-9'
                  fill={color}
                />
              </g>
              <path
                d='M29.1597,72.7961 L41.2217,72.7961 L55.2817,58.5381 L36.7957,40.2101 L33.5757,40.2291 L14.8737,58.6511 L29.1597,72.7961 Z M42.8937,76.7961 L27.5137,76.7961 L9.1807,58.6431 L31.9277,36.2371 L38.4337,36.2011 L60.9307,58.5071 L42.8937,76.7961 Z'
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
