import {
  boundIndent,
  getIndentNodes,
  indentNodeTypes,
} from '@blissbook/lib/document/types'
import { type Editor, Extension } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    indent: {
      adjustIndent: (delta: number) => ReturnType
    }
  }
}

function canAdjustIndent(editor: Editor) {
  const { selection } = editor.state
  if (!selection.empty) return false

  const isStart = editor.view.endOfTextblock('backward', editor.state)
  if (!isStart) return false

  return true
}

export const IndentExtension = Extension.create({
  name: 'indent',

  addGlobalAttributes() {
    return [
      {
        types: indentNodeTypes,
        attributes: {
          indent: {
            default: undefined,
            parseHTML: (element) => {
              const match = element.className.match(/bb-indent-(\d+)/i)
              if (!match) return

              const indent = Number.parseInt(match[1])
              return boundIndent(indent)
            },
            renderHTML: ({ indent }) => {
              if (!indent) return {}
              return { class: `bb-indent-${indent}` }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      adjustIndent:
        (delta) =>
        ({ commands, tr }) => {
          const { doc, selection } = tr
          const nodes = getIndentNodes(doc, selection)
          if (!nodes.length) return false

          for (const node of nodes) {
            const prevIndent = node.node.attrs.indent || 0
            const indent = boundIndent(prevIndent + delta)
            if (indent !== prevIndent) {
              commands.updateNodeAttributes(node.pos, { indent })
            }
          }

          return true
        },
    }
  },

  addKeyboardShortcuts() {
    const { editor } = this
    return {
      Tab: () => {
        if (!canAdjustIndent(editor)) return editor.commands.insertText('\t')
        return editor.commands.adjustIndent(1)
      },
      'Shift-Tab': () => {
        if (!canAdjustIndent(editor)) return false

        return editor.commands.adjustIndent(-1)
      },
    }
  },
})
