import { type Group, orderGroups } from '@blissbook/common/group'
import React from 'react'
import { useMemo } from 'react'
import { NameBadges } from './NameBadges'

type GroupBadgesProps = {
  className?: string
  groups: Group[]
  maxGroups?: number
}

export const GroupBadges: React.FC<GroupBadgesProps> = ({
  groups,
  maxGroups = 2,
  ...props
}) => {
  const groupNames = useMemo(
    () => orderGroups(groups).map((group) => group.name),
    [groups],
  )

  return <NameBadges {...props} maxCount={maxGroups} values={groupNames} />
}
