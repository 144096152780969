import React from 'react'
import {
  NodeSelectionToolbar,
  type NodeSelectionToolbarProps,
} from '../../selection/node'
import { ToolbarDivider } from '../../toolbar'
import {
  EmbedNodeSelectionAlignTool,
  EmbedNodeSelectionRemoveTool,
  EmbedNodeSelectionReplaceTool,
  EmbedNodeSelectionShapeTool,
} from '../embed'

export const ImageNodeSelectionToolbar: React.FC<NodeSelectionToolbarProps> = (
  props,
) => {
  const { editor, removeNode, selection, updateNodeAttributes } = props
  const { node } = selection
  return (
    <NodeSelectionToolbar {...props}>
      <EmbedNodeSelectionAlignTool {...props} />

      <ToolbarDivider />

      <EmbedNodeSelectionShapeTool {...props} />

      <ToolbarDivider />

      <EmbedNodeSelectionReplaceTool
        label='Image'
        onChange={(src) => updateNodeAttributes({ src })}
        pickSrc={editor.pickImage}
        value={node.attrs.src}
      />

      <EmbedNodeSelectionRemoveTool label='Image' onRemove={removeNode} />
    </NodeSelectionToolbar>
  )
}
