import {
  type TableCellNodeAttributes,
  getContainerNodeFromPos,
} from '@blissbook/lib/document'
import {
  getTableClassName,
  getTableCss,
  getTableNodeAttributes,
} from '@blissbook/lib/document'
import { TableCellNode, TableNode } from '@blissbook/lib/document/schema/nodes'
import { css } from '@emotion/css'
import { TableMap } from 'prosemirror-tables'
import type { Editor } from '../../editor'
import { wrapSelectableNodeView } from '../selection'
import { getNodeViewPos } from '../util'
import { bindTableCellResize } from './resize'

export const TableEditorNode = TableNode.extend({
  addNodeView() {
    return (props) => {
      const { node } = props
      const attrs = getTableNodeAttributes(node.attrs)
      const { borderColor, expression } = attrs
      const tableCss = getTableCss(attrs)
      const tableClassName = getTableClassName(attrs)

      const dom = document.createElement('table')
      dom.className = `${tableClassName} ${css(tableCss)}`
      if (borderColor) dom.style.borderColor = borderColor
      if (expression) dom.dataset.expression = expression

      const tbodyEl = document.createElement('tbody')
      dom.appendChild(tbodyEl)

      return {
        ...wrapSelectableNodeView(dom, props),
        contentDOM: tbodyEl,

        ignoreMutation(mutation) {
          return mutation.type === 'attributes'
        },
      }
    }
  },
})

export const TableCellEditorNode = TableCellNode.extend({
  addNodeView() {
    return ({ editor, getPos, node }) => {
      const { backgroundColor, colspan, height, rowspan, valign, width } =
        node.attrs as TableCellNodeAttributes

      const cellEl = document.createElement('td')
      if (backgroundColor) cellEl.style.backgroundColor = backgroundColor
      if (colspan > 1) cellEl.colSpan = colspan
      if (height) cellEl.style.height = height + 'px'
      if (rowspan > 1) cellEl.rowSpan = rowspan
      if (valign) cellEl.style.verticalAlign = valign
      if (width) cellEl.style.width = width + 'px'

      const containerEl = document.createElement('div')
      containerEl.className = 'tw-relative'
      cellEl.appendChild(containerEl)

      const editorEl = document.createElement('div')
      editorEl.className = 'td-editor'
      editorEl.contentEditable = 'false'
      containerEl.appendChild(editorEl)

      const contentEl = document.createElement('div')
      contentEl.className = 'td-content'
      containerEl.appendChild(contentEl)

      function getState() {
        const { doc } = editor.state
        const pos = getNodeViewPos(getPos)
        const $pos = doc.resolve(pos)
        const table = getContainerNodeFromPos($pos, 'table')
        const tableMap = TableMap.get(table.node)
        return { pos, table, tableMap }
      }

      function positionEditorElement() {
        const cellRect = cellEl.getBoundingClientRect()
        const containerRect = containerEl.getBoundingClientRect()
        const top = cellRect.top - containerRect.top
        const bottom = containerRect.height - cellRect.height - top
        const left = cellRect.left - containerRect.left
        const right = containerRect.right - cellRect.right
        editorEl.style.bottom = bottom + 'px'
        editorEl.style.left = left + 'px'
        editorEl.style.right = right + 'px'
        editorEl.style.top = top + 'px'
      }

      bindTableCellResize(editorEl, {
        onResize(resizeEl) {
          ;(editor as Editor).onResize(resizeEl)
        },

        onSubmit(_size, edge) {
          const { table, tableMap } = getState()

          const chain = editor.chain()
          for (const cellPos of tableMap.map) {
            const pos = table.pos + cellPos + 1
            const nextNode = editor.state.doc.nodeAt(pos)
            const nextEl = editor.view.nodeDOM(pos) as HTMLElement
            const attrs = nextNode.attrs as TableCellNodeAttributes
            const height = nextEl.offsetHeight
            const width = nextEl.offsetWidth

            if (edge.xFactor && width !== attrs.width) {
              chain.updateNodeAttributes(pos, { width })
            } else if (edge.yFactor && height !== attrs.height) {
              chain.updateNodeAttributes(pos, { height })
            }
          }
          chain.run()
        },

        setSize(size, _initialSize, edge) {
          const { pos, table, tableMap } = getState()
          const cell = tableMap.findCell(pos - table.pos - 1)

          if (edge.xFactor) {
            cellEl.style.width = size.width + 'px'

            const dWidth = size.width - node.attrs.width
            const col = cell.left
            for (let row = 0; row < tableMap.height; row++) {
              const nextPos =
                tableMap.positionAt(row, col, table.node) + table.pos + 1
              if (nextPos === pos) continue

              const nextNode = editor.state.doc.nodeAt(nextPos)
              if (!nextNode.attrs.width) continue

              const nextEl = editor.view.nodeDOM(nextPos) as HTMLElement
              nextEl.style.width = nextNode.attrs.width + dWidth + 'px'
            }
          } else if (edge.yFactor) {
            cellEl.style.height = size.height + 'px'

            const dHeight = size.height - node.attrs.height
            const row = cell.top
            for (let col = 0; col < tableMap.width; col++) {
              const nextPos =
                tableMap.positionAt(row, col, table.node) + table.pos + 1
              if (nextPos === pos) continue

              const nextNode = editor.state.doc.nodeAt(nextPos)
              if (!nextNode?.attrs?.height) continue

              const nextEl = editor.view.nodeDOM(nextPos) as HTMLElement
              nextEl.style.height = nextNode.attrs.height + dHeight + 'px'
            }
          }

          return {
            height: cellEl.offsetHeight,
            width: cellEl.offsetWidth,
          }
        },
      })

      const observer = new ResizeObserver(() => {
        positionEditorElement()
      })

      observer.observe(cellEl)
      observer.observe(contentEl)

      return {
        dom: cellEl,
        contentDOM: contentEl,

        destroy() {
          observer.disconnect()
        },

        ignoreMutation(mutation) {
          return mutation.type === 'attributes'
        },
      }
    }
  },
})
