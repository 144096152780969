import type { NodeWithPos } from '@tiptap/core'
import last from 'lodash/last'
import { CellSelection, type TableMap } from 'prosemirror-tables'
import type { Editor } from '../../editor'

const borderWidth = 1

export function getTableSelection({
  editor,
  table,
  tableMap,
}: {
  editor: Editor
  table: NodeWithPos
  tableMap: TableMap
}) {
  const { doc } = editor.state
  const { pos } = table
  const anchorCell = tableMap.map[0] + pos + 1
  const headCell = last(tableMap.map) + pos + 1
  return CellSelection.create(doc, anchorCell, headCell)
}

export function getColumnRects({
  editor,
  table,
  tableMap,
}: {
  editor: Editor
  table: NodeWithPos
  tableMap: TableMap
}) {
  const rects = []

  const tableEl = editor.view.nodeDOM(table.pos) as HTMLElement
  const firstCellEl = tableEl.querySelector<HTMLTableCellElement>('td')
  const firstCellRect = firstCellEl.getBoundingClientRect()
  for (let col = 0; col < tableMap.width; col++) {
    for (let row = 0; row < tableMap.height; row++) {
      const cellPos = tableMap.positionAt(row, col, table.node) + table.pos + 1
      const cellEl = editor.view.nodeDOM(cellPos) as HTMLElement
      if (!cellEl) continue

      const cellNode = editor.state.doc.nodeAt(cellPos)
      if (cellNode.attrs.colspan === 1) {
        const cellRect = cellEl.getBoundingClientRect()
        const left = cellRect.left - firstCellRect.left
        const width = cellRect.width + borderWidth
        rects.push({ left, width })
        break
      }
    }
  }

  return rects
}

export function getColumnSelection({
  col,
  editor,
  table,
  tableMap,
}: {
  col: number
  editor: Editor
  table: NodeWithPos
  tableMap: TableMap
}) {
  const { doc } = editor.state
  const anchorCell = tableMap.positionAt(0, col, table.node) + table.pos + 1
  const $anchorCell = doc.resolve(anchorCell)
  return CellSelection.colSelection($anchorCell)
}

export function getRowRects({
  editor,
  table,
  tableMap,
}: {
  editor: Editor
  table: NodeWithPos
  tableMap: TableMap
}) {
  const rects = []

  const tableEl = editor.view.nodeDOM(table.pos) as HTMLElement
  const firstCellEl = tableEl.querySelector<HTMLTableCellElement>('td')
  const firstCellRect = firstCellEl.getBoundingClientRect()
  for (let row = 0; row < tableMap.height; row++) {
    for (let col = 0; col < tableMap.width; col++) {
      const cellPos = tableMap.positionAt(row, col, table.node) + table.pos + 1
      const cellEl = editor.view.nodeDOM(cellPos) as HTMLElement
      if (!cellEl) continue

      const cellNode = editor.state.doc.nodeAt(cellPos)
      if (cellNode.attrs.rowspan === 1) {
        const cellRect = cellEl.getBoundingClientRect()
        const top = cellRect.top - firstCellRect.top
        const height = cellRect.height + borderWidth
        rects.push({ top, height })
        break
      }
    }
  }

  return rects
}

export function getRowSelection({
  editor,
  row,
  table,
  tableMap,
}: {
  editor: Editor
  row: number
  table: NodeWithPos
  tableMap: TableMap
}) {
  const { doc } = editor.state
  const anchorCell = tableMap.positionAt(row, 0, table.node) + table.pos + 1
  const $anchorCell = doc.resolve(anchorCell)
  return CellSelection.rowSelection($anchorCell)
}

export function isSelectionActive(
  selection: CellSelection,
  cellsSelection: CellSelection,
) {
  return cellsSelection.ranges.every((range) => {
    if (!selection) return false
    return selection.ranges.some((r) => range.$from.pos === r.$from.pos)
  })
}
