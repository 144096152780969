import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

const transitionStyles = {
  exited: { height: 0 },
}

export default ({
  animationDuration = 250,
  animationTimingFunction = 'linear',
  isOpen,
  ...props
}) => {
  const [style, setStyle] = useState()
  return (
    <Transition
      in={isOpen}
      onEnter={() => {
        setStyle({
          height: 0,
          opacity: 0,
        })
      }}
      onEntering={(node) => {
        setStyle({
          height: node.scrollHeight,
          opacity: 1,
        })
      }}
      onEntered={() => {
        setStyle()
      }}
      onExit={(node) => {
        setStyle({
          height: node.scrollHeight,
          opacity: 1,
        })
      }}
      onExiting={(node) => {
        // getting this variable triggers a reflow
        const _unused = node.offsetHeight
        setStyle({
          height: 0,
          opacity: 0,
        })
      }}
      onExited={() => {
        setStyle()
      }}
      timeout={animationDuration}
    >
      {(state) => (
        <div
          {...props}
          css={{
            overflow: 'hidden',
            transition: `all ${animationDuration}ms ${animationTimingFunction}`,
          }}
          style={{
            ...transitionStyles[state],
            ...style,
          }}
        />
      )}
    </Transition>
  )
}
