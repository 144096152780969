import {
  unwrapContainer,
  updateNodeAttributes,
} from '@blissbook/lib/document/commands'
import { Extension, type Range } from '@tiptap/core'
import type { Attrs } from 'prosemirror-model'
import type { Selection } from 'prosemirror-state'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blissbook: {
      setSelection: (selection: Selection) => ReturnType
      unwrapContainer: (pos: number) => ReturnType
      updateNodeAttributes: (pos: number, attrs: Attrs) => ReturnType
    }
  }
}

export const BlissbookExtension = Extension.create({
  name: 'blissbook',

  addCommands() {
    return {
      setSelection:
        (selection) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setSelection(selection)
          }

          return true
        },

      unwrapContainer:
        (pos) =>
        ({ commands, dispatch, tr }) => {
          try {
            const container = tr.doc.nodeAt(pos)
            if (!container || container?.type.name !== 'readMore') return false

            let textRange: Range = { from: 0, to: 0 }

            container.descendants((child, childPos) => {
              if (child.isText) {
                const from = childPos + pos + 1
                const to = from + child.nodeSize
                if (!textRange) {
                  textRange = { from, to }
                } else {
                  textRange.to = to
                }
              }
            })

            if (dispatch) {
              commands.setTextSelection(textRange)
              unwrapContainer(tr, container, pos)
            }

            return true
          } catch (e) {
            console.error('Error unwrapping container', e)
            return false
          }
        },

      updateNodeAttributes:
        (pos, attrs) =>
        ({ dispatch, tr }) => {
          const node = tr.doc.nodeAt(pos)
          if (!node) return false

          if (dispatch) {
            updateNodeAttributes(tr, pos, attrs)
          }

          return true
        },
    }
  },
})
