import React from 'react'
import type { IconProps } from './types'

export const MergeTableCellsIcon = (props: IconProps) => (
  <svg
    {...props}
    fill='currentColor'
    viewBox='0 0 18 13'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Merge Table Cells Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.75 1C7.75 1.41421 7.41421 1.75 7 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H7C7.41421 0.25 7.75 0.585786 7.75 1Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 4.75C0.585786 4.75 0.25 4.41421 0.25 4V1C0.25 0.585786 0.585786 0.25 1 0.25C1.41421 0.25 1.75 0.585786 1.75 1V4C1.75 4.41421 1.41421 4.75 1 4.75Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.75 12C7.75 11.5858 7.41421 11.25 7 11.25H1C0.585786 11.25 0.25 11.5858 0.25 12C0.25 12.4142 0.585786 12.75 1 12.75H7C7.41421 12.75 7.75 12.4142 7.75 12Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 8.25C0.585786 8.25 0.25 8.58579 0.25 9V12C0.25 12.4142 0.585786 12.75 1 12.75C1.41421 12.75 1.75 12.4142 1.75 12V9C1.75 8.58579 1.41421 8.25 1 8.25Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.25 1C10.25 1.41421 10.5858 1.75 11 1.75H17C17.4142 1.75 17.75 1.41421 17.75 1C17.75 0.585786 17.4142 0.25 17 0.25H11C10.5858 0.25 10.25 0.585786 10.25 1Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17 4.75C17.4142 4.75 17.75 4.41421 17.75 4V1C17.75 0.585786 17.4142 0.25 17 0.25C16.5858 0.25 16.25 0.585786 16.25 1V4C16.25 4.41421 16.5858 4.75 17 4.75Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H17C17.4142 11.25 17.75 11.5858 17.75 12C17.75 12.4142 17.4142 12.75 17 12.75H11C10.5858 12.75 10.25 12.4142 10.25 12Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17 8.25C17.4142 8.25 17.75 8.58579 17.75 9V12C17.75 12.4142 17.4142 12.75 17 12.75C16.5858 12.75 16.25 12.4142 16.25 12V9C16.25 8.58579 16.5858 8.25 17 8.25Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.33431 3.22774C4.64681 2.92409 5.15324 2.92409 5.46574 3.22774L8.26576 5.94999C8.57808 6.25381 8.57808 6.74618 8.26576 7.05001L5.46574 9.77226C5.15324 10.0759 4.64681 10.0759 4.33431 9.77226C4.02199 9.46844 4.02199 8.97608 4.33431 8.67225L5.7686 7.27779H1.8C1.35821 7.27779 1 6.92952 1 6.5C1 6.07048 1.35821 5.72221 1.8 5.72221H5.7686L4.33431 4.32775C4.02199 4.02393 4.02199 3.53156 4.33431 3.22774Z'
      fill='#333333'
    />
    <path
      d='M13.6657 4.32775C13.978 4.02392 13.978 3.53156 13.6657 3.22774C13.3532 2.92409 12.8468 2.92409 12.5343 3.22774L9.73429 5.94999C9.58429 6.09583 9.5 6.29375 9.5 6.5C9.5 6.70625 9.58429 6.90417 9.73429 7.05001L12.5343 9.77226C12.8468 10.0759 13.3532 10.0759 13.6657 9.77226C13.978 9.46844 13.978 8.97607 13.6657 8.67225L12.2314 7.27779H16.2C16.6418 7.27779 17 6.92952 17 6.5C17 6.07048 16.6418 5.72221 16.2 5.72221H12.2314L13.6657 4.32775Z'
      fill='#333333'
    />
  </svg>
)
