export function normalizeLinks(document: Document) {
  const anchorEls = document.querySelectorAll<HTMLElement>('a')
  for (const anchorEl of anchorEls) {
    const href = anchorEl.getAttribute('href')
    if (href && href[0] !== '#') continue

    const spanEl = document.createElement('span')
    spanEl.append(...anchorEl.childNodes)
    anchorEl.replaceWith(spanEl)
  }
}
