import { Node } from '@tiptap/core'
import { getContainerNodeFromPos } from '../../content'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    readMore: {
      wrapInReadMore: () => ReturnType
    }
  }
}

export const ReadMoreNode = Node.create({
  name: 'readMore',
  content: '(block | table)+',
  isolating: true,

  addAttributes() {
    return {
      buttonBackgroundColor: {
        default: undefined,
        renderHTML: ({ buttonBackgroundColor }) => {
          if (!buttonBackgroundColor) return {}
          return { 'data-button-background-color': buttonBackgroundColor }
        },
      },
      buttonBorderColor: {
        default: undefined,
        renderHTML: ({ buttonBorderColor }) => {
          if (!buttonBorderColor) return {}
          return { 'data-button-border-color': buttonBorderColor }
        },
      },
      buttonHoverBackgroundColor: {
        default: undefined,
        renderHTML: ({ buttonHoverBackgroundColor }) => {
          if (!buttonHoverBackgroundColor) return {}
          return {
            'data-button-hover-background-color': buttonHoverBackgroundColor,
          }
        },
      },
      buttonHoverBorderColor: {
        default: undefined,
        renderHTML: ({ buttonHoverBorderColor }) => {
          if (!buttonHoverBorderColor) return {}
          return { 'data-button-hover-border-color': buttonHoverBorderColor }
        },
      },
      buttonColor: {
        default: undefined,
        renderHTML: ({ buttonColor }) => {
          if (!buttonColor) return {}
          return { 'data-button-color': buttonColor }
        },
      },
      buttonContractText: {
        default: undefined,
        renderHTML: ({ buttonContractText }) => {
          if (!buttonContractText) return {}
          return { 'data-button-contract-text': buttonContractText }
        },
      },
      buttonExpandText: {
        default: undefined,
        renderHTML: ({ buttonExpandText }) => {
          if (!buttonExpandText) return {}
          return { 'data-button-expand-text': buttonExpandText }
        },
      },
      buttonFontSizePx: {
        default: undefined,
        renderHTML: ({ buttonFontSizePx }) => {
          if (!buttonFontSizePx) return {}
          return { 'data-button-font-size': buttonFontSizePx + 'px' }
        },
      },
      buttonFontWeight: {
        default: undefined,
        renderHTML: ({ buttonFontWeight }) => {
          if (!buttonFontWeight) return {}
          return { 'data-button-font-weight': buttonFontWeight }
        },
      },
    }
  },

  addCommands() {
    return {
      wrapInReadMore:
        () =>
        ({ commands, dispatch, tr }) => {
          const canWrap = commands.wrapIn('readMore')
          if (!canWrap) return false

          if (dispatch) {
            const readMore = getContainerNodeFromPos(
              tr.selection.$from,
              'readMore',
            )
            if (readMore) commands.setNodeSelection(readMore.pos)
          }

          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'read-more',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['read-more', HTMLAttributes, 0]
  },
})
