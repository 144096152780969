import { cx } from '@emotion/css'
import React from 'react'
import './emptyState.scss'

export type EmptyStateProps = {
  className?: string
  title?: string
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  children,
  className,
  title,
  ...props
}) => {
  return (
    <div {...props} className={cx('empty-state', className)}>
      <If condition={title !== undefined}>
        <div className='empty-state-title'>{title}</div>
      </If>

      <div className='empty-state-body'>{children}</div>
    </div>
  )
}
