import { orderedList } from '@blissbook/lib/document'
import { Node, wrappingInputRule } from '@tiptap/core'

export const inputRegex = /^(\d+)\.\s$/

export const OrderedListNode = Node.create({
  name: 'orderedList',

  group: 'block list',

  content: 'listItem+',

  parseHTML() {
    return [
      {
        tag: 'ol',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['ol', HTMLAttributes, 0]
  },

  addKeyboardShortcuts() {
    return {
      [orderedList.keyboardShortcut]: () =>
        this.editor.commands.toggleList(orderedList.name, 'listItem'),
    }
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => ({ start: +match[1] }),
        joinPredicate: (match, node) =>
          node.childCount + node.attrs.start === +match[1],
      }),
    ]
  },
})
