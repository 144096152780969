import { embedAligns } from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { NodeSelectionToolbarProps } from '../../../selection/node'
import { ToolbarButton } from '../../../toolbar'

export const EmbedNodeSelectionAlignTool = ({
  selection,
  updateNodeAttributes,
}: NodeSelectionToolbarProps) => {
  const { node } = selection
  return (
    <>
      {embedAligns.map((align) => {
        const active = align.type === node.attrs.align
        return (
          <ToolbarButton
            active={active}
            key={align.type}
            label={align.label}
            onClick={async () => {
              const value = active ? undefined : align.type
              updateNodeAttributes({ align: value })
            }}
          >
            <FontAwesomeIcon icon={align.icon} />
          </ToolbarButton>
        )
      })}
    </>
  )
}
