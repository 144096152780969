import { Shape } from '@blissbook/ui/lib/shape'
import { useDimensions } from '@blissbook/ui/util/hooks'
import { keyframes } from '@emotion/react'
import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'

export const confettiShapeSize = 25

const getRandomInRange = (minValue, maxValue) =>
  minValue + (maxValue - minValue) * Math.random()

const getOrbitAnimation = (size) => {
  const duration = getRandomInRange(6000, 8000)
  const startAngle = getRandomInRange(0, 360)
  const endAngle = startAngle + 360
  const radius = getRandomInRange(size * 0.6, size * 0.8)
  const name = keyframes`
    from { transform: rotate(${startAngle}deg) translateX(${radius}px) rotate(${-startAngle}deg); }
    to { transform: rotate(${endAngle}deg) translateX(${radius}px) rotate(${-endAngle}deg); }
  `
  return `${name} ${duration}ms linear infinite`
}

const ConfettiShape = React.memo(
  ({
    showCannonAnimation,
    showOrbitAnimation,
    size = confettiShapeSize,
    x,
    xMin,
    xMax,
    y,
    yMin,
    yMax,
    ...props
  }) => {
    // Animations
    const animations = []

    // Cannon Animation?
    if (showCannonAnimation) {
      // Determine points
      const x0 = x < 0 ? xMin - size : xMax + size
      const y0 = yMax + size
      const x1 = (xMin + xMax) / 2 + x
      const y1 = y

      // Animation to explode
      const name = keyframes`
      from { left: ${x0}px; top: ${y0}px; }
      to { left: ${x1}px; top: ${y1}px; }
    `

      animations.push(`${name} 500ms ease-out forwards`)
    }

    // Orbit animation?
    if (showOrbitAnimation) {
      const animation = getOrbitAnimation(size)
      animations.push(animation)
    }

    return (
      <div
        css={{
          animation: animations.join(', '),
          position: 'absolute',
          left: `calc(50% + ${x}px)`,
          top: y,
        }}
      >
        <Shape
          {...props}
          css={{
            position: 'absolute',
            marginLeft: -size / 2,
            marginTop: -size / 2,
          }}
          size={size}
        />
      </div>
    )
  },
)

export const ShapeConfetti = React.memo(
  ({
    shapeDefaults,
    shapes,
    showCannonAnimation,
    showOrbitAnimation,
    ...props
  }) => {
    const [node, setNode] = useState()
    const [isInViewport, setInViewport] = useState(false)
    const { height, width } = useDimensions(node) || {}
    const canAnimate = isInViewport || !showCannonAnimation
    const showAnimation = showCannonAnimation || showOrbitAnimation
    return (
      <Waypoint
        onEnter={() => setInViewport(true)}
        bottomOffset={height}
        topOffset={height}
      >
        <div {...props} ref={setNode}>
          <If condition={!showAnimation || canAnimate}>
            {shapes.map((shape, index) => (
              <ConfettiShape
                key={index}
                {...shapeDefaults}
                {...shape}
                css={{
                  opacity: Math.abs(shape.x * 2) < width ? 1 : 0,
                }}
                showCannonAnimation={showCannonAnimation}
                showOrbitAnimation={showOrbitAnimation}
                xMin={0}
                xMax={width}
                yMin={0}
                yMax={height}
              />
            ))}
          </If>
        </div>
      </Waypoint>
    )
  },
)
