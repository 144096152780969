import type { FeatureFlag } from '@blissbook/lib/features'
import {
  type FeatureFlagId,
  useFeatureFlagsQuery,
} from '@blissbook/ui/application/graph'
import { handleError } from '@blissbook/ui/util/errors'
import { addToast } from '@blissbook/ui/util/toaster'
import { useCallback } from 'react'
import { useSetFeatureFlagMutation } from '../types-and-hooks'

// Hook to get the array of enabled feature flag ids
export function useFeatureFlagIds(): string[] {
  const { data } = useFeatureFlagsQuery()
  return data.featureFlagIds
}

// Hook to check if a feature flag is enabled
export function useFeatureFlagEnabled(flagId: string): boolean {
  const flagIds = useFeatureFlagIds()
  return flagIds.includes(flagId)
}

export function useSetFeatureFlag() {
  const [setFeatureFlag] = useSetFeatureFlagMutation({
    onError(error) {
      handleError(error)
    },
    update(cache, { data: { featureFlagIds } }) {
      cache.modify({
        fields: {
          featureFlagIds() {
            return featureFlagIds
          },
        },
      })
    },
  })

  return useCallback(
    (flag: FeatureFlag, enabled: boolean) =>
      setFeatureFlag({
        onCompleted() {
          addToast(`${flag.label} is now ${enabled ? 'enabled' : 'disabled'}`, {
            type: 'success',
          })
        },
        variables: {
          flagId: flag.id as FeatureFlagId,
          enabled,
        },
      }),
    [setFeatureFlag],
  )
}
