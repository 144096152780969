import { isValidHttpUrl, isValidHttpsUrl } from '@blissbook/lib/sanitize'
import * as Yup from 'yup'

Yup.addMethod<Yup.StringSchema>(Yup.string, 'httpUrl', function () {
  return this.test('httpUrl', 'must be a valid HTTP URL', (url) =>
    !url ? false : isValidHttpUrl(url),
  )
})

Yup.addMethod<Yup.StringSchema>(Yup.string, 'httpsUrl', function () {
  return this.test('httpsUrl', 'must be a valid HTTPS URL', (url) =>
    !url ? false : isValidHttpsUrl(url),
  )
})
