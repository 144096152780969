import { useEffect } from 'react'

export function useScrollLock(isEnabled = true) {
  const node = document.body
  return useEffect(() => {
    if (!node || !isEnabled) return

    // Disable scrolling
    const { scrollTop } = document.documentElement
    node.style.top = `-${scrollTop}px`
    node.classList.add('no-scroll')

    return () => {
      // Re-enable scrolling
      node.classList.remove('no-scroll')
      document.documentElement.scrollTop = scrollTop
    }
  }, [node, isEnabled])
}
