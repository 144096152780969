import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { type CSSProperties } from 'react'
import type { InputSize } from './types'

export type RadioProps = {
  checked?: boolean
  children?: any
  className?: string
  disabled?: boolean
  id?: string
  label?: string
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  size?: InputSize
  style?: CSSProperties
  value: string
}

export const Radio = React.forwardRef<HTMLDivElement, RadioProps>(
  ({ children, className, label, size, style, ...props }, ref) => {
    const { disabled, value } = props
    const id = props.id || value

    return (
      <div
        className={classnames(
          'form-check',
          size && `form-check-${size}`,
          className,
        )}
        ref={ref}
        style={style}
      >
        <input
          {...props}
          className='form-check-input tw-hidden'
          disabled={disabled}
          id={id}
          type='radio'
        />

        <label htmlFor={id}>
          <div
            className='form-check-box form-check-box-radio'
            style={{ marginRight: label || children ? '.5em' : 0 }}
          >
            <FontAwesomeIcon className='form-check-icon' icon='circle' />
          </div>

          <If condition={Boolean(label || children)}>
            <div className='form-check-label'>{label || children}</div>
          </If>
        </label>
      </div>
    )
  },
)

export function RadioGroup({
  children,
  name,
  onChangeValue,
  value,
}: {
  children: any
  name: string
  onChangeValue: (value: string) => void
  value: string
}) {
  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      checked: child.props.value === value,
      name,
      onChange: () => onChangeValue(child.props.value),
    }),
  )
}
