import { Node } from '@tiptap/core'
import type { ImageNodeAttributes } from '../../types'
import { embedAttributes } from './embed'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      insertImage: (attrs: ImageNodeAttributes) => ReturnType
    }
  }
}

export const ImageNode = Node.create({
  name: 'image',
  draggable: true,
  group: 'inline',
  inline: true,

  addAttributes() {
    return embedAttributes
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', HTMLAttributes]
  },

  addCommands() {
    return {
      insertImage:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent(
            {
              type: this.name,
              attrs: {
                align: 'center',
                ...attrs,
              },
            },
            {
              updateSelection: true,
            },
          )
        },
    }
  },
})
