import { getExpressionNodes } from '@blissbook/lib/document'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import { colors } from '@blissbook/ui/branding'
import type { Editor } from '../editor'

export function getSelectedElement(editor: Editor) {
  const pos = editor.state.selection.from
  const { node } = editor.view.domAtPos(pos)
  if (!node) return
  return node instanceof HTMLElement ? node : node.parentElement
}

function getElementStyle(element: HTMLElement, property: string) {
  return window.getComputedStyle(element).getPropertyValue(property)
}

// color

export const defaultColor = colors.black

export function getSelectedColor(editor?: Editor) {
  if (!editor) return defaultColor

  const attrs = editor.getAttributes('color')
  if (attrs.color) return attrs.color as string

  const element = getSelectedElement(editor)
  if (element) return getElementStyle(element, 'color')

  return defaultColor
}

// expression

export function getSelectedExpression(editor?: Editor) {
  if (!editor) return

  // Get all of the nodes that could have an extra
  const { doc, selection } = editor.state
  const { blockNodes } = getExpressionNodes(doc, selection)
  const expressions = blockNodes.map(
    (node) => node.node.attrs.audienceExpression as AudienceRootExpression,
  )

  // Must have a valid expression
  const [expression] = expressions
  if (!expressions) return

  // All expressions must have that expression
  const isEvery = expressions.every((e) => e === expression)
  if (isEvery) return expression
}

// fontSize

export const defaultFontSize = 16

export function getSelectedFontSize(editor?: Editor) {
  if (!editor) return defaultFontSize

  const attrs = editor.getAttributes('fontSize')
  if (attrs.fontSize) return Number.parseInt(attrs.fontSize, 10)

  const element = getSelectedElement(editor)
  if (element) {
    const fontSize = getElementStyle(element, 'font-size')
    return Number.parseInt(fontSize, 10)
  }

  return defaultFontSize
}
