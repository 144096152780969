import React from 'react'

export default ({ color, size, ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox='0 0 66 66'
    role='img'
    aria-label='arrows'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-150.000000, -2354.000000)'>
        <g transform='translate(0.000000, 1386.000000)'>
          <g transform='translate(150.000000, 934.000000)'>
            <g transform='translate(0.000000, 34.000000)'>
              <path
                d='M23.7366,5.4687 C34.9286,1.7687 47.0076,5.2077 54.5556,14.0167 L44.2566,14.0167 L44.2566,18.0167 L57.5066,18.0167 C59.1606,18.0167 60.5066,16.6707 60.5066,15.0167 L60.5066,1.7667 L56.5066,1.7667 L56.5066,10.2337 C47.8636,1.0867 34.7126,-2.3703 22.4826,1.6697 C8.8376,6.1787 -0.1964,18.8547 0.0036,33.2127 L4.0036,33.1577 C3.8276,20.5537 11.7576,9.4257 23.7366,5.4687'
                fill={color}
              />
              <g transform='translate(6.000000, 33.016600)'>
                <path
                  d='M55.5013,0.3457 C55.2493,12.5027 47.3223,23.2347 35.7763,27.0487 C25.5863,30.4167 14.4603,27.7707 6.8673,20.4997 L16.5063,20.4997 L16.5063,16.4997 L3.0063,16.4997 C1.3523,16.4997 0.0063,17.8457 0.0063,19.4997 L0.0063,32.9997 L4.0063,32.9997 L4.0063,23.2907 C10.1573,29.2317 18.3653,32.4947 26.7893,32.4947 C30.2103,32.4947 33.6663,31.9587 37.0313,30.8477 C50.1843,26.5007 59.2133,14.2777 59.5013,0.4277 L55.5013,0.3457 Z'
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
