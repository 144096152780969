import { Link } from '@blissbook/ui/lib/links'
import { shutdownIntercom } from '@blissbook/ui/util/integrations/intercom'
import React from 'react'

const onSignOut = (event) => {
  event.preventDefault()
  event.stopPropagation()
  shutdownIntercom()
  window.location = '/sign-out'
}

export const SignOutLink = (props) => {
  return <Link {...props} onClick={onSignOut} />
}
