import {
  type TableCellNodeAttributes,
  getTableCellVerticalAlign,
  tableAligns,
  tableCellVerticalAligns,
} from '@blissbook/lib/document'
import { MergeTableCellsIcon } from '@blissbook/ui/editor/icons'
import { ToolbarButton } from '@blissbook/ui/editor/toolbar'
import type { ToolComponentProps } from '@blissbook/ui/editor/tools'
import { ColorInputMenu, Popper, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { NodeWithPos } from '@tiptap/core'
import pluralize from 'pluralize'
import type { CellSelection } from 'prosemirror-tables'
import React from 'react'
import { useState } from 'react'
import type { Editor } from '../../editor'

export const TableAlignTool: React.FC<{
  editor: Editor
  table: NodeWithPos
}> = ({ editor, table }) => (
  <>
    {tableAligns.map((align) => {
      const active = align.value === table.node.attrs.align
      return (
        <ToolbarButton
          key={align.value}
          active={active}
          label={align.label}
          onClick={() => {
            editor
              .chain()
              .focus()
              .updateNodeAttributes(table.pos, { align: align.value })
              .run()
          }}
        >
          <FontAwesomeIcon icon={align.icon} />
        </ToolbarButton>
      )
    })}
  </>
)

export const TableTidyUpTool: React.FC<{
  editor: Editor
  table: NodeWithPos
}> = ({ editor, table, ...props }) => (
  <ToolbarButton
    {...props}
    label='Tidy up'
    onClick={() => {
      const tableEl = editor.view.nodeDOM(table.pos) as HTMLTableElement
      const width = tableEl.offsetWidth
      editor.chain().focus().tidyUpTable({ width }).run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'wand-magic-sparkles']} />
  </ToolbarButton>
)

export const MergeTableCellsTool: React.FC<ToolComponentProps> = ({
  editor,
  ...props
}) => (
  <ToolbarButton
    {...props}
    label='Merge Cells'
    onClick={() => {
      editor.chain().focus().mergeCells().run()
    }}
  >
    <MergeTableCellsIcon />
  </ToolbarButton>
)

export const SplitTableCellTool: React.FC<ToolComponentProps> = ({
  editor,
  ...props
}) => (
  <ToolbarButton
    {...props}
    active
    label='Split Cells'
    onClick={() => {
      editor.chain().focus().splitCell().run()
    }}
  >
    <MergeTableCellsIcon />
  </ToolbarButton>
)

export const RemoveTableTool: React.FC<ToolComponentProps> = ({
  editor,
  ...props
}) => (
  <ToolbarButton
    {...props}
    label='Delete Table'
    onClick={() => {
      editor.chain().focus().deleteTable().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'trash-can']} />
  </ToolbarButton>
)

export const RemoveTableColumnTool: React.FC<{
  colCount: number
  editor: Editor
}> = ({ colCount, editor, ...props }) => (
  <ToolbarButton
    {...props}
    label={pluralize('Delete Column', colCount)}
    onClick={() => {
      editor.chain().focus().deleteColumn().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'trash-can']} />
  </ToolbarButton>
)

export const RemoveTableRowTool: React.FC<{
  editor: Editor
  rowCount: number
}> = ({ editor, rowCount, ...props }) => (
  <ToolbarButton
    {...props}
    label={pluralize('Delete Row', rowCount)}
    onClick={() => {
      editor.chain().focus().deleteRow().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'trash-can']} />
  </ToolbarButton>
)

export function getCellSelectionBackgroundColor(
  editor: Editor,
  selection: CellSelection,
) {
  // Count the colors
  const colorCounts = new Map<string, number>()
  for (const range of selection.ranges) {
    const cellEl = editor.view.nodeDOM(
      range.$from.pos - 1,
    ) as HTMLTableCellElement
    const color = window
      .getComputedStyle(cellEl)
      .getPropertyValue('background-color')
    const count = colorCounts.has(color) ? colorCounts.get(color) : 0
    colorCounts.set(color, count + 1)
  }

  // Pick the largest
  const entries = [...colorCounts.entries()]
  entries.sort((lhs, rhs) => rhs[1] - lhs[1])
  return entries[0][0]
}

export function isCellSelectionBackgroundColor(selection: CellSelection) {
  for (const range of selection.ranges) {
    const node = range.$from.node()
    const { backgroundColor } = node.attrs as TableCellNodeAttributes
    if (backgroundColor !== undefined) return true
  }
  return false
}

export const TableCellBackgroundColorTool: React.FC<{
  className?: string
  editor: Editor
  selection: CellSelection
}> = ({ className, editor, selection }) => {
  const [isOpen, setOpen] = useState(false)
  const value = getCellSelectionBackgroundColor(editor, selection)
  const active = isOpen || isCellSelectionBackgroundColor(selection)
  return (
    <Popper.Provider isOpen={isOpen} setOpen={setOpen}>
      <Tooltip content='Set Cell Color'>
        <Popper.ToggleButton
          className={cx(
            'btn-tool tw-flex tw-flex-col tw-justify-end',
            { active },
            className,
          )}
        >
          <FontAwesomeIcon icon={['fas', 'fill-drip']} />

          <div
            className='tw-my-0.5 tw-rounded tw-w-full'
            css={{ height: 3 }}
            style={{
              backgroundColor: value,
            }}
          />
        </Popper.ToggleButton>
      </Tooltip>

      {isOpen && (
        <ColorInputMenu
          onChange={(value) => {
            editor
              .chain()
              .focus()
              .setCellAttributes({ backgroundColor: value })
              .run()
          }}
          onChanging={(value) => {
            for (const range of selection.ranges) {
              const cellEl = editor.view.nodeDOM(
                range.$from.pos - 1,
              ) as HTMLElement
              cellEl.style.backgroundColor = value
            }
          }}
          onRemove={() => {
            editor
              .chain()
              .focus()
              .setCellAttributes({ backgroundColor: undefined })
              .run()
            setOpen(false)
          }}
          palette={editor.colorPalette}
          showAlpha
          value={value}
        />
      )}
    </Popper.Provider>
  )
}

export const TableCellVerticalAlignTool: React.FC<{
  editor: Editor
  selection: CellSelection
}> = ({ editor, selection }) => (
  <>
    {tableCellVerticalAligns.map((valign) => {
      const active = selection.ranges.some((range) => {
        const node = range.$from.node()
        return getTableCellVerticalAlign(node.attrs.valign) === valign
      })

      return (
        <ToolbarButton
          key={valign.value}
          active={active}
          label={valign.label}
          onClick={() => {
            editor
              .chain()
              .focus()
              .setCellAttributes({ valign: valign.value })
              .run()
          }}
        >
          <FontAwesomeIcon icon={valign.icon} />
        </ToolbarButton>
      )
    })}
  </>
)
