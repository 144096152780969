import {
  getContentFromProsemirrorDocNode,
  isProseMirrorNodeEmpty,
} from '@blissbook/lib/document'
import { EditorView, useEditor } from '@blissbook/ui/editor'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { useField } from 'formik'
import React, { useEffect } from 'react'

export const Field = ({ disabled, name, schema, variables, ...props }) => {
  const editable = !disabled
  const [{ value }, , { setValue }] = useField(name)

  const editor = useEditor({
    content: { type: 'doc', content: value },
    editable,
    schema,
    variables,
  })

  useEffect(() => {
    if (!editor) return

    const onEditorUpdate = () => {
      const { doc } = editor.state
      const content = getContentFromProsemirrorDocNode(doc)
      const isEmpty = isProseMirrorNodeEmpty(doc)
      setValue(isEmpty ? null : content).catch(logUIError)
    }

    editor.on('update', onEditorUpdate)
    return () => {
      editor.off('update', onEditorUpdate)
    }
  }, [editor, setValue])

  useEffect(() => {
    if (!editor) return
    editor.setOptions({ editable })
  }, [disabled, editor])

  if (!editor) return null
  return <EditorView {...props} editor={editor} />
}
