import pick from 'lodash/pick'

export const fontMimeTypesByExtension: Record<string, string> = {
  '.eot': undefined,
  '.otf': undefined,
  '.svg': 'image/svg+xml',
  '.ttf': undefined,
  '.woff': 'application/font-woff',
  '.woff2': undefined,
}
export const fontExtensions = Object.keys(fontMimeTypesByExtension)
export const fontMimeTypes = Object.values(fontMimeTypesByExtension).filter(
  Boolean,
)

export const fontInputAccept = [...fontExtensions, ...fontMimeTypes]

export const imageMimeTypesByExtension: Record<string, string> = {
  '.bmp': 'image/bmp',
  '.gif': 'image/gif',
  // '.heic': 'image/heic',
  '.ico': 'image/vnd.microsoft.icon',
  '.jfif': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpg',
  '.png': 'image/png',
  '.svg': 'image/svg+xml',
  // '.tif': 'image/tiff',
  // '.tiff': 'image/tiff',
  '.webp': 'image/webp',
}
export const imageExtensions = Object.keys(imageMimeTypesByExtension)
export const imageMimeTypes = Object.values(imageMimeTypesByExtension)

export const emailImageMimeTypesByExtension = pick(
  imageMimeTypesByExtension,
  '.gif',
  '.jpg',
  '.jpeg',
  '.png',
)
export const emailImageMimeTypes = Object.values(emailImageMimeTypesByExtension)

export const pdfMimeTypesByExtension: Record<string, string> = {
  '.pdf': 'application/pdf',
}
export const pdfExtensions = Object.keys(pdfMimeTypesByExtension)
export const pdfMimeTypes = Object.values(pdfMimeTypesByExtension)

export const sanitizeFilename = (filename: string) => {
  return filename.replace(/[^a-z0-9_.]+/gi, '-')
}

export const validateFilepath = (filepath: string) => {
  const invalid = /[^a-z0-9-_./]/i.test(filepath)
  if (invalid) throw new TypeError(`Invalid Filepath: ${filepath}`)
}
