import { type TextAlign, textAligns } from '@blissbook/lib/document'
import { Dropdown, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { Editor } from '../editor'
import { renderToolbarTooltip } from '../toolbar/tooltip'
import { hasExtension } from '../util'
import type { ToolComponent } from './types'

const getTextAligns = (editor: Editor) => {
  if (editor?.textAlign?.values) {
    return textAligns.filter((textAlign) =>
      editor.textAlign.values.includes(textAlign.value),
    )
  }

  return textAligns
}

export const getTextAlign = (editor: Editor) => {
  if (!editor) return textAligns[0]

  // Check for a state
  if (editor.textAlign) {
    const value = editor.textAlign.value || editor.textAlign.defaultValue
    return textAligns.find((textAlign) => textAlign.value === value)
  }

  // Check the text
  for (const textAlign of textAligns) {
    const isActive = isTextAlignActive(editor, textAlign.value)
    if (isActive) return textAlign
  }
}

const isTextAlignActive = (editor: Editor, align: TextAlign) => {
  // TODO: use textAlign types
  const types = ['heading', 'paragraph']
  return types.some((type) => {
    const { textAlign } = editor.getAttributes(type)
    return textAlign === align
  })
}

export const TextAlignTool: ToolComponent = ({
  className,
  editor,
  ...props
}) => {
  const canEdit =
    hasExtension(editor, 'textAlign') || editor?.textAlign !== undefined
  const textAligns = getTextAligns(editor)
  const textAlign = getTextAlign(editor) || textAligns[0]
  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        {...props}
        className={cx('btn-tool tw-w-auto', className)}
        disabled={!canEdit}
      >
        <FontAwesomeIcon icon={textAlign.icon} />
      </Dropdown.ToggleButton>
      <Dropdown.Menu className='tw-flex tw-p-1'>
        {textAligns.map((align) => (
          <Tooltip
            content={renderToolbarTooltip(align.label, align.keyboardShortcut)}
            key={align.value}
          >
            <Dropdown.Item
              className='tw-px-2'
              onClick={() => {
                if (!editor) return

                if (editor.textAlign) {
                  editor.textAlign.setValue(align.value)
                  editor.chain().focus().run()
                } else {
                  editor.chain().focus().setTextAlign(align.value).run()
                }
              }}
            >
              <FontAwesomeIcon className='tw-m-0' icon={align.icon} />
            </Dropdown.Item>
          </Tooltip>
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
