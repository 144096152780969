import React from 'react'
import type { Editor } from '../editor'
import { ToolbarDivider } from '../toolbar/ToolbarDivider'
import { ColorTool } from './color'
import { HorizontalRuleTool, ImageTool, PdfTool, VideoTool } from './embed'
import { ExpressionTool } from './expression'
import { FontSizeTool } from './fontSize'
import { HeadingTool } from './heading'
import { IndentTool } from './indent'
import { BulletListTool, OrderedListTool } from './list'
import { WrapInReadMoreTool } from './readMore'
import { TableTool } from './table'
import {
  BoldTool,
  ClearFormatsTool,
  ItalicTool,
  LinkTool,
  UnderlineTool,
} from './text'
import { TextAlignTool } from './textAlign'
import type { ToolComponent, ToolComponentProps } from './types'
import { VariableTool } from './variable'

const toolsByName: Record<string, ToolComponent> = {
  '|': ToolbarDivider,
  bold: BoldTool,
  bulletList: BulletListTool,
  clearFormats: ClearFormatsTool,
  color: ColorTool,
  expression: ExpressionTool,
  fontSize: FontSizeTool,
  heading: HeadingTool,
  horizontalRule: HorizontalRuleTool,
  image: ImageTool,
  indent: IndentTool,
  italic: ItalicTool,
  link: LinkTool,
  orderedList: OrderedListTool,
  pdf: PdfTool,
  readMore: WrapInReadMoreTool,
  table: TableTool,
  textAlign: TextAlignTool,
  underline: UnderlineTool,
  variable: VariableTool,
  video: VideoTool,
}

type ToolbarToolProps = ToolComponentProps & {
  name: string
}

export const ToolbarTool: React.FC<ToolbarToolProps> = ({ name, ...props }) => {
  const ToolComponent = toolsByName[name]
  if (!ToolComponent) {
    console.info(`Unknown document tool: ${name}`)
    return null
  }

  return <ToolComponent {...props} />
}

export const ToolbarTools: React.FC<{
  editor: Editor
  tools: string
}> = ({ editor, tools }) => (
  <>
    {tools.split(' ').map((name, index) => (
      <ToolbarTool editor={editor} key={index} name={name} />
    ))}
  </>
)
