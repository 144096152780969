import { fromSettingKey, getDefaultValues } from './settings'

export class Theme {
  id: string
  defaultValues: any
  settings: any[]

  static fromJSON({ settings = [], ...json }: { settings: string[] }) {
    const theme = Object.assign(new Theme(), json)
    if (settings) theme.settings = settings.map(fromSettingKey)
    return theme
  }

  getDefaultValues() {
    const defaultValues = getDefaultValues(this.settings)
    return { ...defaultValues, ...this.defaultValues }
  }

  getValues(values: any) {
    const defaultValues = getDefaultValues(this.settings)
    return { ...defaultValues, ...values, ...this.defaultValues }
  }
}
