import type { DocumentVersion } from '@blissbook/lib/document'
import type { PersonalizationInput } from './Personalization'
import { evaluateAudienceExpression } from './expression'

export type DocumentAccessControl = {
  languageCode?: string
  personalization?: PersonalizationInput
}

export function canAccessDocument(
  document: DocumentVersion,
  accessControl: DocumentAccessControl,
) {
  const { languageCode, personalization } = accessControl
  // Language mismatch = disallow
  if (languageCode && document.languageCode !== languageCode) return false
  // No personalization  = allow
  if (!personalization) return true
  // No audienceExpression = allow
  if (!document.audienceExpression) return true
  // Personalize
  return evaluateAudienceExpression(
    document.audienceExpression,
    personalization,
  )
}
