import { type AspectRatio, videoAspectRatios } from '@blissbook/lib/aspectRatio'
import React, { forwardRef } from 'react'
import {
  ProseMirrorBubbleToolbar,
  type ProseMirrorBubbleToolbarProps,
  ToolbarButton,
  ToolbarDivider,
} from '../../toolbar'
import { EmbedRemoveIcon, EmbedReplaceIcon } from '../embed'

export type VideoToolbarProps = ProseMirrorBubbleToolbarProps & {
  aspectRatio: AspectRatio
  onChangeAspectRatio: (aspectRatio: AspectRatio) => void
  onRemove: () => void
  onReplace: () => void
}

export const VideoToolbar = forwardRef<HTMLDivElement, VideoToolbarProps>(
  (
    { aspectRatio, onChangeAspectRatio, onRemove, onReplace, ...props },
    ref,
  ) => (
    <ProseMirrorBubbleToolbar {...props} ref={ref}>
      {videoAspectRatios.map((ratio, index) => {
        return (
          <ToolbarButton
            active={ratio === aspectRatio}
            className='tw-text-sm tw-w-auto'
            key={index}
            label={`${ratio.toString()} Aspect Ratio`}
            onClick={() => {
              if (ratio !== aspectRatio) {
                onChangeAspectRatio(ratio)
              }
            }}
          >
            {ratio.toString()}
          </ToolbarButton>
        )
      })}

      <ToolbarDivider />

      <ToolbarButton
        label='Replace Video'
        onClick={() => {
          onReplace()
        }}
      >
        <EmbedReplaceIcon />
      </ToolbarButton>

      <ToolbarButton
        label='Remove Video'
        onClick={() => {
          onRemove()
        }}
      >
        <EmbedRemoveIcon />
      </ToolbarButton>
    </ProseMirrorBubbleToolbar>
  ),
)
