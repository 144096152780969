import type { Editor } from '@blissbook/ui/editor'
import { cx } from '@emotion/css'
import type { Range } from '@tiptap/core'
import last from 'lodash/last'
import React from 'react'
import { useMemo } from 'react'

type Rect = {
  left: number
  right: number
  top: number
  bottom: number
}

function getTextRects(editor: Editor, range: Range) {
  const { state, view } = editor
  const editorRect = view.dom.getBoundingClientRect()
  const xOffset = editorRect.left + 16
  const yOffset = editorRect.top

  function addRect(rect: Rect) {
    // Adjust the rect
    rect = {
      bottom: rect.bottom - yOffset,
      left: rect.left - xOffset,
      right: rect.right - xOffset,
      top: rect.top - yOffset,
    }

    // Reduce it in
    const lastRect = last(rects)
    if (
      lastRect &&
      lastRect.top === rect.top &&
      lastRect.bottom === rect.bottom
    ) {
      lastRect.right = rect.right
    } else {
      rects.push(rect)
    }
  }

  // Get all of the nodes
  const rects: Rect[] = []
  state.doc.nodesBetween(range.from, range.to, (node, nodePos) => {
    const from = nodePos
    const to = from + node.nodeSize
    if (node.type.name === 'text') {
      for (let pos = from; pos <= to; pos++) {
        const inRange = pos >= range.from && pos <= range.to
        if (!inRange) continue

        if (pos > from) {
          const rect = view.coordsAtPos(pos, -1)
          addRect(rect)
        }
        if (pos < to) {
          const rect = view.coordsAtPos(pos, 1)
          addRect(rect)
        }
      }
    }
  })

  return rects
}

function useTextRects(editor: Editor, range: Range) {
  return useMemo(() => getTextRects(editor, range), [editor, range])
}

export const TextSelectionHighlight: React.FC<{
  className?: string
  editor: Editor
  range: Range
}> = ({ className, editor, range, ...props }) => {
  const rects = useTextRects(editor, range)
  return (
    <>
      {rects.map((rect, index) => (
        <div
          {...props}
          className={cx(
            'tw-absolute tw-bg-aqua-500/20 tw-pointer-events-none',
            className,
          )}
          key={index}
          style={{
            height: rect.bottom - rect.top,
            left: rect.left,
            top: rect.top,
            width: rect.right - rect.left,
          }}
        />
      ))}
    </>
  )
}
