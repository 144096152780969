import type { Editor } from '@tiptap/core'
import times from 'lodash/times'

export type HeadingDefinition = {
  keyboardShortcut: string
  label: string
  level: number
}

export const headings: HeadingDefinition[] = times(5, (index) => {
  const level = index + 1
  return {
    keyboardShortcut: `Mod-Alt-${level}`,
    label: `Heading ${level}`,
    level: index + 1,
  }
})

export const getActiveHeading = (editor: Editor) =>
  headings.find((heading) => {
    const { level } = heading
    return editor.isActive('heading', { level })
  })
