import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import React, { useState } from 'react'
import { Input, useValue } from './Input'

export const onAutoSaveReset = (nodes) =>
  nodes.forEach((node) => {
    node.classList.remove('rw-auto-save', 'has-error', 'has-success')
  })

const onAutoSave = (nodes, className) =>
  nodes.forEach((node) => {
    node.classList.add(className)
    setTimeout(() => node.classList.add('rw-auto-save'))
    setTimeout(() => node.classList.remove(className), 250)
  })

export const onAutoSaveSuccess = (nodes) => onAutoSave(nodes, 'has-success')
export const onAutoSaveError = (nodes) => onAutoSave(nodes, 'has-error')

export const AutoSaveInput = (props) => {
  const { debounce = 500, emptyValue = null } = props
  const [inputNode, setInputNode] = useState()
  const [value, setValue] = useValue(props.value)

  const onChangeValue = (newValue) => {
    setValue(newValue)
    if (newValue !== value) submitValue(newValue).catch(logUIError)
  }

  const submitValue = async (value) => {
    // Reset the input nodes
    const { labels = [] } = inputNode
    const nodes = [inputNode, ...labels]
    onAutoSaveReset(nodes)

    // Run the submission
    try {
      await props.onChangeValue(value)
      onAutoSaveSuccess(nodes)
    } catch (_err) {
      onAutoSaveError(nodes)
    }
  }

  return (
    <Input
      {...props}
      debounce={debounce}
      emptyValue={emptyValue}
      onChangeValue={onChangeValue}
      ref={setInputNode}
      value={value}
    />
  )
}
