import classnames from 'classnames'
import isObject from 'lodash/isObject'
import React from 'react'

// These are based off of the Bootstrap 4 Grid system: https://getbootstrap.com/docs/4.0/layout/grid/

type DeviceSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const Container: React.FC<{
  className?: string
  fluid?: boolean
}> = ({ className, fluid, ...props }) => (
  <div
    {...props}
    className={classnames(fluid ? 'container-fluid' : 'container', className)}
  />
)

type ColumnArgs = {
  offset?: number
  size: number | string
}

type ColumnProp = number | 'auto' | ColumnArgs

const getColClassName = (
  prefix: string,
  deviceSize: DeviceSize | undefined,
  size: number | string | undefined,
) => {
  if (size === undefined) return
  return [prefix, deviceSize, size].filter(Boolean).join('-')
}

const getColClassNames = (
  prop: ColumnProp | undefined,
  deviceSize?: DeviceSize,
) => {
  if (!prop) return
  const args = isObject(prop) ? prop : { size: prop }
  const { offset, size } = args
  return [
    getColClassName('col', deviceSize, size),
    getColClassName('offset', deviceSize, offset),
  ]
}

export const Col: React.FC<{
  className?: string
  lg?: ColumnProp
  md?: ColumnProp
  sm?: ColumnProp
  xl?: ColumnProp
  xs?: ColumnProp
}> = ({ className, lg, md, sm, xl, xs, ...props }) => (
  <div
    {...props}
    className={classnames(
      getColClassNames(xs),
      getColClassNames(sm, 'sm'),
      getColClassNames(md, 'md'),
      getColClassNames(lg, 'lg'),
      getColClassNames(xl, 'xl'),
      className,
    )}
  />
)

export const Row: React.FC<{
  className?: string
}> = ({ className, ...props }) => (
  <div {...props} className={classnames('row', className)} />
)
