import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import './alert.scss'

export type AlertType = 'upgrade' | 'info' | 'success' | 'warning' | 'danger'

export const alertTypes: AlertType[] = [
  'upgrade',
  'info',
  'success',
  'warning',
  'danger',
]

export type AlertTypeMetadata = {
  icon: IconProp
}

export type AlertVariant = 'small' | 'large' | 'stacked'

const AlertTypesMap = new Map<AlertType, AlertTypeMetadata>([
  [
    'upgrade',
    {
      icon: 'sparkles',
    },
  ],
  [
    'info',
    {
      icon: 'info',
    },
  ],
  [
    'success',
    {
      icon: 'check',
    },
  ],
  [
    'warning',
    {
      icon: 'exclamation',
    },
  ],
  [
    'danger',
    {
      icon: 'exclamation',
    },
  ],
])

export type AlertProps = {
  canDismiss?: boolean
  className?: string
  title?: string
  type: AlertType
  variant: AlertVariant
}

export const Alert: React.FC<AlertProps> = ({
  canDismiss,
  children,
  className,
  title,
  type = 'warning',
  variant = 'small',
  ...props
}) => {
  const { icon } = AlertTypesMap.get(type)
  const [dismissed, setDismissed] = useState(false)
  if (dismissed) return null

  return (
    <div
      {...props}
      className={cx(`alert alert-${type} alert-${variant}`, className)}
    >
      <div
        className={
          variant === 'stacked'
            ? 'tw-flex tw-flex-col tw-items-center'
            : 'tw-flex'
        }
      >
        <div className='alert-icon'>
          <FontAwesomeIcon icon={icon} />
        </div>

        <div className={variant === 'stacked' ? 'tw-w-full' : 'tw-flex-1'}>
          <If condition={!!title}>
            <div className='alert-title'>{title}</div>
          </If>

          <If condition={!!children}>
            <div className='alert-body tw-mt-1'>{children}</div>
          </If>
        </div>

        <If condition={canDismiss}>
          <button
            type='button'
            className='alert-dismiss'
            data-dismiss='alert'
            aria-label='Close'
            onClick={() => {
              setDismissed(true)
            }}
          >
            <FontAwesomeIcon icon='times' />
          </button>
        </If>
      </div>
    </div>
  )
}
