import { getHTMLFromFragment } from '@tiptap/core'
import {
  DOMParser,
  Node as ProseMirrorNode,
  type Schema,
} from 'prosemirror-model'
import { parseHTML } from 'zeed-dom'
import { type DocNode, type Node, reduceNode } from './types'

export function htmlToDOM(html: string) {
  // Server
  if (parseHTML) {
    return parseHTML(html) as unknown as Document
  }
  // Browser

  const value = `<body>${html}</body>`
  return new window.DOMParser().parseFromString(value, 'text/html')
}

export function domToJSON(dom: Document, schema: Schema) {
  const doc = DOMParser.fromSchema(schema).parse(dom, {
    preserveWhitespace: true,
  })
  const { content } = doc.toJSON() as DocNode
  return content.map(reduceNode)
}

export function htmlToJSON(html: string, schema: Schema) {
  const dom = htmlToDOM(html)
  return domToJSON(dom, schema)
}

export function jsonToHTML(content: Node[], schema: Schema) {
  const doc = { type: 'doc', content }
  const contentNode = ProseMirrorNode.fromJSON(schema, doc)
  return getHTMLFromFragment(contentNode.content, schema)
}
