import {
  isCellSelection,
  isNodeSelection,
  isTextSelection,
} from '@blissbook/lib/document'
import React from 'react'
import { useEffect, useState } from 'react'
import type { Editor } from '../editor'
import { CellSelectionView } from './cell/view'
import { NodeSelectionView } from './node/view'
import { TextSelectionView } from './text/view'

type SelectionViewProps = {
  editor: Editor
  isSelecting: boolean
  showBubbleMenu?: boolean
}

export const useSelecting = (editor: Editor) => {
  const { dom } = editor.view
  const [isSelecting, setSelecting] = useState(false)

  useEffect(() => {
    function onMouseDown() {
      setSelecting(true)
    }

    function onMouseUp() {
      setSelecting(false)
    }

    dom.addEventListener('mousedown', onMouseDown)
    dom.addEventListener('mouseup', onMouseUp)
    dom.addEventListener('dragend', onMouseUp)
    dom.addEventListener('drop', onMouseUp)
    return () => {
      dom.removeEventListener('mousedown', onMouseDown)
      dom.removeEventListener('mouseup', onMouseUp)
      dom.removeEventListener('dragend', onMouseUp)
      dom.removeEventListener('drop', onMouseUp)
    }
  }, [dom])

  return isSelecting
}

export const SelectionView: React.FC<SelectionViewProps> = ({
  isSelecting,
  showBubbleMenu,
  ...props
}) => {
  const { editor } = props
  const { selection } = editor.state

  if (isTextSelection(selection)) {
    return (
      <TextSelectionView
        {...props}
        selection={selection}
        showBubbleMenu={showBubbleMenu && !isSelecting}
      />
    )
  }
  if (isNodeSelection(selection) && showBubbleMenu) {
    return <NodeSelectionView {...props} selection={selection} />
  }
  if (isCellSelection(selection) && showBubbleMenu) {
    return <CellSelectionView {...props} selection={selection} />
  }
  return null
}
