import { getContainerNodeFromPos } from '@blissbook/lib/document'
import type { Editor } from '@blissbook/ui/editor'
import { TableEditor } from '@blissbook/ui/editor/nodes'
import React from 'react'

type SelectionContainerViewProps = {
  editor: Editor
}

export const SelectionContainerView = (props: SelectionContainerViewProps) => {
  const { editor } = props
  const { selection } = editor.state
  const table = getContainerNodeFromPos(selection.$from, 'table')

  return <>{table && <TableEditor {...props} table={table} />}</>
}
