import type { DocumentSchema } from '@blissbook/lib/document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import HistoryExtension from '@tiptap/extension-history'
import { LinkEditorMark } from '../marks'
import {
  HorizontalRuleEditorNode,
  ImageEditorNode,
  PdfEditorNode,
  ReadMoreEditorNode,
  TableCellEditorNode,
  TableEditorNode,
  VariableEditorNode,
  VideoEditorNode,
} from '../nodes'
import { EditorExtension } from './editor'
import { ExpressionsExtension } from './expressions'

const overrides = [
  HorizontalRuleEditorNode,
  ImageEditorNode,
  LinkEditorMark,
  PdfEditorNode,
  ReadMoreEditorNode,
  TableEditorNode,
  TableCellEditorNode,
  VariableEditorNode,
  VideoEditorNode,
]

export const getExtensions = (schema: DocumentSchema) => {
  const extensions = [
    ...schema.extensions,
    Dropcursor,
    Gapcursor,
    HistoryExtension,
    EditorExtension,
    ExpressionsExtension,
  ]

  for (let index = 0; index < extensions.length; ++index) {
    const extension = extensions[index]
    const override = overrides.find(
      (override) => override.name === extension.name,
    )
    if (override) extensions[index] = override
  }

  return extensions
}
