import isObject from 'lodash/isObject'

type GraphType = {
  __typename?: string
}

export function omitTypename<T>(value: T): T {
  // Strip from arrays
  if (Array.isArray(value)) {
    return value.map(omitTypename) as T
  }

  // Strip from objects
  if (isObject(value)) {
    const object = { ...value } as GraphType
    for (const key of Object.keys(object)) {
      if (key === '__typename') {
        delete object[key]
      } else {
        object[key as keyof GraphType] = omitTypename(
          object[key as keyof GraphType],
        )
      }
    }
    return object as T
  }

  return value
}
