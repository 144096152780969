import * as vidyard from './vidyard'
import * as vimeo from './vimeo'
import * as wistia from './wistia'
import * as youtube from './youtube'

type VideoJSON = {
  url: string
  coverUrl?: string
}

export type VideoSource = {
  fromUrl: (url: string) => VideoJSON
  label: string
  exampleUrl: string
  playerProps?: Record<string, any>
}

export const videoSourcesByType: Record<string, VideoSource> = {
  vidyard,
  vimeo,
  wistia,
  youtube,
}

export const videoSources = Object.values(videoSourcesByType)
