import { isNodeSelection, isTextSelection } from '@blissbook/lib/document'
import { Dropdown } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import type { ToolComponent } from './types'

export const VariableTool: ToolComponent = ({
  className,
  editor,
  ...props
}) => {
  if (!editor) return null

  const { variables } = editor
  if (!variables) return null

  const { selection } = editor.state
  const canInsert = isNodeSelection(selection) || isTextSelection(selection)
  if (!canInsert) return null

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        {...props}
        className={cx(
          'btn btn-tool tw-font-bold tw-px-1.5 tw-py-0.5 tw-text-xs tw-w-auto tw-uppercase',
          className,
        )}
        style={{ letterSpacing: 2 }}
      >
        Variables
      </Dropdown.ToggleButton>

      <Dropdown.Menu className='tw-py-2'>
        {variables.map((variable, index) => {
          const { category } = variable
          const prevCategory = variables[index - 1]?.category
          return (
            <Fragment key={index}>
              <If condition={category !== prevCategory}>
                <div
                  className={cx(
                    'tw-font-semibold tw-mb-1 tw-px-2 tw-text-blurple-500',
                    prevCategory ? 'tw-mt-4' : undefined,
                  )}
                >
                  <FontAwesomeIcon
                    className='tw-mr-1.5'
                    fixedWidth
                    icon={category.dropdownIcon}
                  />
                  {category.label}
                </div>
              </If>

              <Dropdown.Item
                className='tw-px-8'
                onClick={() => {
                  editor.chain().focus().insertVariable(variable).run()
                }}
              >
                {variable.dropdownItem || variable.label}
              </Dropdown.Item>
            </Fragment>
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
