import { useCallback, useMemo, useState } from 'react'

const getSortedItems = <T>(
  items: T[],
  dragIndex: number,
  dropIndex: number,
) => {
  const sortedItems = [...items]
  sortedItems.splice(dragIndex, 1)
  sortedItems.splice(dropIndex, 0, items[dragIndex])
  return sortedItems
}

export const useSortableItems = <T>(
  items: T[],
  onSort: (sortedItems: T[]) => void,
) => {
  const [dragIndex, setDragIndex] = useState<number>()
  const [hoverIndex, setHoverIndex] = useState<number>()

  const onDragEnd = useCallback(() => {
    setDragIndex(undefined)
    setHoverIndex(undefined)
  }, [])

  const onDropHover = useCallback((dragIndex: number, hoverIndex: number) => {
    setDragIndex(dragIndex)
    setHoverIndex(hoverIndex)
  }, [])

  const onDrop = useCallback(
    (dragIndex: number, dropIndex: number) => {
      const sortedItems = getSortedItems(items, dragIndex, dropIndex)
      onSort(sortedItems)
      setDragIndex(undefined)
      setHoverIndex(undefined)
    },
    [items],
  )

  const sortedItems = useMemo(() => {
    if (dragIndex === undefined || hoverIndex === undefined) return items
    return getSortedItems(items, dragIndex, hoverIndex)
  }, [dragIndex, hoverIndex, items])

  return [sortedItems, { onDragEnd, onDrop, onDropHover }] as const
}
