import { isTextSelection } from '@blissbook/lib/document'
import type { Range } from '@tiptap/core'
import type { Node as ProseMirrorNode } from 'prosemirror-model'
import type { EditorState, Transaction } from 'prosemirror-state'
import { v4 as uuidV4 } from 'uuid'
import type { Editor } from '../../editor'
import { pluginKey } from './plugin'

export type LinkEditorState = {
  href?: string
  id: string
  range: Range
}

type LinkEditorStateArgs = Omit<LinkEditorState, 'id'>

function buildLinkEditorState(args: LinkEditorStateArgs): LinkEditorState {
  const id = uuidV4()
  return { ...args, id }
}

export function getNextLinkEditorState({
  newState,
  oldState,
  pluginState,
  tr,
}: {
  newState: EditorState
  oldState: EditorState
  pluginState: LinkEditorState
  tr: Transaction
}) {
  const { doc, selection } = newState
  const meta = tr.getMeta(pluginKey)

  // Did the user force a link editor on/off?
  if (meta) {
    if (!meta.show) return
    if (!isTextSelection(selection)) return

    const { from, to } = selection
    return buildLinkEditorStateFromRange(doc, { from, to })
  }
  // If the selection didn't change, retain
  if (selection === oldState.selection) {
    return pluginState
  }
  // If the selection is a caret, try to find an href
  if (isTextSelection(selection) && selection.empty) {
    return buildLinkEditorStateFromPos(doc, selection.from)
  }
}

function buildLinkEditorStateFromRange(doc: ProseMirrorNode, range: Range) {
  const hrefs: string[] = []

  const { from, to } = range
  doc.nodesBetween(from, to, (node) => {
    if (node.type.name === 'text') {
      const linkMark = node.marks?.find((mark) => mark.type.name === 'link')
      hrefs.push(linkMark?.attrs.href)
    }
  })

  const href = hrefs.reduce((result, href) => {
    if (!result || href !== result) return
    return result
  })

  return buildLinkEditorState({ href, range })
}

function buildLinkEditorStateFromPos(doc: ProseMirrorNode, pos: number) {
  let state: LinkEditorState

  doc.nodesBetween(pos, pos, (node, pos) => {
    if (node.type.name === 'text') {
      const linkMark = node.marks?.find((mark) => mark.type.name === 'link')
      if (!linkMark) return

      const { href } = linkMark.attrs
      const from = pos
      const to = pos + node.nodeSize
      const range = { from, to }
      state = buildLinkEditorState({ href, range })
    }
  })

  return state
}

export function getLinkEditorState(editor: Editor) {
  return pluginKey.getState(editor.state)
}
