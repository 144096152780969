import { Node } from '@tiptap/core'

export const TableCellNode = Node.create({
  name: 'tableCell',

  content: 'block+',

  addAttributes() {
    return {
      backgroundColor: {
        default: undefined,
        parseHTML: (element: HTMLElement) => {
          return element.style.backgroundColor || undefined
        },
        renderHTML: ({ backgroundColor }) => {
          if (!backgroundColor) return {}
          return { style: `background-color: ${backgroundColor}` }
        },
      },
      colspan: {
        default: 1,
        renderHTML: ({ colspan }) => {
          if (colspan > 1) return { colspan }
          return {}
        },
      },
      height: {
        default: undefined,
        parseHTML: (element) => {
          const { height } = element.style
          return height ? Number.parseInt(height, 10) : undefined
        },
        renderHTML: ({ height }) => {
          if (!height) return {}
          return { style: `height: ${height}px` }
        },
      },
      rowspan: {
        default: 1,
        renderHTML: ({ rowspan }) => {
          if (rowspan > 1) return { rowspan }
          return {}
        },
      },
      width: {
        default: undefined,
        parseHTML: (element) => {
          const { width } = element.style
          return width ? Number.parseInt(width, 10) : undefined
        },
        renderHTML: ({ width }) => {
          if (!width) return {}
          return { style: `width: ${width}px` }
        },
      },
      valign: {
        default: undefined,
        parseHTML: (element: HTMLElement) => {
          return element.style.verticalAlign || undefined
        },
        renderHTML: ({ valign }) => {
          if (!valign) return {}
          return { style: `vertical-align: ${valign}` }
        },
      },
    }
  },

  tableRole: 'cell',

  isolating: true,

  parseHTML() {
    return [{ tag: 'th' }, { tag: 'td' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['td', HTMLAttributes, 0]
  },
})
