/**
 * Updating the stock images on the default handbook
 * 1. Run the following query on the production database in MySQLWorkbench:
 * SELECT id, CONCAT('/images/stock/', s3_filename) AS url, IF(archived, 'true', 'false') AS archived, filesize FROM assets_view WHERE organization_id = 1029;
 * 2. Export the result to ./stockImages.json
 * 3. Replace the following
 * - "archived": "true" => "archived": true
 * - "archived": "false" => "archived": false
 * 4. Navigate to the S3 folder where the images are located: https://s3.console.aws.amazon.com/s3/buckets/blissbook-production?region=us-east-1&prefix=assets/default/images/&showversions=false
 * 5. Download all the images to /assets/images/stock
 * 6. Deploy to production
 * 7. Go to the inspirational handbook: https://default.blissbook.com/handbooks/1029/edit
 * 8. Convert all of the broken images from "Your Images" to "Stock Images"
 * 9. Publish the handbook
 *
 * To verify
 * 1. Go to a test production account: https://duxter.blissbook.com/home.
 * 2. Create a new handbook
 * 3. Initialize the handbook with inspirational content
 * 4. Go to the handbook editor and verify the content is correct.
 */
import stockImagesJSON from './stockImages.json'

export type StockImage = {
  id: number
  url: string
  filesize: number
  archived: boolean
}

export const stockImages = stockImagesJSON as StockImage[]
