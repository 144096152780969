export const label = 'Vidyard'
export const exampleUrl =
  'https://share.vidyard.com/watch/GjfwhsHUFrqq8R6DM4bVwU'

const urlRegEx = /^https:\/\/share.vidyard.com\/watch\/([A-Za-z0-9]+)\??$/i

export function fromUrl(url: string) {
  const match = url.match(urlRegEx)
  if (match)
    return {
      url: `https://share.vidyard.com/watch/${match[1]}`,
    }
}
