export type VariantType = 'group' | 'organization' | 'person' | 'savedSegment'

export type VariantJSON = {
  type: VariantType
  id: number
}

type VariantTypeMetadata = {
  abbr: string
  icon: string
}

export const variantTypeMetas: Record<VariantType, VariantTypeMetadata> = {
  organization: {
    abbr: 'O',
    icon: 'building',
  },
  group: {
    abbr: 'G',
    icon: 'users',
  },
  person: {
    abbr: 'P',
    icon: 'user',
  },
  savedSegment: {
    abbr: 'S',
    icon: 'chart-pie-simple',
  },
}

export const variantTypes = Object.keys(variantTypeMetas) as VariantType[]

export class Variant {
  static fromString(value: string) {
    // Must match regex
    const matches = value.match(/^(\w)(\d+)$/)
    if (!matches) return

    // Must have a valid type
    const abbr = matches[1]
    const type = variantTypes.find(
      (type) => variantTypeMetas[type].abbr === abbr,
    )
    if (!type) return

    // Add id
    const id = Number.parseInt(matches[2], 10)
    return new Variant(type, id)
  }

  static fromJSON(json: VariantJSON) {
    const { id, type } = json
    return new Variant(type, id)
  }

  static parse(value: string | Variant) {
    if (!value) return
    if (value instanceof Variant) return value
    return Variant.fromString(value)
  }

  // Determine if these two variants are equal
  static isEqual(v1: Variant | string, v2: Variant | string) {
    return v1.toString() === v2.toString()
  }

  // Determine if this array of variants contains the variant
  static contains(variants: Variant[], variant: Variant | string) {
    return variants.some((v) => Variant.isEqual(v, variant))
  }

  // Get the ids, with an optional type filter
  static getIds(variants: Variant[], type?: VariantType) {
    if (type) variants = variants.filter((v) => v.type === type)
    return variants.map((v) => v.id)
  }

  constructor(type: VariantType, id: number) {
    this.type = type
    this.id = id
  }

  get abbr() {
    return variantTypeMetas[this.type].abbr
  }

  get icon() {
    return variantTypeMetas[this.type].icon
  }

  get uid() {
    return this.abbr + this.id
  }

  toString() {
    return this.uid
  }

  type: VariantType
  id: number
}
