import { getContainerNodeFromPos } from '@blissbook/lib/document'
import { Popper, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import times from 'lodash/times'
import React from 'react'
import { useState } from 'react'
import { hasExtension } from '../util'
import type { ToolComponent } from './types'

type TableProps = {
  cols: number
  rows: number
}

const maxMaxCols = 20
const maxMaxRows = 20

const TableSizePicker: React.FC<{
  className?: string
  onSelect: (tableProps: TableProps) => void
}> = ({ className, onSelect, ...props }) => {
  const [cols, setCols] = useState(3)
  const [rows, setRows] = useState(3)
  const [maxCols, setMaxCols] = useState(5)
  const [maxRows, setMaxRows] = useState(5)
  return (
    <div {...props} className={cx('tw-flex tw-flex-col tw-gap-0.5', className)}>
      {times(maxRows).map((rowIndex) => (
        <div className='tw-flex tw-gap-0.5' key={rowIndex}>
          {times(maxCols).map((colIndex) => {
            const isActive = rowIndex < rows && colIndex < cols
            return (
              // biome-ignore lint/a11y/useKeyWithClickEvents: should be fixed at one point
              <div
                className={cx(
                  'tw-border tw-cursor-pointer tw-p-2',
                  isActive
                    ? 'tw-bg-aqua-100 tw-border-aqua-300'
                    : 'tw-bg-gray-100 tw-border-gray-300',
                )}
                key={colIndex}
                onClick={() => {
                  onSelect({ cols, rows })
                }}
                onMouseEnter={() => {
                  const cols = colIndex + 1
                  const rows = rowIndex + 1
                  setCols(cols)
                  setRows(rows)

                  if (cols === maxCols && cols < maxMaxCols)
                    setMaxCols(maxCols + 1)
                  if (rows === maxRows && rows < maxMaxRows)
                    setMaxRows(maxRows + 1)
                }}
              />
            )
          })}
        </div>
      ))}

      <div className='tw-flex tw-justify-center tw-mt-2'>
        {rows} x {cols}
      </div>
    </div>
  )
}

export const TableTool: ToolComponent = ({ className, editor, ...props }) => {
  const [isOpen, setOpen] = useState(false)
  const table = editor
    ? getContainerNodeFromPos(editor.state.selection.$from, 'table')
    : undefined
  const disabled = !hasExtension(editor, 'table') || !editor.can().insertTable()
  return (
    <Popper.Provider isOpen={isOpen} setOpen={setOpen}>
      <Tooltip content='Insert Table'>
        <Popper.ToggleButton
          {...props}
          className={cx('btn-tool', { active: isOpen || !!table }, className)}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={['far', 'table']} />
        </Popper.ToggleButton>
      </Tooltip>
      <Popper.Menu className='tw-p-2'>
        <TableSizePicker
          onSelect={(tableProps) => {
            editor.chain().focus().insertTable(tableProps).run()

            setOpen(false)
          }}
        />
      </Popper.Menu>
    </Popper.Provider>
  )
}
