import { latinize } from './latinize'

// Format an email to lowercase to ensure unique check pass and remove spacing
export const formatEmail = (email?: string) => email?.toLowerCase().trim()

// RegEx for validating an email address
// https://en.wikipedia.org/wiki/Email_address
// https://postmarkapp.com/support/article/1180-what-does-errorcode-300-mean
const localRegEx = /^[a-z0-9.!#$%&'`*+-/=^_{}|~]+$/i
const domainDnsRegEx = /^[a-zA-Z0-9-]+$/i

const isValidLocal = (local: string) => {
  const parts = local.split('.')
  return (
    local.length > 0 &&
    local.length <= 64 &&
    parts.every((part) => part.length > 0) &&
    localRegEx.test(local)
  )
}

const isValidDomain = (domain: string) => {
  const parts = domain.split('.')
  return (
    parts.length > 1 &&
    parts.every(
      (part) =>
        part.length > 0 && part.length < 64 && domainDnsRegEx.test(part),
    )
  )
}

export const isValidEmailAddress = (email: string) => {
  email = latinize(email)

  const parts = email.split('@')
  if (parts.length !== 2) return false

  const [local, domain] = parts
  return email.length < 255 && isValidLocal(local) && isValidDomain(domain)
}

// Helper to validate an email address
export const validateEmailAddress = (email: string) => {
  const isValid = isValidEmailAddress(email)
  if (!isValid) throw new TypeError(`Invalid email address: ${email}`)
}
