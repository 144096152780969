import type { Node } from '../types'
import { type RenderVariableInput, renderVariable } from './variable'

const newlineTypes = ['heading', 'paragraph']

export function renderText(
  content: Node[],
  variables?: RenderVariableInput<Date | string>,
) {
  const text = renderContent(content, variables)
  return text.trim()
}

function renderContent(
  content: Node[],
  variables?: RenderVariableInput<Date | string>,
) {
  return content.reduce((text, node) => {
    if ('content' in node) {
      text += renderContent(node.content, variables)
    } else if ('text' in node) {
      text += node.text
    } else if (node.type === 'hardBreak') {
      text += '\n'
    } else if (node.type === 'variable') {
      text += renderVariable(node.attrs, variables)
    }

    if (newlineTypes.includes(node.type)) {
      text += '\n'
    }

    return text
  }, '')
}
