export type AnnotationTargetType = 'document' | 'handbookSection'

export type Annotation = {
  id: string
  targetType: AnnotationTargetType
  targetId: number
  html: string
  createdAt: Date
  createdBy?: import('@blissbook/ui/application/graph').WhodunnitPerson
  publishedAt?: Date
  dismissedAt?: Date
}

export type ResolvedAnnotation = Annotation & {
  dismissedAt?: Date
}

export function formatAnnotationBookmark(annotation: Annotation) {
  const { targetType, targetId } = annotation
  if (targetType === 'handbookSection') return `section-${targetId}`
  return `${targetType}-${targetId}`
}

/** Extract all data-annotation-ids from the HTML */
export function parseAnnotationIds(html: string) {
  const annotationIds = new Set<string>()

  const matches = html.matchAll(/data-annotation-id="([^"]+)"/g)
  for (const match of matches) {
    annotationIds.add(match[1])
  }

  return [...annotationIds]
}

export function mapAnnotationTextToHtml(text: string | null) {
  if (!text) return null
  return `<p>${text.replace(/\n/g, '</p><p>').replace(/<p><\/p>/g, '<p><br></p>')}</p>`
}

export function getCommentsCount(html: string) {
  const matches = html.match(/<comment-start name="[^"]+"><\/comment-start>/g)
  return matches ? matches.length : 0
}

export function getSuggestionsCount(html: string) {
  const matches = html.match(
    /<suggestion-start name="[^"]+"><\/suggestion-start>/g,
  )
  return matches ? matches.length : 0
}
