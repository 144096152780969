import { Node } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    horizontalRule: {
      insertHorizontalRule: () => ReturnType
    }
  }
}

export const HorizontalRuleNode = Node.create({
  name: 'horizontalRule',

  group: 'block',

  addAttributes() {
    return {
      borderColor: {
        default: undefined,
        parseHTML: (element: HTMLElement) => {
          return element.style.borderColor || undefined
        },
        renderHTML: ({ borderColor }) => {
          if (!borderColor) return {}
          return { style: `border-color: ${borderColor}` }
        },
      },
    }
  },

  parseHTML() {
    return [{ tag: 'hr' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['hr', HTMLAttributes]
  },

  addCommands() {
    return {
      insertHorizontalRule:
        () =>
        ({ chain }) => {
          return chain().insertContent({ type: this.name }).run()
        },
    }
  },
})
