import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import moment from 'moment'
import type { Node, VariableAttributes } from '../types'

// Piping sort of follows Angular: https://angular.io/guide/pipes

export type RenderVariables<Value = string> = Record<string, Value>
export type RenderVariableFunction<Value = string> = (
  variable: VariableAttributes,
) => Value
export type RenderVariableInput<Value = string> =
  | RenderVariableFunction<Value>
  | RenderVariables<Value>

export function getVariableValue<Value = string>(
  variable: VariableAttributes,
  variables?: RenderVariableInput<Value>,
) {
  if (!variables) {
    return
  }
  if (isFunction(variables)) {
    return variables(variable)
  }
  return variables[variable.name]
}

function formatValue<Value = string>(value: Value, format: string) {
  if (value instanceof Date) {
    return moment(value).format(format)
  }
  return value
}

/** Render the variable, if provided, otherwise render {{variable}} */
export function renderVariable<Value = string>(
  variable: VariableAttributes,
  variables?: RenderVariableInput<Value>,
) {
  const value = getVariableValue(variable, variables)
  if (isNil(value)) return serializeVariable(variable)

  const { format } = variable
  if (!format) return value
  return formatValue(value, format)
}

/** Convert this variable node attributes into text varibale */
export function serializeVariable(variable: VariableAttributes) {
  const { format, name } = variable
  let text = name
  if (format) text += `:${format}`
  return `{{${text}}}`
}

/** Parse the text into a varible node's attributes */
export function parseVariable(text: string) {
  const [name, format] = text.split(':')
  const variable: VariableAttributes = {
    format: format ? format.trim() : undefined,
    name: name.trim(),
  }
  return variable
}

/** Replace any variable nodes that match the variable values */
export function replaceVariables(
  content: Node[],
  values: Record<string, string>,
): Node[] {
  return content.map((node) => {
    // Replace variable node with text node, if value found
    if (node.type === 'variable') {
      const { marks } = node
      const { name } = node.attrs
      const value = values[name]
      if (value !== undefined) {
        return { type: 'text', text: value, marks }
      }
    }

    // Go deeper
    if ('content' in node) {
      return {
        ...node,
        content: replaceVariables(node.content, values),
      } as Node
    }

    return node
  })
}
