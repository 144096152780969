import { Node } from '@tiptap/core'

export const TableRowNode = Node.create({
  name: 'tableRow',

  content: 'tableCell*',

  tableRole: 'row',

  parseHTML() {
    return [{ tag: 'tr' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['tr', HTMLAttributes, 0]
  },
})
