import { type VideoSource, videoSources } from './sources'

export class Video {
  source: VideoSource
  url: string
  coverUrl?: string

  static fromUrl(url: string) {
    for (const source of videoSources) {
      const video = source.fromUrl(url)
      if (video) return Object.assign(new Video(), { source, ...video })
    }
  }
}
