import type { Role } from './types'

export function getPermissionIdsFromRoles(roles: Set<Role>) {
  const permissionIds = new Set<string>()
  roles.forEach((role) => {
    role.permissionIds.forEach(permissionIds.add, permissionIds)
  })
  return Array.from(permissionIds)
}

export function mapById<T extends { id: string }>(items: T[]) {
  const map = new Map<string, T>()
  for (const item of items) {
    const { id } = item
    map.set(id, item)
  }
  return map
}
