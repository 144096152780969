import React, { forwardRef } from 'react'
import {
  ProseMirrorBubbleToolbar,
  type ProseMirrorBubbleToolbarProps,
  ToolbarButton,
} from '../../toolbar'
import { EmbedRemoveIcon, EmbedReplaceIcon } from '../embed'

export type ImageToolbarProps = ProseMirrorBubbleToolbarProps & {
  onRemove: () => void
  onReplace: () => void
}

export const ImageToolbar = forwardRef<HTMLDivElement, ImageToolbarProps>(
  ({ onRemove, onReplace, ...props }, ref) => (
    <ProseMirrorBubbleToolbar {...props} ref={ref}>
      <ToolbarButton
        label='Replace Image'
        onClick={() => {
          onReplace()
        }}
      >
        <EmbedReplaceIcon />
      </ToolbarButton>

      <ToolbarButton
        label='Remove Image'
        onClick={() => {
          onRemove()
        }}
      >
        <EmbedRemoveIcon />
      </ToolbarButton>
    </ProseMirrorBubbleToolbar>
  ),
)
