import config from '@blissbook/ui-config'
import { sanitizeUrl } from '@braintree/sanitize-url'
export { sanitizeUrl }

export const emptyUrl = 'about:blank'

const isValidProcotol = (protocol: string, validProtocols: string[]) =>
  validProtocols.map((protocol) => protocol + ':').includes(protocol)

export function isRelativeUrl(url: string) {
  return url[0] === '#' || url[0] === '/'
}

export function isValidUrl(
  value: string,
  {
    validProtocols,
  }: {
    validProtocols?: string[]
  } = {},
) {
  // Ensure the URL is valid
  try {
    const base = isRelativeUrl(value) ? config.origin : undefined
    const url = new URL(value, base)
    if (validProtocols && !isValidProcotol(url.protocol, validProtocols))
      return false
  } catch (_error) {
    return false
  }

  // Ensure the URL is sanitized
  const sanitized = sanitizeUrl(value)
  return sanitized === value
}

// HTTP: allows HTTPS and HTTP (typically for development)

export const isValidHttpUrl = (value: string) =>
  isValidUrl(value, {
    validProtocols: ['http', 'https'],
  })

// HTTPS: allow HTTPS ONLY

export const isValidHttpsUrl = (value: string) =>
  isValidUrl(value, {
    validProtocols: ['https'],
  })
