import { type History, createBrowserHistory } from 'history'
import qs from 'query-string'

// Create the history instance (browser-only)
export let history: History
if (typeof document !== 'undefined') {
  history = createBrowserHistory()

  // URL Changes
  history.listen((location, action) => {
    const { gtag, Intercom } = window as any

    // Google Analytics
    if (gtag && ['PUSH'].includes(action)) {
      gtag('send', 'pageview', location.pathname)
    }

    // Intercom
    if (Intercom && ['POP', 'PUSH'].includes(action)) {
      Intercom('update')
    }
  })
}

// Trim the hash value
export const trimHash = (hash: string | undefined) => {
  if (!hash) return
  if (hash[0] === '#') hash = hash.substring(1)
  return hash
}

export type ReplaceHashOptions = {
  setQuery?: boolean
}

// Replace the current hash
export function replaceHash(
  hash: string | undefined,
  options: ReplaceHashOptions = {},
) {
  if (hash === window.location.hash) return

  // Update the hash always
  const location = { ...window.location, hash }

  // Update a query parameter to survive authentication redirects?
  if (options.setQuery) {
    const query = qs.parse(location.search)
    query.anchor = trimHash(hash)
    location.search = qs.stringify(query)
  }

  // njsscan-ignore: node_timing_attack
  history.replace(location)
}

// Update the current query params
export const patchQuery = (changes: Record<string, any>) => {
  const { location } = history
  const prevQuery = qs.parse(location.search)
  return setQuery({ ...prevQuery, ...changes })
}

// Set the current query params
export const setQuery = (query: Record<string, any> = {}) => {
  const { location } = history
  const search = qs.stringify(query)
  history.replace({ ...location, search })
}
