import { type TeamsQuery, useTeamsQuery } from '@blissbook/ui/application/graph'
import { useMemo } from 'react'

type Team = TeamsQuery['teams'][0]

/** Hook to get all of the available teams */
export function useTeams(): Team[] {
  const { data } = useTeamsQuery()
  if (!data) return []
  return data.teams
}

/** Hooks to get the only active team */
export function useTeam(): Team {
  const teams = useTeams()
  return useMemo(() => teams[0], [teams])
}

/** Hook to get a team by id */
export function useTeamById(teamId: number): Team {
  const teams = useTeams()
  return useMemo(
    () => teams.find((team) => team.id === teamId),
    [teams, teamId],
  )
}
