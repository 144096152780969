import { Shape } from '@blissbook/ui/lib/shape'
import React from 'react'

const defaultAlignX = 'left'
const defaultAlignY = 'top'

export const gridShapeSize = 120

export const ShapeGrid = React.forwardRef<HTMLDivElement, ShapeGridProps>(
  (
    {
      alignX = defaultAlignX, // left or right
      alignY = defaultAlignY, // top or bottom
      shapeDefaults,
      shapeSize = gridShapeSize,
      shapes,
      ...props
    },
    ref,
  ) => (
    <div css={{ position: 'relative' }} ref={ref} {...props}>
      {shapes.map((shape, index) => {
        const { x, y, style, ...shapeProps } = shape
        return (
          <Shape
            {...shapeDefaults}
            {...shapeProps}
            size={shapeSize}
            key={index}
            css={{ position: 'absolute' }}
            style={{
              ...style,
              [alignY]: y * shapeSize,
              [alignX]: x * shapeSize,
            }}
          />
        )
      })}
    </div>
  ),
)

interface ShapeProps {
  x: number
  y: number
  className?: string
  color?: string
  fill?: boolean
  rotate?: number
  style?: React.CSSProperties
  type?: string
}

interface ShapeGridProps {
  alignX?: 'left' | 'right'
  alignY?: 'top' | 'bottom'
  className?: string
  shapeDefaults?: Partial<ShapeProps>
  shapeSize?: number
  shapes: ShapeProps[]
  style?: React.CSSProperties
}

export const BackgroundShapeGrid: React.FC<ShapeGridProps> = (props) => {
  const { alignX = defaultAlignX, alignY = defaultAlignY } = props

  return (
    <ShapeGrid
      {...props}
      css={{
        position: 'absolute',
        [alignX]: 0,
        [alignY]: 0,
        zIndex: -1,
      }}
    />
  )
}
