import type { NodeWithPos, Range } from '@tiptap/core'
import type { Node as ProseMirrorNode } from 'prosemirror-model'

export const indentNodeTypes = ['heading', 'listItem', 'paragraph']

export const minIndent = 0
export const maxIndent = 9

export const boundIndent = (indent: number) => {
  indent = Math.max(indent, minIndent)
  indent = Math.min(indent, maxIndent)
  if (indent > 0) return indent
}

export function getIndentNodes(doc: ProseMirrorNode, range?: Range) {
  const nodes: NodeWithPos[] = []

  doc.descendants((node, pos) => {
    const from = pos
    const to = from + node.nodeSize
    const isInRange = !range || (to > range.from && from < range.to)

    if (isInRange && indentNodeTypes.includes(node.type.name)) {
      nodes.push({ node, pos })
      return false
    }
  })

  return nodes
}

export function getIndentClassName(indent?: number) {
  return indent ? `bb-indent-${indent}` : undefined
}
