import { posToDOMRect } from '@tiptap/core'
import type { TextSelection } from 'prosemirror-state'
import React, { useMemo } from 'react'
import type { Editor } from '../../editor'
import { ProseMirrorBubbleToolbar, ToolbarDivider } from '../../toolbar'
import {
  BoldTool,
  ClearFormatsTool,
  ColorTool,
  ItalicTool,
  LinkTool,
  UnderlineTool,
  WrapInReadMoreTool,
} from '../../tools'
import type { TextSelectionViewProps } from './view'

export const createVirtualTextSelectionElement = (
  editor: Editor,
  selection: TextSelection,
) => ({
  getBoundingClientRect() {
    const { view } = editor
    const { from, to } = selection
    return posToDOMRect(view, from, to)
  },
})

export const TextSelectionToolbar = ({
  selection,
  ...props
}: TextSelectionViewProps) => {
  const { editor } = props
  const virtualEl = useMemo(
    () => createVirtualTextSelectionElement(editor, selection),
    [editor.view, selection],
  )

  const canWrapInLink = editor.hasExtension('link')
  const canWrapInReadMore =
    editor.hasExtension('readMore') && editor.can().wrapInReadMore()
  const canWrap = canWrapInLink || canWrapInReadMore

  return (
    <ProseMirrorBubbleToolbar
      {...props}
      offset={4}
      onMouseDown={(event) => {
        const { target } = event
        const isInput =
          target instanceof HTMLInputElement ||
          target instanceof HTMLTextAreaElement
        if (!isInput) {
          event.preventDefault()
        }
      }}
      refEl={virtualEl}
    >
      <BoldTool editor={editor} />
      <ItalicTool editor={editor} />
      <UnderlineTool editor={editor} />
      <ColorTool editor={editor} />
      <ClearFormatsTool editor={editor} />

      <If condition={canWrap}>
        <ToolbarDivider />

        <If condition={canWrapInLink}>
          <LinkTool editor={editor} />
        </If>

        <If condition={canWrapInReadMore}>
          <WrapInReadMoreTool editor={editor} />
        </If>
      </If>
    </ProseMirrorBubbleToolbar>
  )
}
