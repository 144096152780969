import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type DocumentStatus = {
  key: string
  className: string
  icon: IconProp
  label: string
}

export const draftingStatus: DocumentStatus = {
  key: 'drafting',
  className: 'tw-text-gray-500',
  icon: ['far', 'circle-half-stroke'],
  label: 'Drafting',
}

export const cleanStatus: DocumentStatus = {
  key: 'clean',
  className: 'tw-text-gray-500',
  icon: ['far', 'circle'],
  label: 'Clean',
}

export const documentStatuses = [draftingStatus, cleanStatus]

export function getDocumentStatus(document: {
  draftVersion?: {
    isDirty: boolean
  }
}): DocumentStatus {
  const { isDirty } = document.draftVersion
  return isDirty ? draftingStatus : cleanStatus
}
