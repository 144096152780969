import { Mark } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: string) => ReturnType
    }
  }
}

export const FontSizeMark = Mark.create({
  name: 'fontSize',

  addAttributes() {
    return {
      fontSize: {
        default: undefined,
        renderHTML: ({ fontSize }) => {
          if (!fontSize) return {}
          return { style: `font-size: ${fontSize}` }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        style: 'font-size',
        getAttrs: (fontSize) => {
          if (!fontSize) return false
          return { fontSize }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ commands }) => {
          return commands.setMark('fontSize', { fontSize })
        },
    }
  },
})
