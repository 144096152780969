import { useQueryParam } from '@blissbook/ui/hooks/useQueryParam'
import { patchQuery } from '@blissbook/ui/util/history'
import { useMemo } from 'react'
import { generatePath, matchPath } from 'react-router'

// Modals by id
const queryKey = 'drawer'
const separator = '~'

// Hook to determine to open modalId
export function useModalId() {
  const [modalId] = useQueryParam(queryKey)
  return modalId
}

// Set the modalId
export function setModalId(modalId: string) {
  return patchQuery({ [queryKey]: modalId })
}

// Convert the modal id to a routable path
export function getModalPath(modalId: string) {
  if (!modalId) return
  return '/' + modalId.replace(new RegExp(separator, 'g'), '/')
}

// Attempt to match this modal path
export function matchModalId(modalId: string, props: any) {
  const modalPath = getModalPath(modalId)
  return matchPath(modalPath, props)
}

// Hook to try and match the modal path
export function useModalMatch(props: any) {
  const modalId = useModalId()
  return useMemo(() => matchModalId(modalId, props), [modalId])
}

// Generate a modal id from the path / params
export function generateModalId(path: string, params: any) {
  return generatePath(path, params).substr(1).replace(/\//g, separator)
}
