import React from 'react'
import { useInfiniteContext } from '..'
import { InfiniteLoading, type InfiniteLoadingProps } from './InfiniteLoading'

export type InfiniteContextLoadingProps = Omit<
  InfiniteLoadingProps,
  'isLoading'
>

export function InfiniteContextLoading(props: InfiniteContextLoadingProps) {
  const infinite = useInfiniteContext()
  if (!infinite) return null
  return <InfiniteLoading {...props} isLoading={infinite.isLoading} />
}
