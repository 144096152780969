import type { Attributes } from '@tiptap/core'
import { idNanoid } from '../../id'

export const idAttributes: Attributes = {
  id: {
    default: undefined,
    keepOnSplit: false,
    parseHTML: (element: HTMLElement) => {
      return element.id || idNanoid()
    },
  },
}
