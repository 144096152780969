import { isCellSelection } from '@blissbook/lib/document'
import type { NodeWithPos } from '@tiptap/core'
import type { EditorState } from 'prosemirror-state'
import type { CellSelection } from 'prosemirror-tables'

export function isCellSelectionInTable(
  selection: CellSelection,
  table: NodeWithPos,
) {
  const from = table.pos
  const to = table.pos + table.node.nodeSize
  return selection.ranges.every(
    (range) => range.$from.pos > from && range.$to.pos < to,
  )
}

export function getCellSelection(state: EditorState, table?: NodeWithPos) {
  const { selection } = state
  if (!isCellSelection(selection)) return
  if (table && !isCellSelectionInTable(selection, table)) return
  return selection
}
