import { formatMarkTypes } from '@blissbook/lib/document'
import { Extension } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    editor: {
      clearFormats: () => ReturnType
    }
  }
}

export const EditorExtension = Extension.create({
  name: 'editor',

  addCommands() {
    return {
      clearFormats:
        () =>
        ({ dispatch, state, tr }) => {
          const { selection } = tr
          const { empty, ranges } = selection
          if (empty) return true

          if (dispatch) {
            Object.values(state.schema.marks)
              .filter((type) => formatMarkTypes.includes(type.name))
              .forEach((markType) => {
                ranges.forEach((range) => {
                  tr.removeMark(range.$from.pos, range.$to.pos, markType)
                })
              })
          }

          return true
        },
    }
  },
})
