import {
  type ResizeOptions,
  bindResize,
  bottomSide,
  rightSide,
} from '@blissbook/ui/editor/resize'

const edges = [rightSide, bottomSide]

export const bindTableCellResize = (
  embedEl: HTMLElement,
  handlers: Omit<ResizeOptions, 'edges'>,
) =>
  bindResize(embedEl, {
    ...handlers,
    edges,
  })
