import { handleError } from '@blissbook/ui/util/errors'
import { setToast } from '@blissbook/ui/util/toaster'
import { cx } from '@emotion/css'
import type { Placement } from '@floating-ui/react-dom-interactions'
import copy from 'clipboard-copy'
import React from 'react'
import { type CSSProperties, useState } from 'react'
import { Tooltip } from './tooltip'

export function ClipboardText({
  className,
  placement = 'top',
  tagName = 'span',
  value,
  disableTextHighlight = false,
  ...props
}: {
  children?: React.ReactNode
  className?: string
  placement?: Placement
  style?: CSSProperties
  tagName?: string
  value?: string
  disableTextHighlight?: boolean
}) {
  const [node, setNode] = useState<HTMLElement>()
  const TagName = tagName
  return (
    <Tooltip content='Click to Copy' placement={placement}>
      <TagName
        {...props}
        // @ts-ignore: TagName is a valid HTML element
        className={cx('tw-cursor-pointer', className)}
        // @ts-ignore: TagName is a valid HTML element
        onClick={async () => {
          // Copy to clipboard
          try {
            await copy(value || node.innerText)
            setToast('Copied to clipboard', { type: 'success' })
            if (!disableTextHighlight) {
              window.getSelection().selectAllChildren(node)
            }
          } catch (error) {
            handleError(error, 'Error copying to clipboard')
          }
        }}
        ref={setNode}
      />
    </Tooltip>
  )
}
