import { stockImages } from './stock'

const backgroundImagesIds = [382, 7227, 7283, 7287, 7290, 7291, 7296, 7299]

export const backgroundImages = stockImages
  .filter((s) => backgroundImagesIds.includes(s.id))
  .map((s) => s.url)

export const getRandomBackgroundImage = () => {
  const index = Math.floor(Math.random() * backgroundImages.length)
  return backgroundImages[index]
}
