import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import React, {
  type CSSProperties,
  type ReactNode,
  useMemo,
  useState,
} from 'react'
import { InfiniteContextLoading, InfiniteContextWaypoint } from '../infinite'
import { SortHeader, type SortOrder } from '../sort'
import type { TableColumn } from './types'

type GetRowPropsFunction = (row: any) => any
type OnChangeOrderFunction = (order: SortOrder) => void
type TableRowData = Record<string, any>
type RowKeyFunction = (row: TableRowData) => React.Key
type RowKeyInput = RowKeyFunction | React.Key

function calcProps<Props>(
  props: Props,
  getProps: (props: Props) => Props,
): Props {
  return !getProps ? props : { ...props, ...getProps(props) }
}

type TableHeaderProps = {
  className?: string
  columns: TableColumn[]
  onChangeOrder?: OnChangeOrderFunction
  order?: SortOrder
  style?: CSSProperties
}

function TableHeaderComponent({
  className,
  style,
  ...rowProps
}: TableHeaderProps) {
  return (
    <div className={cx('table-header', className)} style={style}>
      <TableRow {...rowProps} CellComponent={TableHeadingCell} />
    </div>
  )
}

const TableHeader = React.memo(TableHeaderComponent)

type TableHeadingCellProps = {
  column: TableColumn
  onChangeOrder?: OnChangeOrderFunction
  order?: SortOrder
}

function TableHeadingCell({
  onChangeOrder,
  order,
  ...props
}: TableHeadingCellProps) {
  const { column } = props

  // Sortable?
  const cellProps = !column.order
    ? undefined
    : {
        Component: SortHeader,
        columnOrder: column.order,
        onChangeOrder,
        order,
      }

  return (
    <TableCell
      {...cellProps}
      className={cx('table-heading', column.headerClassName)}
      column={column}
    >
      {renderCell(column.Header, props)}
    </TableCell>
  )
}

// Determine the key for this row
function getRowKey(row: TableRowData, rowKey: RowKeyInput = 'id') {
  return isFunction(rowKey) ? rowKey(row) : row[rowKey]
}

type TableBodyProps = {
  columns: TableColumn[]
  getRowProps?: GetRowPropsFunction
  rowKey: RowKeyInput
  rows: TableRowData[]
  selectedRowKey?: number | string
}

function TableBody({
  rowKey,
  rows,
  selectedRowKey,
  ...rowProps
}: TableBodyProps) {
  return (
    <div className='table-body'>
      {rows.map((row, rowIndex) => {
        const key = getRowKey(row, rowKey) || rowIndex
        return (
          <TableRow
            {...rowProps}
            key={key}
            CellComponent={TableBodyCell}
            row={row}
            selected={key === selectedRowKey}
          />
        )
      })}

      <InfiniteContextLoading className='table-cell'>
        Loading
      </InfiniteContextLoading>

      <InfiniteContextWaypoint />
    </div>
  )
}

type TableBodyCellProps = {
  cellProps?: any
  column: TableColumn
}

function TableBodyCell(props: TableBodyCellProps) {
  const { column } = props
  return (
    <TableCell
      className={cx('table-cell', column.cellClassName)}
      column={column}
    >
      {renderCell(column.Cell, props)}
    </TableCell>
  )
}

type TableRowProps = {
  CellComponent: any
  className?: string
  columns: TableColumn[]
  css?: CSSProperties
  getRowProps?: GetRowPropsFunction
  onClickRow?: (row: TableRowData) => void
  row?: TableRowData
  selected?: boolean
  style?: CSSProperties
}

function TableRowComponent({ getRowProps, ...props }: TableRowProps) {
  const {
    CellComponent,
    className,
    columns,
    css,
    onClickRow,
    selected,
    style,
    ...cellProps
  } = calcProps<TableRowProps>(props, getRowProps)

  const { row } = props
  const canClickRow = Boolean(onClickRow && row)
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: should fix
    <div
      className={cx('table-row tw-group', { active: selected }, className)}
      css={{
        cursor: canClickRow ? 'pointer' : undefined,
        ...css,
      }}
      onClick={() => {
        if (canClickRow) onClickRow(row)
      }}
      style={style}
    >
      {columns.map((column, columnIndex) => (
        <CellComponent {...cellProps} key={columnIndex} column={column} />
      ))}

      <If condition={onClickRow !== undefined}>
        <CellComponent {...cellProps} column={clickRowColumn} />
      </If>
    </div>
  )
}

const TableRow = React.memo(TableRowComponent)

const renderCell = (Component: any, props: any) => (
  <Choose>
    <When condition={!Component} />
    <When condition={isString(Component)}>{Component}</When>
    <Otherwise>
      <Component {...props} />
    </Otherwise>
  </Choose>
)

type TableCellProps = {
  Component?: any
  children?: ReactNode
  className?: string
  column: TableColumn
}

function TableCell({
  Component = 'div',
  className,
  column,
  column: { flex = 1, maxWidth, minWidth = 0, width },
  ...props
}: TableCellProps) {
  return (
    <Component
      {...props}
      className={cx(className, column.className)}
      style={{
        flex: width ? undefined : flex,
        maxWidth,
        minWidth,
        width,
      }}
    />
  )
}

export type TableProps = {
  className?: string
  columns: TableColumn[]
  getRowProps?: GetRowPropsFunction
  onChangeOrder?: OnChangeOrderFunction
  onClickRow?: (row: TableRowData) => void
  order?: SortOrder
  selectedRowKey?: number | string
  rowKey?: RowKeyInput
  rows: TableRowData[]
  style?: CSSProperties
}

function TableComponent({
  className,
  getRowProps,
  onChangeOrder,
  order,
  rowKey,
  rows,
  selectedRowKey,
  style,
  ...props
}: TableProps) {
  const { columns } = props
  const hasHeader = useMemo(() => columns.some((col) => col.Header), [columns])

  return (
    <div className={cx('table', className)} style={style}>
      <If condition={hasHeader}>
        <TableHeader
          {...props}
          columns={columns}
          onChangeOrder={onChangeOrder}
          order={order}
        />
      </If>

      <TableBody
        {...props}
        getRowProps={getRowProps}
        rowKey={rowKey}
        rows={rows}
        selectedRowKey={selectedRowKey}
      />
    </div>
  )
}

export const Table = React.memo(TableComponent)

const clickRowColumn = {
  minWidth: 24,
  width: 24,
  cellClassName: 'tw-text-gray-300',
  Cell: () => (
    <FontAwesomeIcon css={{ fontSize: 12 }} icon={['far', 'chevron-right']} />
  ),
}
