import classnames from 'classnames'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as yup from 'yup'
import { TextInput } from './TextInput'
import { Textarea } from './Textarea'

// Input types
const INPUTS_BY_TYPE = {
  email: {
    Component: TextInput,
    schema: yup.string().email(),
    submitOnBlur: true,
    submitOnEnter: true,
  },
  number: {
    Component: TextInput,
    schema: yup.number(),
    submitOnBlur: true,
    submitOnEnter: true,
  },
  text: {
    Component: TextInput,
    schema: yup.string(),
    submitOnBlur: true,
    submitOnEnter: true,
  },
  textarea: {
    Component: Textarea,
    schema: yup.string(),
    submitOnBlur: true,
  },
}
const getInputByType = (type) => INPUTS_BY_TYPE[type] || INPUTS_BY_TYPE.text

export const useValue = (propValue) => {
  const lastPropValueRef = useRef(propValue)
  const resetRef = useRef()
  const [value, setValue] = useState(propValue)

  // Determine if the incoming propValue changed
  const hasChanged = propValue !== lastPropValueRef.current
  lastPropValueRef.current = propValue

  // If the incoming propValue changed and isn't the current value, reset
  if (hasChanged && propValue !== value) {
    resetRef.current = new Date()
    setValue(propValue)
  }

  return [value, setValue, resetRef.current]
}

const useDebouncedCallback = (callback, wait, deps) => {
  const callbackRef = useRef()
  const debouncedRef = useRef()
  const [count, setCount] = useState(0)

  // Remember the latest callback.
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // Set up the debounce.
  useEffect(() => {
    const fn = (...args) => callbackRef.current(...args)
    debouncedRef.current = debounce(fn, wait)
    setCount(count + 1)
    return () => debouncedRef.current.cancel()
  }, [wait, ...deps])

  return debouncedRef.current
}

export const Input = React.forwardRef(
  (
    {
      className,
      debounce,
      initialValue,
      onBlur,
      onChange,
      onChangeValue,
      onEnterKey,
      onKeyDown,
      onSubmit,
      onTabKey,
      required,
      ...props
    },
    ref,
  ) => {
    // Current value
    const [value, setValue, resetAt] = useValue(props.value)

    // Get the type of input
    const { type } = props
    const { Component, schema, submitOnBlur, submitOnEnter } = useMemo(
      () => getInputByType(type),
      [type],
    )

    // Get the callback to determine if a value is valid
    const getValid = useCallback(
      (value) => (!value ? !required : schema.isValidSync(value)),
      [required, schema],
    )
    const isValid = getValid(value)

    // Only trigger changes if the value has actually changed
    const handleChangeValue = useCallback((value) => {
      const prevValue = initialValue === undefined ? props.value : initialValue
      const isValid = getValid(value)
      const isChanged = value !== prevValue
      if (isValid && isChanged && onChangeValue) onChangeValue(value)
    })

    // Debounced changes
    const handleChangeValueDebounced = useDebouncedCallback(
      handleChangeValue,
      debounce,
      [resetAt],
    )

    // Handle submits
    const handleSubmit = useCallback((value) => {
      const isValid = getValid(value)
      if (!isValid) return

      handleChangeValueDebounced.cancel()
      handleChangeValue(value)
      if (onSubmit) onSubmit(value)
    })

    // Handle blur
    const handleBlur = useCallback((event) => {
      if (onBlur) onBlur(event)
      if (event.defaultPrevented) return

      if (submitOnBlur) handleSubmit(value)
    })

    // Handle when the value has changed
    const handleChange = useCallback((event, value) => {
      if (onChange) onChange(event, value)
      setValue(value)
      handleChangeValueDebounced(value)
    })

    // Handle the enter key
    const handleEnterKey = useCallback((event) => {
      if (onEnterKey) onEnterKey(event)
      if (event.defaultPrevented) return // For SearchInput dropdown

      if (submitOnEnter) {
        event.preventDefault()
        handleSubmit(value)
      }
    })

    // Handle the tab key
    const handleTabKey = useCallback((event) => {
      if (onTabKey) onTabKey(event)
    })

    // Handle a keydown event
    const handleKeyDown = useCallback((event) => {
      if (onKeyDown) onKeyDown(event)
      if (event.defaultPrevented) return

      // Custom handlers
      if (event.keyCode === 9) handleTabKey(event)
      if (event.keyCode === 13) handleEnterKey(event)
    })

    return (
      <Component
        {...props}
        className={classnames(className, { 'has-error': isValid === false })}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        value={value}
        data-1p-ignore={type !== 'password'}
      />
    )
  },
)
