import config from '@blissbook/ui-config'
import React from 'react'

export const Environment = () => {
  if (config.env.production) {
    return null
  }

  return (
    <div className='tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-h-1 tw-bg-red-500 tw-z-10' />
  )
}
