import { type Node as ProseMirrorNode, Slice } from 'prosemirror-model'
import type { Transaction } from 'prosemirror-state'
import { ReplaceAroundStep } from 'prosemirror-transform'

export const unwrapContainer = (
  tr: Transaction,
  node: ProseMirrorNode,
  pos: number,
) => {
  try {
    const start = pos
    const end = pos + node.nodeSize

    tr.step(
      new ReplaceAroundStep(
        start,
        end,
        start + 1,
        end - 1,
        Slice.empty,
        0,
        true,
      ),
    )
  } catch (e) {
    console.error('Error unwrapping container', e)
  }
}
