import './tabs.scss'
import { Link } from '@blissbook/ui/lib'
import classnames from 'classnames'
import React, { forwardRef, useRef } from 'react'
import { ScrollContainer } from '../scroll'

export const TabsNav = ({ className, size, ...props }) => (
  <ul
    {...props}
    className={classnames(
      'nav nav-tabs',
      size && `nav-tabs-${size}`,
      className,
    )}
  />
)

TabsNav.Item = forwardRef(
  (
    { active, children, className, disabled, href, onClick, to, ...props },
    ref,
  ) => (
    <li
      {...props}
      className={classnames('nav-item', { active, disabled }, className)}
      ref={ref}
    >
      <Link
        children={children}
        className='nav-link'
        href={href}
        onClick={onClick}
        to={to}
      />
    </li>
  ),
)

export const Tabs = ({
  activeId,
  children,
  className,
  onSelectId,
  ...props
}) => {
  const tabs = React.Children.toArray(children)
  const activeTab = tabs.find((tab) => tab.props.id === activeId) || tabs[0]
  return (
    <div {...props} className={classnames('tabs', className)}>
      <ul className='nav nav-tabs'>
        {tabs.map((tab) => {
          const { className, disabled, id, label } = tab.props
          return (
            <li
              key={id}
              className={classnames('nav-item', className, {
                active: tab === activeTab,
                disabled,
              })}
            >
              <Link
                children={label}
                className='nav-link'
                onClick={() => !disabled && onSelectId(id)}
              />
            </li>
          )
        })}
      </ul>

      {tabs.map((tab) => {
        const { forceRender } = tab.props
        const active = tab === activeTab
        if (!active && !forceRender) return null
        return React.cloneElement(tab, {
          active,
          disable: undefined,
          id: undefined,
          label: undefined,
        })
      })}
    </div>
  )
}

Tabs.Item = React.memo(
  ({ active, className, innerClassName, style = {}, ...props }) => {
    const activatedRef = useRef(false)
    activatedRef.current = activatedRef.current || active

    return (
      <If condition={activatedRef.current}>
        <ScrollContainer
          {...props}
          className={classnames('tab-content', className)}
          innerClassName={classnames('tab-content-scroll', innerClassName)}
          showScrollText
          style={{
            ...style,
            display: active ? style.display : 'none',
          }}
        />
      </If>
    )
  },
)
