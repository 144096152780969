import config from '@blissbook/ui-config'
import { colors } from '@blissbook/ui/branding'
import { css } from '@emotion/react'
import { useField } from 'formik'
import React from 'react'
import Recaptcha from 'react-google-recaptcha'
const { siteKey } = config.recaptcha

export const Field = ({ name }) => {
  const [, { error, touched }, { setValue }] = useField(name)
  const showError = touched && error
  return (
    <div className='form-group'>
      <Recaptcha
        css={css`
          > * > * {
            border: 1px solid ${showError ? colors['red-500'] : 'transparent'};
            border-radius: 3px;
          }
        `}
        sitekey={siteKey}
        onChange={(value) => setValue(value)}
      />
    </div>
  )
}
