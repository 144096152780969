import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isValidEmailAddress } from './email'
import { emptyUrl, isRelativeUrl, sanitizeUrl } from './url'

export type HrefProtocol = {
  icon: IconProp
  value: string
}

const fileProtocol: HrefProtocol = {
  icon: ['fad', 'file-export'],
  value: 'file:',
}

const ftpProtocol: HrefProtocol = {
  ...fileProtocol,
  value: 'ftp:',
}

const httpProtocol: HrefProtocol = {
  icon: ['fad', 'arrow-up-right-from-square'],
  value: 'http:',
}

const httpsProtocol: HrefProtocol = {
  ...httpProtocol,
  value: 'https:',
}

const mailtoProtocol: HrefProtocol = {
  icon: ['fad', 'envelope'],
  value: 'mailto:',
}

const phoneProtocol: HrefProtocol = {
  icon: ['fad', 'phone'],
  value: 'tel:',
}

const httpProtocols = [httpProtocol, httpsProtocol]

export const hrefProtocols: HrefProtocol[] = [
  ...httpProtocols,
  fileProtocol,
  ftpProtocol,
  mailtoProtocol,
  phoneProtocol,
]

export function getHrefProtocol(href: string) {
  try {
    const url = new URL(href)
    return hrefProtocols.find((p) => p.value === url.protocol)
  } catch (_error) {
    return undefined
  }
}

const filePrefix = 'file:///'
const fileRegEx = /^\w:\\/
const httpPrefix = 'http://'

function isValidHrefUrl(url: URL) {
  return hrefProtocols.some((p) => p.value === url.protocol)
}

function formatHrefUrl(href?: string) {
  if (!href) return emptyUrl

  // Email address?
  if (isValidEmailAddress(href) && href.indexOf(mailtoProtocol.value) !== 0) {
    return mailtoProtocol.value + href
  }

  // File?
  if (fileRegEx.test(href)) {
    return filePrefix + href
  }

  // Relative URL
  if (isRelativeUrl(href)) return href

  // Check for valid URL
  try {
    const url = new URL(href)
    return isValidHrefUrl(url) ? href : emptyUrl
  } catch (_error) {
    // Ignore
  }

  // Can we make a URL by adding a prefix?
  try {
    const prefixedHref = httpPrefix + href
    const prefixedUrl = new URL(prefixedHref)
    return isValidHrefUrl(prefixedUrl) ? prefixedHref : emptyUrl
  } catch (_error) {
    // Ignore
  }

  return emptyUrl
}

export function formatHref(href: string) {
  const hrefUrl = formatHrefUrl(href)
  return sanitizeUrl(hrefUrl)
}
