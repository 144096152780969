import isString from 'lodash/isString'
import {
  Mark as ProseMirrorMark,
  Node as ProseMirrorNode,
  type Schema as ProseMirrorSchema,
} from 'prosemirror-model'
import { type Mark, reduceNode } from './types'

export class DocumentBuilder {
  readonly schema: ProseMirrorSchema

  constructor(schema: ProseMirrorSchema) {
    this.schema = schema
  }

  br = (...args: any[]) => {
    return this.createNode('hardBreak', args)
  }

  doc(...nodes: ProseMirrorNode[]) {
    return nodes.map((node) => reduceNode(node.toJSON()))
  }

  hr = (...args: any[]) => {
    return this.createNode('horizontalRule', args)
  }

  image = (...args: any[]) => {
    return this.createNode('image', args)
  }

  li = (...args: any[]) => {
    return this.createNode('listItem', args)
  }

  ol = (...args: any[]) => {
    return this.createNode('orderedList', args)
  }

  p = (...args: any[]) => {
    return this.createNode('paragraph', args)
  }

  pdf = (...args: any[]) => {
    return this.createNode('pdf', args)
  }

  readMore = (...args: any[]) => {
    return this.createNode('readMore', args)
  }

  table = (...args: any[]) => {
    return this.createNode('table', args)
  }

  td = (...args: any[]) => {
    return this.createNode('tableCell', args)
  }

  text = (text: string, marks: readonly Mark[] = []) => {
    const pmMarks = marks.map((json) =>
      ProseMirrorMark.fromJSON(this.schema, json),
    )
    return this.schema.text(text, pmMarks)
  }

  tr = (...args: any[]) => {
    return this.createNode('tableRow', args)
  }

  ul = (...args: any[]) => {
    return this.createNode('bulletList', args)
  }

  variable = (...args: any[]) => {
    return this.createNode('variable', args)
  }

  video = (...args: any[]) => {
    return this.createNode('video', args)
  }

  private createNode(typeName: string, args: any[]) {
    const type = this.schema.nodes[typeName]
    let [attrs, ...content] = args

    if (attrs instanceof ProseMirrorNode || isString(attrs)) {
      content.unshift(attrs)
      attrs = {}
    }

    content = content.map((item) => {
      if (isString(item)) {
        return this.schema.text(item)
      }
      return item
    })

    return type.create(attrs, content)
  }
}
