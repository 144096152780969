import { embedShapes } from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { NodeSelectionToolbarProps } from '../../../selection/node'
import { ToolbarButton } from '../../../toolbar'

export const EmbedNodeSelectionShapeTool = ({
  selection,
  updateNodeAttributes,
}: NodeSelectionToolbarProps) => {
  const { node } = selection
  return (
    <>
      {embedShapes.map((shape) => {
        const active = shape.type === node.attrs.shape
        return (
          <ToolbarButton
            active={active}
            key={shape.type}
            label={shape.label}
            onClick={async () => {
              const value = active ? undefined : shape.type
              updateNodeAttributes({ shape: value })
            }}
          >
            <FontAwesomeIcon icon={shape.icon} />
          </ToolbarButton>
        )
      })}
    </>
  )
}
