import { headingTagNames, listTagNames, textBlockTagNames } from '../lib'

function allowAdjacentElements(beforeEl: Element, afterEl: Element) {
  const beforeTagName = beforeEl.tagName
  const afterTagName = afterEl.tagName

  return (
    headingTagNames.includes(beforeTagName) ||
    headingTagNames.includes(afterTagName) ||
    (listTagNames.includes(beforeTagName) &&
      listTagNames.includes(afterTagName))
  )
}

function isElementEmpty(el: Element) {
  if (!textBlockTagNames.includes(el.tagName)) {
    return false
  }

  return el.textContent.trim().length === 0
}

export function normalizeSpacing(document: Document) {
  const { body } = document

  // Trim at the top
  while (body.firstElementChild && isElementEmpty(body.firstElementChild)) {
    body.removeChild(body.firstElementChild)
  }

  // Trim at the bottom
  while (body.lastElementChild && isElementEmpty(body.lastElementChild)) {
    body.removeChild(body.lastElementChild)
  }

  // Trim below
  let childEl = body.firstElementChild
  while (childEl) {
    const nextEl = childEl.nextElementSibling
    if (!nextEl) break

    const isEmpty = isElementEmpty(childEl)
    const isNextEmpty = isElementEmpty(nextEl)

    if (isEmpty && isNextEmpty) {
      body.removeChild(nextEl)
    } else if (
      !isEmpty &&
      !isNextEmpty &&
      !allowAdjacentElements(childEl, nextEl)
    ) {
      const emptyParagraph = document.createElement('p')
      body.insertBefore(emptyParagraph, nextEl)
      childEl = nextEl
    } else {
      childEl = childEl.nextElementSibling
    }
  }
}
