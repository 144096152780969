import { useTransitionState } from '@blissbook/ui/util/hooks'
import { cx } from '@emotion/css'
import React from 'react'

export type DrawerLayoutProps = {
  children: React.ReactNode
  className?: string
  drawerEl?: JSX.Element
  drawerWidth?: number
}

export function DrawerLayout({
  children,
  className,
  drawerEl,
  drawerWidth = 550,
  ...props
}: DrawerLayoutProps) {
  const [currDrawerEl, onTransitionEnd] = useTransitionState(
    drawerEl,
    !!drawerEl,
  )
  return (
    <div {...props} className={cx('tw-flex tw-relative', className)}>
      <div className='tw-flex-1 tw-min-h-0 tw-overflow-x-hidden tw-overflow-y-auto'>
        {children}
      </div>

      <div
        className='tw-transition-all tw-ease-in-out tw-duration-500'
        onTransitionEnd={onTransitionEnd}
        style={{
          width: drawerEl ? drawerWidth : 0,
        }}
      >
        <div
          className='tw-h-full tw-border-l-2 tw-border-gray-200 tw-overflow-x-hidden tw-overflow-y-auto'
          style={{ width: drawerWidth }}
        >
          {currDrawerEl}
        </div>
      </div>
    </div>
  )
}
