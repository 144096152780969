import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { Dropdown } from '../popper'
import {
  //@ts-ignore WIP import
  ScrollContainer,
} from '../scroll'
import type { DropdownInputProps } from './dateInput'

interface YearDropdownInputProps extends DropdownInputProps<number> {
  startYear?: number
  endYear?: number
}

export const YearDropdownInput: React.FC<YearDropdownInputProps> = ({
  onChange,
  value,
  startYear = 2012,
  ...props
}) => {
  const endYear = new Date().getUTCFullYear()
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i,
  ).reverse()

  return (
    <Dropdown.Provider {...props}>
      <Dropdown.ToggleButton
        className={`btn btn-input tw-mx-2 ${props.className}`}
      >
        {value !== null ? value : 'Year'}
      </Dropdown.ToggleButton>
      <Dropdown.Menu placement='bottom-end'>
        <ScrollContainer maxHeight='196px'>
          {years.map((year, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                onChange(year)
              }}
            >
              {year}
            </Dropdown.Item>
          ))}
        </ScrollContainer>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
