import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type TextAlign = 'left' | 'center' | 'right'

type TextAlignDefinition = {
  keyboardShortcut: string
  icon: IconProp
  label: string
  value: TextAlign
}

export type TextAlignEditor = {
  defaultValue: TextAlign
  setValue: (value: TextAlign) => void
  value: TextAlign
  values?: TextAlign[]
}

export const textAligns: TextAlignDefinition[] = [
  {
    keyboardShortcut: 'Mod-Shift-l',
    icon: ['fas', 'align-left'],
    label: 'Align Left',
    value: 'left',
  },
  {
    keyboardShortcut: 'Mod-Shift-e',
    icon: ['fas', 'align-center'],
    label: 'Align Center',
    value: 'center',
  },
  {
    keyboardShortcut: 'Mod-Shift-r',
    icon: ['fas', 'align-right'],
    label: 'Align Right',
    value: 'right',
  },
]
