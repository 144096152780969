import React from 'react'
// @ts-ignore: WIP imports
import { FullScreenPage } from '../layout'
import { MailtoSupportLink } from '../links'

export function UnknownErrorPage() {
  return (
    <FullScreenPage>
      <div className='tw-flex tw-flex-col tw-gap-4 container'>
        <div className='tw-text-6xl tw-font-semibold tw-leading-snug'>
          Whoops! Something went wrong.
        </div>
        <div className='tw-text-3xl tw-font-semibold tw-leading-snug'>
          We've logged the error and routinely check our logs to resolve issues.
          You may try refreshing the page or clicking the back button in your
          browser. If that doesn't work and this issue is preventing you from
          accomplishing a task, please contact <MailtoSupportLink />. You can
          help us resolve the issue by describing the action you were taking
          when it occurred. Thank you!
        </div>
      </div>
    </FullScreenPage>
  )
}
