import dropRight from 'lodash/dropRight'

// domain ---------------------------------------------------------------------

const domainRegEx = /^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/

export function isValidDomain(domain: string) {
  return domainRegEx.test(domain)
}

export function validateDomain(domain: string) {
  if (!isValidDomain(domain)) throw new TypeError(`Invalid domain: ${domain}`)
}

export const formatDomain = (domain: string) => {
  domain = domain.trim().toLowerCase()
  return domain
}

// subdomain ------------------------------------------------------------------

export const subdomainRegEx = /^[a-z0-9_-]+$/
export const isValidSubdomain = (subdomain: string) =>
  subdomainRegEx.test(subdomain)
export const validateSubdomain = (domain: string) => {
  if (!isValidSubdomain(domain))
    throw new TypeError(`Invalid subdomain: ${domain}`)
}

export const formatSubdomain = (subdomain: string) => {
  subdomain = subdomain.trim().toLowerCase()
  subdomain = subdomain.replace(/[\s@.]+/g, '-')
  subdomain = subdomain.replace(/[^A-Za-z0-9_-]+/g, '')
  return subdomain
}

export function getSubdomain(domain: string) {
  const parts = dropRight(domain.split('.'), 2)
  if (!parts.length) return
  return parts.join('.')
}
