import { useForceUpdate, useWindowSize } from '@blissbook/ui/util/hooks'
import isFunction from 'lodash/isFunction'
import { useEffect } from 'react'
import type { Editor } from '../editor'

export type NodeViewGetPos = (() => number) | boolean

export function getNodeViewPos(getPos: NodeViewGetPos) {
  return isFunction(getPos) ? getPos() : undefined
}

export function useElement(editor: Editor, pos: number) {
  const forceUpdate = useForceUpdate()

  // Update on resize window
  useWindowSize()

  // Update on resize elements
  useEffect(() => {
    editor.on('resize', forceUpdate)
    return () => {
      editor.off('resize', forceUpdate)
    }
  }, [editor, forceUpdate])

  return editor.view.nodeDOM(pos) as HTMLElement
}

export function useDOMRect(editor: Editor, pos: number) {
  const element = useElement(editor, pos)

  let left = element.offsetLeft
  let top = element.offsetTop
  let { offsetParent } = element
  while (offsetParent instanceof HTMLElement) {
    left += offsetParent.offsetLeft
    top += offsetParent.offsetTop
    if (offsetParent === editor.view.dom) break
    offsetParent = offsetParent.offsetParent
  }

  return new DOMRect(left, top, element.offsetWidth, element.offsetHeight)
}
