import React from 'react'

export default ({ color, fill, size, ...props }) => (
  <svg
    {...props}
    height={size}
    width={size}
    fill={fill ? color : 'none'}
    stroke={fill ? 'none' : color}
    strokeWidth={3}
    viewBox={fill ? '0 0 32 55' : '0 -1.5 32 56.5'}
    role='img'
    aria-label='quote'
  >
    <g>
      <g transform='translate(-1345.000000, -882.000000)'>
        <g transform='translate(960.000000, 424.000000)'>
          <path d='M385.859375,507.296875 C391.744821,504.432277 396.35415,501.098977 399.6875,497.296875 C403.02085,493.494773 404.947914,488.885444 405.46875,483.46875 L401.09375,483.46875 C397.864567,483.46875 395.234385,483.10417 393.203125,482.375 C391.171865,481.64583 389.531256,480.630215 388.28125,479.328125 C387.083327,478.078119 386.236982,476.697924 385.742188,475.1875 C385.247393,473.677076 385,472.114591 385,470.5 C385,467.16665 386.341132,464.250013 389.023438,461.75 C391.705743,459.249987 394.869773,458 398.515625,458 C404.24482,458 408.645818,459.901023 411.71875,463.703125 C414.791682,467.505227 416.328125,472.765591 416.328125,479.484375 C416.328125,485.734406 413.684922,492.036427 408.398438,498.390625 C403.111953,504.744823 396.744829,509.562484 389.296875,512.84375 L385.859375,507.296875 Z' />
        </g>
      </g>
    </g>
  </svg>
)
