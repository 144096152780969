import { useEffect, useRef } from 'react'

export type MouseEventFunction = (ev: MouseEvent) => void

export const useMouseDown = (_fn: MouseEventFunction) => {
  const fnRef = useRef(_fn)
  fnRef.current = _fn

  useEffect(() => {
    function onMouseDown(event: MouseEvent) {
      fnRef.current(event)
    }

    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
  }, [])
}
