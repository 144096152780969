import BaseTextNode from '@tiptap/extension-text'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    text: {
      insertText: (text: string, from?: number, to?: number) => ReturnType
    }
  }
}

export const TextNode = BaseTextNode.extend({
  addCommands() {
    return {
      insertText:
        (text, from, to) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.insertText(text, from, to)
          }

          return true
        },
    }
  },
})
