export const headingTagNames = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6']

export function normalizeHeadings(document: Document) {
  const selector = headingTagNames.join(',')
  const headingEls = document.querySelectorAll<HTMLHeadingElement>(selector)
  for (const headingEl of headingEls) {
    const isEmpty = headingEl.textContent.trim().length === 0
    if (!isEmpty) continue

    const paragraphEl = document.createElement('p')
    headingEl.replaceWith(paragraphEl)
  }
}
