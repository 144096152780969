import { isProseMirrorNodeEmpty } from '@blissbook/lib/document'
import { cx } from '@emotion/css'
import React from 'react'
import { type CSSProperties, useEffect, useState } from 'react'
import { useActiveEditorManager } from './active'
import type { Editor } from './editor'
import { useEditorTransaction } from './hooks'
import { SelectionContainerView } from './selection/container'
import { SelectionView, useSelecting } from './selection/view'
import { getTextAlign } from './tools/textAlign'
import './styles/index.scss'

export type EditorViewProps = {
  autoFocus?: boolean
  className?: string
  editor: Editor
  editorClassName?: string
  fullHeight?: boolean
  placeholder?: string
  showBubbleMenu?: boolean
  style?: CSSProperties
  tagName?: string
}

function useEditorView(editor: Editor) {
  const [active, setActive] = useState(false)
  const [editorEl, setEditorEl] = useState<HTMLDivElement | null>()
  const [viewEl, setViewEl] = useState<HTMLDivElement | null>()

  // Update on transactions
  useEditorTransaction(editor)

  // Bind to DOM
  useEffect(() => {
    if (!editorEl || !viewEl) return

    editorEl.innerHTML = ''
    editorEl.append(editor.view.dom)
    editor.setOptions({ viewEl })
  }, [editor, editorEl, viewEl])

  // Bind to manager
  const manager = useActiveEditorManager()
  useEffect(() => {
    if (!manager) return

    editor.setOptions({ manager })

    function handleDestroy() {
      manager.handleBlur(editor)
    }

    function handleFocus() {
      manager.handleFocus(editor)
    }

    function handleActive(activeEditor: Editor) {
      setActive(editor === activeEditor)
    }

    editor.on('destroy', handleDestroy)
    editor.on('focus', handleFocus)
    manager.addListener(handleActive)
    return () => {
      editor.off('destroy', handleDestroy)
      editor.off('focus', handleFocus)
      manager.removeListener(handleActive)
    }
  }, [editor, manager])

  return {
    active,
    setEditorEl,
    setViewEl,
  }
}

export const EditorView = ({
  autoFocus,
  className,
  editor,
  editorClassName,
  fullHeight,
  placeholder,
  showBubbleMenu,
  tagName = 'div',
  ...props
}: EditorViewProps) => {
  const { state } = editor
  const TagName = tagName as React.ElementType
  const isEmpty = isProseMirrorNodeEmpty(state.doc)
  const isSelecting = useSelecting(editor)
  const textAlign = getTextAlign(editor)
  const { active, setEditorEl, setViewEl } = useEditorView(editor)
  const focus = editor.view.hasFocus()

  useEffect(() => {
    if (!editor) return

    // Autofocs on the editor view?
    if (autoFocus) {
      setTimeout(() => {
        editor.view.focus()
      })
    }

    // Add custom class to the editor view?
    const { dom } = editor.view
    dom.className = cx(dom.className, editorClassName)

    // Make the editor view full height?
    if (fullHeight) {
      dom.className = cx(dom.className, 'tw-h-full tw-overflow-y-auto')
    }
  }, [editor, editorClassName])

  return (
    <TagName
      {...props}
      className={cx(
        'rw-wysiwyg rw-wysiwyg-editor',
        fullHeight && 'tw-h-full',
        { active, focus },
        className,
      )}
      ref={setViewEl}
    >
      <div
        className={cx(
          'rw-wysiwyg-placeholder',
          fullHeight && 'tw-h-full',
          textAlign && `text-${textAlign.value}`,
          editorClassName,
        )}
        key='placeholder'
        style={{
          display: isEmpty ? 'block' : 'none',
        }}
      >
        {placeholder}
      </div>

      <div
        className={cx(fullHeight && 'tw-h-full')}
        key='editor'
        ref={setEditorEl}
      />

      {active && (
        <>
          <SelectionContainerView editor={editor} key='container' />

          <SelectionView
            editor={editor}
            key='selection'
            isSelecting={isSelecting}
            showBubbleMenu={showBubbleMenu}
          />
        </>
      )}
    </TagName>
  )
}
