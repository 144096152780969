import type { VariableOption } from '@blissbook/lib/document'
import { Node } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    variable: {
      insertVariable: (variable: VariableOption) => ReturnType
    }
  }
}

export const VariableNode = Node.create({
  name: 'variable',

  draggable: true,
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      format: {
        default: undefined,
        parseHTML: (element) => {
          if (element.nodeName === 'VARIABLE') {
            const format = element.getAttribute('format')
            return format
          }

          if (element.nodeName === 'SPAN') {
            const format = element.getAttribute('data-variable-format')
            if (format) return format
          }
        },
      },

      name: {
        parseHTML: (element) => {
          if (element.nodeName === 'VARIABLE') {
            const name = element.getAttribute('name')
            return name
          }

          if (element.nodeName === 'SPAN') {
            const name = element.getAttribute('data-variable-name')
            if (name) return name
          }
        },
      },
    }
  },

  addCommands() {
    return {
      insertVariable:
        (variable) =>
        ({ commands }) => {
          const { format, name } = variable
          return commands.insertContent({
            type: this.name,
            attrs: { format, name },
          })
        },
    }
  },

  parseHTML() {
    return [{ tag: 'variable' }, { tag: 'span[data-variable-name]' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['variable', HTMLAttributes]
  },
})
