import { type Role, type RoleColor, adminRolesById } from '@blissbook/lib/rbac'
import { cx } from '@emotion/css'
import React from 'react'
import { type ReactNode, forwardRef } from 'react'

type RoleBadgeProps = {
  children?: ReactNode
  className?: string
  outline?: boolean
  role?: Role
}

type RoleColorProps = {
  outline: string
  solid: string
}

const noRoleColor: RoleColorProps = {
  outline: 'tw-border-gray-500 tw-text-gray-500',
  solid: 'tw-bg-gray-500 tw-border-gray-500 tw-text-white',
}

const colorsMap = new Map<RoleColor, RoleColorProps>([
  [
    'aqua',
    {
      outline: 'tw-border-aqua-700 tw-bg-white tw-text-aqua-700',
      solid: 'tw-bg-aqua-700 tw-border-aqua-700 tw-text-white',
    },
  ],
  [
    'blurple',
    {
      outline: 'tw-border-blurple-500 tw-text-blurple-500',
      solid: 'tw-bg-blurple-500 tw-border-blurple-500 tw-text-white',
    },
  ],
  [
    'green',
    {
      outline: 'tw-border-green-500 tw-text-green-500',
      solid: 'tw-bg-green-500 tw-border-green-500 tw-text-white',
    },
  ],
  [
    'purple',
    {
      outline: 'tw-border-purple-500 tw-text-purple-500',
      solid: 'tw-bg-purple-500 tw-border-purple-500 tw-text-white',
    },
  ],
  [
    'red',
    {
      outline: 'tw-border-red-700 tw-text-red-700',
      solid: 'tw-bg-red-700 tw-border-red-700 tw-text-white',
    },
  ],
  [
    'sunshine',
    {
      outline: 'tw-border-sunshine-700 tw-text-sunshine-700',
      solid: 'tw-bg-sunshine-700 tw-border-sunshine-700 tw-text-white',
    },
  ],
])

function getRoleBadgeClassName(role: Role, outline = false) {
  const color = colorsMap.get(role?.color) || noRoleColor
  return cx(
    'tw-border tw-border-solid tw-font-semibold tw-inline-flex tw-leading-snug tw-px-1.5 tw-rounded-full tw-text-xs tw-tracking-[1px] tw-uppercase tw-whitespace-nowrap',
    outline ? color.outline : color.solid,
  )
}

export const RoleBadge = forwardRef<HTMLDivElement, RoleBadgeProps>(
  ({ children, className, role, outline, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cx(getRoleBadgeClassName(role, outline), className)}
        ref={ref}
      >
        {children || role.label}
      </div>
    )
  },
)

type OrganizationRoleBadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  outline?: boolean
  roleId: string
}

export const OrganizationRoleBadge = forwardRef<
  HTMLDivElement,
  OrganizationRoleBadgeProps
>(({ roleId, ...props }, ref) => {
  const role = adminRolesById.get(roleId)
  return <RoleBadge {...props} ref={ref} role={role} />
})
