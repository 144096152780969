import { getColor } from '@blissbook/ui/branding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import times from 'lodash/times'
import React from 'react'

export const StepIndicator = ({
  activeColor = 'blurple-500',
  activeIndex,
  activeSize = 8,
  count,
  inactiveColor = 'gray-400',
  inactiveSize = 6,
  transitionDuration = 200,
  ...props
}) => (
  <div
    {...props}
    css={{
      alignItems: 'center',
      display: 'flex',
    }}
  >
    {times(count).map((index) => {
      const isActive = index === activeIndex
      return (
        <div
          className='tw-flex tw-items-center tw-justify-center'
          css={{
            height: Math.max(activeSize, inactiveSize),
            marginRight: 2,
            transition: `color ${transitionDuration}ms linear, font-size ${transitionDuration}ms linear`,
            width: Math.max(activeSize, inactiveSize),
            '&:last-child': {
              marginRight: 0,
            },
          }}
          key={index}
          style={{
            color: getColor(isActive ? activeColor : inactiveColor),
            fontSize: isActive ? activeSize : inactiveSize,
          }}
        >
          <FontAwesomeIcon icon='circle' />
        </div>
      )
    })}
  </div>
)
