import {
  type Node,
  getAudienceExpressionFromNode,
} from '@blissbook/lib/document'
import type { PersonalizationInput } from './Personalization'
import { evaluateAudienceExpression } from './expression'

function isNodeEmpty(node: Node): boolean {
  if (node.type === 'paragraph') {
    return !node.content || !node.content.length
  }

  if (node.type === 'readMore') {
    return (
      !node.content || !node.content.length || node.content.every(isNodeEmpty)
    )
  }

  return false
}

export function personalizeContent(
  content: Node[],
  personalization: PersonalizationInput,
) {
  let index = 0
  const removedIndexes = new Set<number>()
  while (index < content.length) {
    const node = content[index]

    const audienceExpression = getAudienceExpressionFromNode(node)
    if (
      audienceExpression &&
      !evaluateAudienceExpression(audienceExpression, personalization)
    ) {
      content.splice(index, 1)
      removedIndexes.add(index)
      continue
    }

    if ('content' in node) {
      personalizeContent(node.content, personalization)
      if (isNodeEmpty(node)) {
        content.splice(index, 1)
        removedIndexes.add(index)
        continue
      }
    }

    index++
  }

  if (removedIndexes.size > 0) {
    let removedCount = 0
    for (const initialIndex of removedIndexes) {
      const index = initialIndex - removedCount

      // How many empty nodes above?
      let aboveIndex = index
      while (aboveIndex > 0 && isNodeEmpty(content[aboveIndex - 1])) {
        aboveIndex--
      }
      const aboveCount = index - aboveIndex

      // How many empty nodes above?
      let belowIndex = index
      while (belowIndex < content.length && isNodeEmpty(content[belowIndex])) {
        belowIndex++
      }
      const belowCount = belowIndex - index

      // Remove the nodes
      const removeCount = Math.min(aboveCount, belowCount)
      for (let step = 0; step < removeCount; ++step) {
        content.splice(index, 1)
      }
      removedCount += removeCount
    }
  }
}
