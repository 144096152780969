import { embedAligns, embedShapes } from '@blissbook/lib/document'
import type { Attributes } from '@tiptap/core'

export const embedAttributes: Attributes = {
  align: {
    default: undefined,
    keepOnSplit: true,
    parseHTML: (element: HTMLElement) => {
      const align = embedAligns.find((align) =>
        element.classList.contains(align.className),
      )
      if (!align) return
      return align.type
    },
    renderHTML: ({ align }) => {
      if (!align) return {}

      const type = embedAligns.find((type) => type.type === align)
      if (!type) return
      return { class: type.className }
    },
  },
  height: {
    default: undefined,
    keepOnSplit: true,
    parseHTML: (element: HTMLElement) => {
      const height = element.getAttribute('height') || element.style.height
      return height ? Number.parseInt(height, 10) : undefined
    },
    renderHTML: ({ height }) => {
      if (!height) return {}
      return { style: `height: ${height}px` }
    },
  },
  shape: {
    default: undefined,
    keepOnSplit: true,
    parseHTML: (element: HTMLElement) => {
      const shape = embedShapes.find((shape) =>
        element.classList.contains(shape.className),
      )
      if (!shape) return
      return shape.type
    },
    renderHTML: ({ shape }) => {
      if (!shape) return {}

      const type = embedShapes.find((type) => type.type === shape)
      if (!type) return
      return { class: type.className }
    },
  },
  src: {
    default: undefined,
    keepOnSplit: true,
  },
  width: {
    default: undefined,
    keepOnSplit: true,
    parseHTML: (element: HTMLElement) => {
      const width = element.getAttribute('width') || element.style.width
      return width ? Number.parseInt(width, 10) : undefined
    },
    renderHTML: ({ width }) => {
      if (!width) return {}
      return { style: `width: ${width}px` }
    },
  },
}
