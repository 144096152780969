import { sanitizeUrl } from '@blissbook/lib/sanitize'
import { Mark } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    link: {
      setLink: (href: string) => ReturnType
      unsetLink: () => ReturnType
    }
  }
}

export const LinkMark = Mark.create({
  name: 'link',

  priority: 1000,

  keepOnSplit: false,

  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML: (element: HTMLAnchorElement) => {
          const href = element.getAttribute('href')
          return sanitizeUrl(href)
        },
      },
    }
  },

  parseHTML() {
    return [{ tag: 'a' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setLink:
        (href) =>
        ({ commands }) => {
          return commands.setMark(this.name, { href })
        },

      unsetLink:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name, { extendEmptyMarkRange: true })
        },
    }
  },
})
