import { Variant } from '@blissbook/lib/expression'
import { immerable } from 'immer'
import orderBy from 'lodash/orderBy'
import { fromJSON, mapJSONValues } from './util/json'

export class Group {
  id: number
  name: string
  archived: boolean
  peopleCount: number

  static get ATTRIBUTES() {
    return {
      createdAt: fromJSON.date,
      updatedAt: fromJSON.date,
    }
  }

  // Map attributes
  static mapJSON(json: any) {
    return mapJSONValues(json, Group.ATTRIBUTES)
  }

  // Create a handbook from JSON
  static fromJSON(json: any) {
    return Object.assign(new Group(), Group.mapJSON(json))
  }

  // Variant
  static get variantType() {
    return 'group' as const
  }
  get displayName() {
    return this.name
  }
  get uid() {
    return this.toVariant().uid
  }
  toVariant() {
    return new Variant(Group.variantType, this.id)
  }

  get url() {
    return `/groups/${this.id}`
  }
}

// @ts-ignore: immerable
Group[immerable] = true

// Sort these groups
export const orderGroups = (groups: Group[]) =>
  orderBy(groups, ['id', 'name'], ['asc', 'asc'])
