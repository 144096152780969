import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ToolbarButton } from '../../../toolbar'

export const EmbedRemoveIcon = () => (
  <FontAwesomeIcon icon={['fas', 'trash-can']} />
)

export type EmbedNodeSelectionRemoveToolProps = {
  label: string
  onRemove: () => void
}

export const EmbedNodeSelectionRemoveTool = ({
  label,
  onRemove,
  ...props
}: EmbedNodeSelectionRemoveToolProps) => (
  <ToolbarButton
    {...props}
    label={`Remove ${label}`}
    onClick={() => {
      onRemove()
    }}
  >
    <EmbedRemoveIcon />
  </ToolbarButton>
)
