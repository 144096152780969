export class AspectRatio {
  width: number
  height: number

  constructor(width: number, height: number) {
    this.width = width
    this.height = height
  }

  static fromString(value?: string) {
    if (!value) return null

    const match = value.match(/(\d+):(\d+)/)
    if (!match) return null

    const width = Number.parseInt(match[1], 10)
    const height = Number.parseInt(match[2], 10)
    return new AspectRatio(width, height)
  }

  // width / height
  get value() {
    return this.width / this.height
  }

  toString() {
    return `${this.width}:${this.height}`
  }

  // Get the delta from this ratio
  getDelta(ratio: AspectRatio) {
    return Math.abs(this.value - ratio.value)
  }

  // Calculate the height from the given width using this aspect ratio
  getHeightFromWidth(width: number) {
    return width * (this.height / this.width)
  }

  // Calculate the height from the given width using this aspect ratio
  getWidthFromHeight(height: number) {
    return height * (this.width / this.height)
  }
}

class AspectRatioArray extends Array<AspectRatio> {
  // Find the best aspect ratio to match these dimensions
  findBest(width: number, height: number) {
    const ratio = new AspectRatio(width, height)
    return this.reduce((best, next) =>
      ratio.getDelta(next) < ratio.getDelta(best) ? next : best,
    )
  }

  // Find the exact aspect ratio to match these dimensions
  findMatch(width: number, height: number) {
    const ratio = new AspectRatio(width, height)
    return this.find((next) => ratio.value === next.value)
  }
}

export const fullScreenAspectRatio = new AspectRatio(4, 3)
export const wideScreenAspectRatio = new AspectRatio(16, 9)

export const videoAspectRatios = new AspectRatioArray(
  fullScreenAspectRatio,
  wideScreenAspectRatio,
)
