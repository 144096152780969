import type { BlissbookSection } from '@blissbook/lib/blissbook'
import type { PersonalizationInput } from './Personalization'
import { evaluateAudienceExpression } from './expression'

export type HandbookSectionAccessControl = {
  languageCode?: string
  personalization?: PersonalizationInput
  showHidden?: boolean
}

export function canAccessSection(
  section: BlissbookSection,
  accessControl: HandbookSectionAccessControl,
) {
  const { languageCode, personalization, showHidden } = accessControl
  // Hidden = disallow
  if (section.hidden && !showHidden) return false
  // Language mismatch = disallow
  if (languageCode && section.languageCode !== languageCode) return false
  // No personalization = allow
  if (!personalization) return true
  // No audienceExpression = allow
  if (!section.audienceExpression) return true
  // Personalize
  return evaluateAudienceExpression(section.audienceExpression, personalization)
}
