import { headingTagNames } from './headings'

export const textBlockTagNames = [...headingTagNames, 'P']

const textBlockSelector = textBlockTagNames.join(',')

function splitElement(element: Element) {
  const childGroups: Node[][] = []

  let childNodes: Node[] = []
  childGroups.push(childNodes)

  for (const childNode of element.childNodes) {
    if (childNode.nodeType !== Node.ELEMENT_NODE) {
      childNodes.push(childNode)
      continue
    }

    const childEl = childNode as Element
    if (childEl.tagName === 'BR') {
      childNodes = []
      childGroups.push(childNodes)
    } else {
      const [node, ...splitNodes] = splitElement(childEl)
      childNodes.push(node)

      for (const splitNode of splitNodes) {
        childNodes = [splitNode]
        childGroups.push(childNodes)
      }
    }
  }

  if (childGroups.length === 1) return [element]

  return childGroups.map((childNodes) => {
    const clone = element.cloneNode(false)
    for (const childNode of childNodes) {
      clone.appendChild(childNode)
    }
    return clone
  })
}

export function splitTextBlocks(document: Document) {
  const textBlockEls = document.querySelectorAll<Element>(textBlockSelector)
  for (const textBlockEl of textBlockEls) {
    // If no text, clear it out
    const isEmpty = textBlockEl.textContent.trim().length === 0
    if (isEmpty) {
      for (const node of textBlockEl.childNodes) {
        textBlockEl.removeChild(node)
      }
    }
    // Otherwise, try to split
    else {
      const splitEls = splitElement(textBlockEl)
      if (splitEls.length > 1) {
        textBlockEl.replaceWith(...splitEls)
      }
    }
  }
}
