import mapValues from 'lodash/mapValues'

// Convert empty string to null
export const mapToNullValue = (value: any) => (value === '' ? null : value)
export const mapToNullValues = (values: any[]) =>
  mapValues(values, mapToNullValue)

// Convert null to empty strings
export const mapFromNullValue = (value: any) => (value === null ? '' : value)
export const mapFromNullValues = (values: any[]) =>
  mapValues(values, mapFromNullValue)

// From https://github.com/gregberge/react-merge-refs
export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        const refObj = ref as React.MutableRefObject<T | null>
        refObj.current = value
      }
    })
  }
}
