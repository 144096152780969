export class Environment {
  constructor(name) {
    this[name] = true
  }

  get local() {
    return [this.development, this.test].some(Boolean)
  }

  get name() {
    return Object.keys(this)[0]
  }

  toString() {
    return this.name
  }
}
