import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

export const Textarea = React.forwardRef(
  (
    {
      emptyValue = '', // Value for onChangeValue when the input is empty
      onChange,
      onChangeValue,
      value,
      ...props
    },
    ref,
  ) => (
    <TextareaAutosize
      {...props}
      ref={ref}
      onChange={(event) => {
        const { target } = event
        const value = target.value.length ? target.value : emptyValue
        if (onChange) onChange(event, value)
        if (onChangeValue) onChangeValue(value)
      }}
      value={value === emptyValue ? '' : value}
    />
  ),
)
