import React from 'react'
import { Slide, type ToastOptions, toast } from 'react-toastify'

const baseOptions: ToastOptions = {
  autoClose: 4000,
  closeButton: false,
  draggable: false,
  icon: false,
  position: toast.POSITION.BOTTOM_LEFT,
  theme: 'colored',
  transition: Slide,
}

// Format the toast message
export const Toast: React.FC<{
  message: JSX.Element | string
  title?: string
}> = ({ message, title }) =>
  !title ? (
    <>{message}</>
  ) : (
    <>
      <div className='tw-font-bold tw-mb-1'>{title}</div>
      <div>{message}</div>
    </>
  )

export const addToast = (
  content: JSX.Element | string,
  options?: ToastOptions,
) => {
  toast(content, {
    ...baseOptions,
    ...options,
  })
}

export const setToast = (
  content: JSX.Element | string,
  options?: ToastOptions,
) => {
  toast.dismiss()
  addToast(content, options)
}
