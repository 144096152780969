import React from 'react'
import ReactDOM from 'react-dom'
import { MountCSSTransition } from './animate'

export const Portal: React.FC = ({ children }) => {
  const { document } = global
  if (!document) return null
  return ReactDOM.createPortal(children, document.body)
}

export const AnimatedPortal: React.FC<{
  in: boolean
}> = ({ children, ...props }) => (
  <MountCSSTransition {...props}>
    <Portal>{children}</Portal>
  </MountCSSTransition>
)
