import { colors, getColor } from '@blissbook/ui/branding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const Infobox = ({ children, iconColor = 'aqua-500', ...props }) => (
  <div
    {...props}
    css={{
      alignItems: 'center',
      background: colors['gray-100'],
      display: 'flex',
      fontSize: 14,
      padding: '.5rem 1rem',
      borderRadius: 4,
    }}
  >
    <FontAwesomeIcon
      className='tw-mr-4'
      css={{
        color: getColor(iconColor),
        fontSize: 24,
      }}
      icon={['far', 'info-circle']}
    />
    <div>{children}</div>
  </div>
)
