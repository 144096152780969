import { Plugin, PluginKey } from 'prosemirror-state'
import { type LinkEditorState, getNextLinkEditorState } from './state'

export const pluginName = 'linkEditor'
export const pluginKey = new PluginKey<LinkEditorState>(pluginName)

export function createPlugin() {
  return new Plugin<LinkEditorState>({
    key: pluginKey,

    state: {
      init() {
        return undefined
      },

      apply(tr, pluginState, oldState, newState) {
        return getNextLinkEditorState({
          newState,
          oldState,
          pluginState,
          tr,
        })
      },
    },
  })
}
