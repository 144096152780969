import {
  type PeopleSource,
  useMetadataKeysQuery,
  useSavedSegmentsQuery,
} from '@blissbook/ui/application/graph'

export function useMetadataKeys(source?: PeopleSource) {
  const { data } = useMetadataKeysQuery({
    variables: { source },
  })
  return data?.metadataKeys || []
}

export function useSavedSegments() {
  const { data } = useSavedSegmentsQuery()
  return data?.savedSegments || []
}
