import type { ColorPaletteInput } from '@blissbook/lib/blissbook'
import type {
  DocumentSchema,
  TextAlignEditor,
  VariableOption,
} from '@blissbook/lib/document'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import { Editor as BaseEditor, type EditorOptions } from '@tiptap/core'
import { type ReactNode, useEffect, useState } from 'react'
import type { ActiveEditorManager } from './active'
import { getExtensions } from './extensions'
import type { BookmarksInput } from './marks'
import { normalizeHtml } from './normalize'

export type { EditorOptions }
export type PickFunction<T> = (prevValue?: T) => Promise<T>

declare module '@tiptap/core' {
  interface EditorEvents {
    bookmarks: BookmarksInput | undefined
    colorPalette: ColorPaletteInput | undefined
    resize: HTMLElement
    textAlign: TextAlignEditor | undefined
  }

  interface EditorOptions {
    bookmarks?: BookmarksInput
    colorPalette?: ColorPaletteInput
    manager: ActiveEditorManager
    pickAudienceExpression?: PickFunction<AudienceRootExpression | null>
    pickImage?: PickFunction<string>
    pickPdf?: PickFunction<string>
    pickVideo?: PickFunction<string>
    renderAudienceExpression?: (
      audienceExpression: AudienceRootExpression | null,
    ) => ReactNode
    schema: DocumentSchema
    textAlign?: TextAlignEditor
    variables?: VariableOption[]
    viewEl: HTMLDivElement
  }
}

export class Editor extends BaseEditor {
  constructor(options?: Partial<EditorOptions>) {
    super({
      ...options,
      editorProps: {
        ...options?.editorProps,
        transformPastedHTML(html) {
          return normalizeHtml(html)
        },
      },
    })
  }

  get bookmarks() {
    return this.options.bookmarks
  }

  get colorPalette() {
    return this.options.colorPalette
  }

  get manager() {
    return this.options.manager
  }

  get pickAudienceExpression() {
    return this.options.pickAudienceExpression
  }

  get pickImage() {
    return this.options.pickImage
  }

  get pickPdf() {
    return this.options.pickPdf
  }

  get pickVideo() {
    return this.options.pickVideo
  }

  get textAlign() {
    return this.options.textAlign
  }

  get variables() {
    return this.options.variables
  }

  get viewEl() {
    return this.options.viewEl
  }

  handleBlur() {
    const { commands } = this
    if (commands.hideLinkEditor) {
      commands.hideLinkEditor()
    }

    this.manager.handleBlur(this)
  }

  hasExtension(name: string) {
    return this.extensionManager.extensions.some((e) => e.name === name)
  }

  onResize(resizeEl: HTMLElement) {
    this.emit('resize', resizeEl)
  }

  renderAudienceExpression(audienceExpression: AudienceRootExpression | null) {
    const { renderAudienceExpression } = this.options
    return renderAudienceExpression
      ? renderAudienceExpression(audienceExpression)
      : audienceExpression
  }

  setBookmarks(bookmarks?: BookmarksInput) {
    if (bookmarks !== this.bookmarks) {
      this.options.bookmarks = bookmarks
      this.emit('bookmarks', bookmarks)
    }
  }

  setColorPalette(colorPalette?: ColorPaletteInput) {
    if (colorPalette !== this.colorPalette) {
      this.options.colorPalette = colorPalette
      this.emit('colorPalette', colorPalette)
    }
  }

  setTextAlign(textAlign?: TextAlignEditor) {
    if (textAlign !== this.textAlign) {
      this.options.textAlign = textAlign
      this.emit('textAlign', textAlign)
    }
  }
}

export function createEditor(options: Partial<EditorOptions>) {
  const { schema } = options
  return new Editor({
    ...options,
    extensions: getExtensions(schema),
  })
}

export function createTestEditor(options: Partial<EditorOptions>) {
  return createEditor({
    ...options,
    editorProps: {
      ...options.editorProps,
      handleScrollToSelection() {
        return true
      },
    },
  })
}

export function useEditor(options: Partial<EditorOptions>) {
  const { bookmarks, colorPalette, schema, textAlign } = options

  const [editor, setEditor] = useState<Editor>()
  useEffect(() => {
    const editor = createEditor(options)
    setEditor(editor)

    return () => {
      editor.destroy()
      setEditor(undefined)
    }
  }, [schema])

  // Ensure the editor options are kept up-to-date in a react-y world
  if (editor) {
    editor.setBookmarks(bookmarks)
    editor.setColorPalette(colorPalette)
    editor.setTextAlign(textAlign)
  }

  return editor
}
