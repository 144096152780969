import { AspectRatio } from '@blissbook/lib/aspectRatio'
import { colors } from '@blissbook/ui/branding'
import { mergeRefs } from '@blissbook/ui/util'
import { useWindowSize } from '@blissbook/ui/util/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import ReactPlayer from 'react-player'
import { Video } from './video'

const playerConfig = {
  youtube: {
    playerVars: {
      showinfo: 0,
      controls: 1,
    },
  },
}

type Size = {
  height: number
  width: number
}

// Get the size from this node
const getSizeFromNode = (node: HTMLElement, ratio: AspectRatio) => {
  if (!node) return

  const width = node.offsetWidth
  const height = ratio.getHeightFromWidth(node.offsetWidth)
  return { width, height }
}

// Hook for the current size
const useSize = ({
  height,
  node,
  width,
}: {
  height: number
  node: HTMLElement
  width: number
}) => {
  const [size, setSize] = useState<Size>()

  // Ratio
  const ratio = useMemo(() => new AspectRatio(width, height), [width, height])

  // Size
  const windowSize = useWindowSize()
  useEffect(() => {
    const size = getSizeFromNode(node, ratio)
    setSize(size)
  }, [node, ratio, windowSize])

  return size || { width, height: undefined }
}

// Hook for the video
const useVideo = (url: string) => useMemo(() => Video.fromUrl(url), [url])

type PlayerProps = {
  className?: string
  height: number
  url: string
  width: number
}

export const VideoPlayer = forwardRef<HTMLDivElement, PlayerProps>(
  ({ className, height, url, width, ...props }, ref) => {
    const [isPlaying, setPlaying] = useState(false)
    const [isStarted, setStarted] = useState(false)
    const [node, setNode] = useState<HTMLDivElement>()
    const size = useSize({ height, node, width })
    const video = useVideo(url)
    const { coverUrl } = video || {}
    return (
      <div
        {...props}
        className={classnames('rw-video', className)}
        ref={mergeRefs([ref, setNode])}
        style={{ height, width }}
      >
        <Choose>
          <When condition={!video}>
            <div
              className='tw-flex tw-items-center tw-justify-center tw-h-full tw-p-6'
              css={{
                border: `3px solid ${colors['red-700']}`,
                color: colors['red-700'],
                fontWeight: 700,
              }}
            >
              Invalid Video URL
            </div>
          </When>
          <Otherwise>
            <ReactPlayer
              {...video.source.playerProps}
              className='rw-video-player'
              config={playerConfig}
              height={size.height}
              key={url}
              onStart={() => setStarted(true)}
              playing={isPlaying}
              url={url}
              width={size.width}
            />

            <If condition={coverUrl && !isStarted}>
              <div
                className='rw-video-cover'
                style={{ backgroundImage: `url('${video.coverUrl}'` }}
                onClick={() => setPlaying(true)}
                onKeyUp={(e) => e.key === 'Enter' && setPlaying(true)}
              >
                <FontAwesomeIcon
                  className='rw-video-play'
                  icon={isPlaying ? 'spinner' : ['far', 'play-circle']}
                  spin={isPlaying}
                />
              </div>
            </If>
          </Otherwise>
        </Choose>
      </div>
    )
  },
)
