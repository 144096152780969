import {
  type ExpressionNode,
  getAudienceExpressionFromNodeAttrs,
  getExpressionNodes,
} from '@blissbook/lib/document/types'
import type { Editor } from '@blissbook/ui/editor'
import { Tooltip } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import last from 'lodash/last'
import type { Node as ProseMirrorNode } from 'prosemirror-model'
import { Decoration, type EditorView } from 'prosemirror-view'
import React from 'react'
import ReactDOM from 'react-dom'

export function getExpressions(doc: ProseMirrorNode) {
  const { nodes } = getExpressionNodes(doc)
  return nodes.reduce((groups, node) => {
    const { groupId } = node
    const lastGroup = last(groups)
    const audienceExpression = getAudienceExpressionFromNodeAttrs(
      node.node.attrs,
    )

    if (!audienceExpression) {
      // Do nothing
    } else if (groupId === lastGroup?.id) {
      lastGroup.nodes.push(node)
    } else {
      const group = new Expression([node])
      groups.push(group)
    }

    return groups
  }, [] as Expression[])
}

export class Expression {
  readonly nodes: ExpressionNode[]

  constructor(nodes: ExpressionNode[]) {
    this.nodes = nodes
  }

  get audienceExpression() {
    const { attrs } = this.firstNode.node
    return getAudienceExpressionFromNodeAttrs(attrs)
  }

  get firstNode() {
    return this.nodes[0]
  }

  get from() {
    return this.firstNode.pos
  }

  get id() {
    return this.firstNode.groupId
  }

  get lastNode() {
    return last(this.nodes)
  }

  get to() {
    const { lastNode } = this
    return lastNode.pos + lastNode.node.nodeSize
  }
}

export class ExpressionView {
  decorations: Decoration[] = []
  editor: Editor
  group: Expression
  nodeEls: HTMLElement[] = []
  widgetEl: HTMLElement

  constructor(editor: Editor, group: Expression) {
    this.editor = editor
    this.group = group

    // Create the widget
    const widgetEl = document.createElement('div')
    widgetEl.className = 'expression-widget'
    widgetEl.dataset.id = group.id
    this.widgetEl = widgetEl

    const dom = document.createElement('span')
    dom.classList.add('rw-variable')
    ReactDOM.render(
      <>
        <Tooltip
          content={editor.renderAudienceExpression(group.audienceExpression)}
          placement='left'
          theme='expression'
        >
          <button
            type='button'
            className='expression-widget-button'
            onClick={async () => {
              this.select()
              editor.handleBlur()
              widgetEl.classList.add('active')

              try {
                const audienceExpression = await editor.pickAudienceExpression(
                  group.audienceExpression,
                )
                editor
                  .chain()
                  .focus()
                  .setAudienceExpressions(group.nodes, audienceExpression)
                  .run()
              } catch (_error) {
                editor.commands.focus()
              }

              widgetEl.classList.remove('active')
            }}
          >
            <FontAwesomeIcon icon={['far', 'lock']} />
          </button>
        </Tooltip>
        <div className='expression-widget-container' />
      </>,
      this.widgetEl,
    )

    // Create the decorations

    this.decorations.push(Decoration.widget(group.from, widgetEl))
    for (const node of group.nodes) {
      const from = node.pos
      const to = from + node.node.nodeSize
      this.decorations.push(
        Decoration.node(from, to, { 'data-expression-group-id': group.id }),
      )
    }
  }

  select() {
    const { editor, group } = this
    const isTextSelection = group.nodes.some((node) => node.node.isTextblock)
    if (isTextSelection) {
      editor.commands.setTextSelection(group.from + 1)
    } else {
      editor.commands.setNodeSelection(group.from)
    }
  }

  updatePosition(view: EditorView) {
    const { group, widgetEl } = this
    const { firstNode, lastNode, nodes } = group
    const widgetRect = this.widgetEl.getBoundingClientRect()

    // Vertical position
    const yPadding = 3
    const topEl = view.nodeDOM(firstNode.pos) as HTMLElement
    const topRect = topEl.getBoundingClientRect()
    const top = topRect.top - widgetRect.top
    const bottomEl = view.nodeDOM(lastNode.pos) as HTMLElement
    const height = bottomEl.offsetTop + bottomEl.offsetHeight - topEl.offsetTop

    // Horizontal position
    const xPadding = 3
    const nodeEls = nodes.map((node) => view.nodeDOM(node.pos) as HTMLElement)
    const lefts = nodeEls.map((el) => el.offsetLeft)
    const left = Math.min(...lefts) - widgetEl.offsetLeft
    const rights = nodeEls.map((el) => el.offsetLeft + el.offsetWidth)
    const right = Math.max(...rights) - widgetEl.offsetLeft
    const width = right - left

    // Container position
    const cotainerEl = widgetEl.querySelector<HTMLButtonElement>(
      '.expression-widget-container',
    )
    if (cotainerEl) {
      cotainerEl.style.top = top - yPadding + 'px'
      cotainerEl.style.height = height + yPadding * 2 + 'px'
      cotainerEl.style.left = left - xPadding + 'px'
      cotainerEl.style.width = width + xPadding * 2 + 'px'
    }

    // Button position
    const buttonEl = widgetEl.querySelector<HTMLButtonElement>(
      '.expression-widget-button',
    )
    if (buttonEl) {
      let marginLeft = widgetEl.offsetLeft + xPadding * 2
      const marginEl = widgetEl as HTMLElement
      if (marginEl.offsetParent === view.dom) {
        marginLeft -= 16
      }
      buttonEl.style.right = `calc(100% + ${marginLeft}px)`
      buttonEl.style.top = top + 'px'
    }
  }
}
