import { Button } from '@blissbook/ui/lib'
import { mergeRefs } from '@blissbook/ui/util'
import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Placement } from '@popperjs/core'
import React, {
  type CSSProperties,
  type ReactNode,
  forwardRef,
  useState,
} from 'react'
import { usePopper } from 'react-popper'
import './ProTip.scss'

const defaultTheme = {
  className: 'tw-bg-gray-100 tw-text-black',
  dismissClassName: 'tw-text-gray-400 hover:tw-text-red-700',
  iconClassName: 'tw-text-sunshine-500',
}

const themes = [
  {
    id: 'light',
    className: 'tw-bg-gray-200 tw-text-black',
    dismissClassName: 'tw-text-gray-500 hover:tw-text-black',
    iconClassName: 'tw-text-sunshine-500',
  },
  {
    id: 'dark',
    className: 'tw-bg-black tw-text-white',
    dismissClassName: 'tw-text-gray-400 hover:tw-text-white',
    iconClassName: 'tw-text-sunshine-500',
  },
]

const getTheme = (id: string) => themes.find((t) => t.id === id) || defaultTheme

export type ProTipProps = {
  children: ReactNode
  className?: string
  icon: IconProp
  maxWidth?: number
  onDismiss?: () => void
  prefix?: string
  style?: CSSProperties
  theme?: string
}

export const ProTip = forwardRef<HTMLDivElement, ProTipProps>(
  (
    {
      children,
      className,
      icon,
      maxWidth,
      onDismiss,
      prefix = 'Pro-Tip:',
      style,
      theme,
      ...props
    },
    ref,
  ) => {
    const resTheme = getTheme(theme)

    return (
      <div
        {...props}
        className={cx(
          'pro-tip tw-flex tw-items-center tw-rounded',
          resTheme.className,
          className,
        )}
        css={{
          padding: '1rem 1.5rem',
          position: 'relative',
        }}
        ref={ref}
        style={{
          ...style,
          maxWidth,
        }}
      >
        <FontAwesomeIcon
          className={cx('tw-mr-4', resTheme.iconClassName)}
          css={{
            flexShrink: 0,
            fontSize: 24,
          }}
          icon={icon}
        />

        <div className='tw-text-sm' css={{ background: 'inherit' }}>
          <strong>{prefix}</strong> {children}
        </div>

        {onDismiss && (
          <Button
            className={cx(
              'btn-icon tw-absolute tw-text-xs',
              resTheme.dismissClassName,
            )}
            onClick={async () => {
              onDismiss()
            }}
            style={{ right: 7, top: 3 }}
          >
            <FontAwesomeIcon icon={['far', 'times']} />
          </Button>
        )}
      </div>
    )
  },
)

export type ProTipTooltipProps = {
  children: ReactNode
  placement?: Placement
  tip: ProTipProps
}

export const ProTipTooltip = ({
  children,
  placement,
  tip,
}: ProTipTooltipProps) => {
  const [arrowNode, setArrowNode] = useState<HTMLElement | null>()
  const [refNode, setRefNode] = useState<HTMLElement | null>()
  const [tipNode, setTipNode] = useState<HTMLElement | null>()

  const { attributes, styles } = usePopper(refNode, tipNode, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowNode,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ].filter(Boolean),
    placement,
  })

  return (
    <>
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, {
          ref: mergeRefs([child.ref, setRefNode]),
        }),
      )}

      <ProTip
        {...attributes.popper}
        {...tip}
        className='tw-absolute tw-py-2 tw-pl-4 tw-pr-6'
        ref={setTipNode}
        style={styles.popper}
      >
        <div
          className='pro-tip-arrow'
          ref={setArrowNode}
          style={styles.arrow}
        />

        {tip.children}
      </ProTip>
    </>
  )
}
