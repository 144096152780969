import type { Permission } from '../types'
import { mapById } from '../util'

export const superUserPermissions: Permission[] = [
  {
    id: 'blissbook.admin',
    label: 'Admin Blissbook',
    description: 'User can administer Blissbook',
  },
  {
    id: 'superUsers.edit',
    label: 'Edit Super Users',
    description: 'User can edit super users',
  },
]

export const superUserPermissionsById = mapById(superUserPermissions)
