import type { Permission } from '../types'
import { mapById } from '../util'

export const handbookPermissions: Permission[] = [
  {
    id: 'handbook.convert',
    action: 'convert',
    label: 'Convert Handbook Sections',
    description: 'User can convert handbook sections to policies',
  },
  {
    id: 'handbook.delete',
    action: 'delete',
    label: 'Delete Handbook',
    description: 'User can delete the handbook',
  },
  {
    id: 'handbook.sharing.view',
    action: 'edit sharing on',
    label: 'View Handbook Sharing',
    description: 'User can view who the handbook is shared with',
  },
  {
    id: 'handbook.query',
    action: 'query',
    label: 'Query Handbook Metadata',
    description: 'User can query handbook metadata',
  },
  {
    id: 'handbook.live.view',
    action: 'view',
    label: 'View Live Handbook',
    description: 'User can view the live handbook',
  },
  {
    id: 'handbook.draft.view',
    action: 'view the draft of',
    label: 'View Draft Handbook',
    description: 'User can view the draft content for a handbook',
  },
  {
    id: 'handbook.draft.edit',
    action: 'edit',
    label: 'Edit Handbook',
    description: 'User can edit the handbook',
  },
  {
    id: 'handbook.draft.brand',
    action: 'brand',
    label: 'Brand Handbook',
    description: 'User can brand the handbook',
  },
  {
    id: 'handbook.draft.editCss',
    action: 'edit css on',
    label: 'Edit Handbook CSS',
    description: 'User can edit the handbook CSS',
  },
  {
    id: 'handbook.draft.editCustomHtml',
    action: 'edit custom html on',
    label: 'Edit Handbook Custom Html',
    description: 'User can edit the handbook custom Html',
  },
  {
    id: 'handbook.draft.preview',
    action: 'preview',
    label: 'Preview Handbook',
    description: 'User can preview the handbook',
  },
  {
    id: 'handbook.history.view',
    action: 'view history on',
    label: 'View Handbook History',
    description: 'User can view previous handbook versions',
  },
  {
    id: 'handbook.notifyPeople',
    action: 'notify recipients of',
    label: 'Notify Handbook Recipients',
    description: 'User can notify handbook recipients',
  },
  {
    id: 'handbook.publish',
    action: 'publish',
    label: 'Publish Handbook',
    description: 'User can publish the handbook',
  },
  {
    id: 'handbook.recordSignature',
    action: 'record signatures on',
    label: 'Record Handbook Signatures',
    description: 'User can manually record handbook signatures',
  },
  {
    id: 'handbook.report',
    action: 'report on',
    label: 'Report on Handbook',
    description: 'User can report on the handbook',
  },
  {
    id: 'handbook.audience.edit',
    action: 'edit the audience on',
    label: 'Edit Handbook Audience',
    description: 'User can edit the handbook audience',
  },
  {
    id: 'handbook.collaborators.edit',
    action: 'edit the collaborators on',
    label: 'Edit Handbook Collaborators',
    description: 'User can edit the handbook collaborators',
  },
  {
    id: 'handbook.signatureRounds.edit',
    action: 'edit signature rounds on',
    label: 'Edit Handbook Signature Rounds',
    description: 'User can edit handbook signature rounds',
  },
  {
    id: 'handbook.performTech',
    action: 'perform tech on',
    label: 'Perform Tech on Handbook',
    description: 'User can perform tech on handbook',
  },
  {
    id: 'handbook.deleteSignature',
    action: 'delete signatures on',
    label: 'Delete Handbook Signatures',
    description: 'User can delete handbook signatures',
  },
]

export const handbookPermissionsById = mapById(handbookPermissions)
