import { useEffect } from 'react'

function isScrollable(node: HTMLElement) {
  // Must be an HTML element to be scrollable (e.g. not document)
  const isElement = node instanceof Element
  if (!isElement) return false

  // Check element styles
  const style = window.getComputedStyle(node)
  return ['scroll', 'auto'].includes(style.overflowY)
}

export function getScrollNode(
  node: HTMLElement | undefined,
): HTMLElement | undefined {
  if (!node) return
  return isScrollable(node)
    ? node
    : getScrollNode(node.parentNode as HTMLElement | undefined)
}
