import { Extension, type KeyboardShortcutCommand } from '@tiptap/core'
import { type TextAlign, textAligns } from '../../types'

const types = ['heading', 'paragraph']

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textAlign: {
      setTextAlign: (alignment: TextAlign) => ReturnType
    }
  }
}

export const TextAlignExtension = Extension.create({
  name: 'textAlign',

  addGlobalAttributes() {
    return [
      {
        types,
        attributes: {
          textAlign: {
            default: undefined,
            parseHTML: (element) => {
              const value = element.style.textAlign
              if (!value) return

              const isValid = textAligns.some((ta) => ta.value === value)
              if (!isValid) return

              return value
            },
            renderHTML: ({ textAlign }) => {
              if (!textAlign) return {}
              return { style: `text-align: ${textAlign}` }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setTextAlign:
        (textAlign) =>
        ({ commands }) => {
          if (!textAligns.some((t) => t.value === textAlign)) return false
          return types.every((type) =>
            commands.updateAttributes(type, { textAlign }),
          )
        },
    }
  },

  addKeyboardShortcuts() {
    return textAligns.reduce(
      (shortcuts, textAlign) => {
        shortcuts[textAlign.keyboardShortcut] = () =>
          this.editor.commands.setTextAlign(textAlign.value)
        return shortcuts
      },
      {} as Record<string, KeyboardShortcutCommand>,
    )
  },
})
